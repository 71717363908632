import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoad: false,
  list: [],
  statusLabels: ['', '', ''],
  filters: {
    status: '0',
    email: '',
    dateFrom: null,
    dateTo: null,
    name: '',
    reason: '',
    type: '',
    paymentGatewayId: '',
    clientId: '',
  },
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  selectedOrder: {},
};

const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setOrderRefundGetAll(state, action) {
      state.list = action.payload.items;
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
      state.isLoad = false;
    },
    setOrderRefundClearSelected(state) {
      state.statusLabels = ['', '', ''];
    },
    setFilterOrderRefund(state, action) {
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
      state.filters.status = action.payload.status;
      state.filters.email = action.payload.email;
      state.filters.name = action.payload.name;
      state.filters.reason = action.payload.reason;
      state.filters.type = action.payload.type;
      state.filters.paymentGatewayId = action.payload.paymentGatewayId;
      state.filters.clientId = action.payload.clientId;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setPageOrderRefund(state, action) {
      state.pagination.page = action.payload;
    },
    setRefundStatusLabels(state, action) {
      state.statusLabels = action.payload;
    },
  },
});

const {
  reducer: refund,
  actions: { setIsLoad, setRefundStatusLabels, setOrderRefundGetAll, setPageOrderRefund, setFilterOrderRefund },
} = refundSlice;

export const orderRefundGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('/admin/tickets', { params })
    .then((res) => dispatch(setOrderRefundGetAll(res.data)))
    .catch(() => dispatch(setIsLoad(false)));
};

export const orderRefundUpdateStatus = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/tickets/complete`, { id })
    .then(() => {
      dispatch(orderRefundGetAll());
      dispatch(setIsLoad(false));
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export const getRefundStatusLabels = () => (dispatch) => {
  return request.get('/admin/tickets/statuses').then((res) => dispatch(setRefundStatusLabels(res.data)));
};

export { refund, setFilterOrderRefund, setPageOrderRefund };
