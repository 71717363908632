import React, { useEffect, useState } from 'react';
import Input from 'assets/components/Input';
import { Link, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setClearError,
  setError,
  startCreateAdmin,
  startEditAdmin,
  startAdminSetSelected,
  setClearSelectedAdmin,
  startAdminSetRoles,
} from 'store/slices/admin';

const AdminCrtPage = () => {
  const dispatch = useAppDispatch();
  const {
    params: { id },
  } = useRouteMatch();

  const errors = useAppSelector((state) => state.admin.errors);
  const selectedAdmin = useAppSelector((state) => state.admin.selectedAdmin);
  const ownId = useAppSelector((state) => state.auth.id);
  const roles = useAppSelector((state) => state.admin.roles);

  // state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectRoleOptions, setSelectRoleOptions] = useState([]);

  useEffect(() => {
    if (roles.length === 0) {
      dispatch(startAdminSetRoles());
    }
  }, [roles, startAdminSetRoles]);

  useEffect(() => {
    if (roles.length) {
      setSelectRoleOptions(roles.map((v, i) => ({ value: v.id, label: v.name })));
    }
  }, [roles]);

  // set seletced admin on mount
  useEffect(() => {
    if (id) {
      dispatch(startAdminSetSelected(id));
    }
  }, [id, startAdminSetSelected]);

  // set selected admin username
  useEffect(() => {
    if (selectedAdmin) {
      setUsername(selectedAdmin.username);
    }
  }, [selectedAdmin]);

  // set selected admin role
  useEffect(() => {
    if (selectRoleOptions.length) {
      if (selectedAdmin) {
        // Edit admin
        setSelectedRole(selectRoleOptions.find((o) => o.label === selectedAdmin.role.name));
      } else {
        // Create admin
        // setSelectedRole(selectRoleOptions[0]);
      }
    }
  }, [selectedAdmin, selectRoleOptions]);

  //cleanup
  useEffect(
    () => () => {
      dispatch(setClearSelectedAdmin());
      dispatch(setClearError());
    },
    [setClearError, setClearSelectedAdmin],
  );

  const handleSubmit = () => {
    if (selectedAdmin) {
      return selectedAdmin.id === ownId && selectedRole.label !== selectedAdmin.role
        ? dispatch(setError({ message: 'Нельзя поменять роль себе' }))
        : dispatch(startEditAdmin({ id, username, password: password || null, role: selectedRole.value }));
    }
    dispatch(startCreateAdmin({ username, password, role: selectedRole.value }));
  };

  return (
    <>
      <div className='create'>
        <h2 className='create__title'>{id ? `Редактирование админа - ID ${id}` : 'Новый админ'}</h2>
        <Input
          placeholder='Имя пользователя'
          type='text'
          name='name'
          inputCls='create__input'
          label='Имя пользователя'
          error={errors.email}
          onChange={({ target: { value } }) => setUsername(value)}
          value={username}
        />
        <Input
          placeholder='Пароль'
          type='password'
          name='password'
          inputCls='create__input'
          label='Пароль'
          error={errors.password}
          onChange={({ target: { value } }) => setPassword(value)}
          value={password}
        />
        <label className='input__label'>Роль</label>
        <Select
          styles={{ container: (provided) => ({ ...provided, maxWidth: 300, marginBottom: 32 }) }}
          options={selectRoleOptions}
          onChange={setSelectedRole}
          value={selectedRole}
        />
        <button className='button' onClick={handleSubmit}>
          Сохранить
        </button>
        <Link className='create__prev' to='/admin/admins'>
          Назад к списку
        </Link>
      </div>
      {errors.message && <div style={{ color: 'red' }}>{errors.message}</div>}
    </>
  );
};

export default AdminCrtPage;
