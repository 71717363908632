import React, { useEffect, useState } from 'react';
import Input from 'assets/components/Input';
import { setCompanyGetOneSuccess, setClearSelectedCompany } from 'store/slices/company';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../assets/components/Loader';
/**
 * store
 */
import { useAppDispatch, useAppSelector } from 'store';
import { companyCreate, companyGetOne, companyUpdate, companyGetOptions } from 'store/slices/company';

const CompanyCrtPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const isLoad = useAppSelector((state) => state.company.isLoad);
  const optionsVat = useAppSelector((state) => state.company.options.vat);
  const optionsStatus = useAppSelector((state) => state.company.options.status);
  const optionsType = useAppSelector((state) => state.company.options.type);
  const optionsTaxation = useAppSelector((state) => state.company.options.taxation);
  const [selectOptionsVat, setSelectOptionsVat] = useState([]);
  const [selectOptionsStatus, setSelectOptionsStatus] = useState([]);
  const [selectOptionsType, setSelectOptionsType] = useState([]);
  const [selectOptionsTaxation, setSelectOptionsTaxation] = useState([]);
  const [metadata, setMetadata] = useState({
    linkOfferName: '',
    linkPermissionName: '',
    linkPolicyName: '',
    linkRefundName: '',
    linkTariffName: '',
    linkUnsubscribeName: '',
  });

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [inn, setInn] = useState('');
  const [ogrn, setOgrn] = useState('');
  const [taxation, setTaxation] = useState('');
  const [address, setAddress] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('');
  const [vat, setVat] = useState('');
  const [phone, setPhone] = useState('');
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(companyGetOne(id));
    }
    return () => {
      dispatch(setClearSelectedCompany());
    };
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(companyGetOptions());
  }, [dispatch]);

  useEffect(() => {
    optionsVat &&
      setSelectOptionsVat(
        Object.keys(optionsVat).map((item, index) => ({
          value: Number(item),
          label: Object.values(optionsVat)[index],
        })),
      );
    optionsStatus &&
      setSelectOptionsStatus(
        Object.keys(optionsStatus).map((item, index) => ({
          value: Number(item),
          label: Object.values(optionsStatus)[index],
        })),
      );
    optionsType &&
      setSelectOptionsType(
        Object.keys(optionsType).map((item, index) => ({
          value: Number(item),
          label: Object.values(optionsType)[index],
        })),
      );
    optionsTaxation &&
      setSelectOptionsTaxation(
        Object.keys(optionsTaxation).map((item, index) => ({
          value: Number(item),
          label: Object.values(optionsTaxation)[index],
        })),
      );
  }, [optionsVat, optionsStatus, optionsTaxation, optionsType]);

  useEffect(() => {
    if (id && selectedCompany) {
      selectedCompany.name && setName(selectedCompany.name);
      selectedCompany.type && setType(selectedCompany.type);
      selectedCompany.inn && setInn(selectedCompany.inn);
      selectedCompany.ogrn && setOgrn(selectedCompany.ogrn);
      selectedCompany.taxation && setTaxation(selectedCompany.taxation);
      selectedCompany.address && setAddress(selectedCompany.address);
      selectedCompany.comment && setComment(selectedCompany.comment);
      selectedCompany.status && setStatus(selectedCompany.status);
      selectedCompany.vat && setVat(selectedCompany.vat);
      selectedCompany.metadata && setMetadata(selectedCompany.metadata);
      (selectedCompany.phone === null || selectedCompany.phone) && setPhone(selectedCompany.phone);
      (selectedCompany.year === null || selectedCompany.year) && setYear(selectedCompany.year);
    } else {
      const date = new Date();
      const actualYear = date.getFullYear();
      setYear(actualYear);
    }
  }, [selectedCompany, id]);

  const handleCreateCompany = () => {
    id
      ? dispatch(
          companyUpdate(id, {
            type,
            name,
            inn,
            ogrn,
            taxation,
            address,
            comment,
            status,
            vat,
            phone,
            year,
            metadata,
          }),
        )
      : dispatch(
          companyCreate({
            type,
            name,
            inn,
            ogrn,
            taxation,
            address,
            comment,
            vat,
            phone,
            year,
            metadata,
          }),
        );
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setMetadata({
      ...metadata,
      [name]: value,
    });
  };

  const disabledBtn = !type || !name || !inn || !ogrn || !taxation || !address || !phone;

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Создание ЮрЛица</h2>
        <div className='create__inner'>
          <div className='create__inputs'>
            <div className='product-crt-select'>
              <label className='input__label'>Тип:</label>
              <Select
                options={selectOptionsType}
                value={selectOptionsType[selectOptionsType.findIndex((el) => el.value === type)]}
                onChange={(option) => {
                  setType(option.value);
                }}
                placeholder='Тип'
              />
            </div>
            <Input
              placeholder=''
              type='text'
              name='name'
              inputCls='create__input'
              label='Название'
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Input
              placeholder=''
              type='text'
              name='inn'
              inputCls='create__input'
              label='ИНН'
              onChange={(e) => setInn(e.target.value)}
              value={inn}
            />
            <Input
              placeholder=''
              type='text'
              name='ogrn'
              inputCls='create__input'
              label='ОГРН'
              onChange={(e) => setOgrn(e.target.value)}
              value={ogrn}
            />
            <div className='product-crt-select'>
              <label className='input__label'>Cистема налогооблажения</label>
              <Select
                options={selectOptionsTaxation}
                value={selectOptionsTaxation[selectOptionsTaxation.findIndex((el) => el.value === taxation)]}
                onChange={(option) => {
                  setTaxation(option.value);
                }}
                placeholder='Cистема налогооблажения'
              />
            </div>
            <div className='product-crt-select'>
              <label className='input__label'>НДС</label>
              <Select
                options={selectOptionsVat}
                value={selectOptionsVat[selectOptionsVat.findIndex((el) => el.value === vat)]}
                onChange={(option) => {
                  setVat(option.value);
                }}
                placeholder='НДС'
              />
            </div>
            {id && (
              <div className='product-crt-select'>
                <label className='input__label'>Cтатус</label>
                <Select
                  options={selectOptionsStatus}
                  value={selectOptionsStatus[selectOptionsStatus.findIndex((el) => el.value === status)]}
                  onChange={(option) => {
                    setStatus(option.value);
                  }}
                  placeholder='Статус'
                />
              </div>
            )}
            <Input
              placeholder=''
              type='text'
              name='address'
              inputCls='create__input'
              label='Адрес'
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
            <Input
              placeholder=''
              type='text'
              name='phone'
              inputCls='create__input'
              label='Телефон'
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <Input
              placeholder=''
              type='number'
              name='year'
              inputCls='create__input'
              label='Год'
              onChange={(e) => setYear(parseInt(e.target.value))}
              value={year}
            />
            <Input
              placeholder=''
              type='text'
              name='linkOfferName'
              inputCls='create__input'
              label='Пользовательское соглашение'
              onChange={handleChangeInput}
              value={metadata?.linkOfferName}
            />
            <Input
              placeholder=''
              type='text'
              name='linkPermissionName'
              inputCls='create__input'
              label='Политика обработки данных'
              onChange={handleChangeInput}
              value={metadata?.linkPermissionName}
            />
            <Input
              placeholder=''
              type='text'
              name='linkPolicyName'
              inputCls='create__input'
              label='Политика конфиденциальности'
              onChange={handleChangeInput}
              value={metadata?.linkPolicyName}
            />
            <Input
              placeholder=''
              type='text'
              name='linkRefundName'
              inputCls='create__input'
              label='Возврат средств'
              onChange={handleChangeInput}
              value={metadata?.linkRefundName}
            />
            <Input
              placeholder=''
              type='text'
              name='linkTariffName'
              inputCls='create__input'
              label='Тариф'
              onChange={handleChangeInput}
              value={metadata.linkTariffName}
            />
            <Input
              placeholder=''
              type='text'
              name='linkUnsubscribeName'
              inputCls='create__input'
              label='Остановка услуги'
              onChange={handleChangeInput}
              value={metadata?.linkUnsubscribeName}
            />
            <label className='input__label'>Комментарий</label>
            <textarea className='domain-textarea' value={comment} onChange={(e) => setComment(e.target.value)} />
          </div>
        </div>
        <button disabled={disabledBtn} className={disabledBtn ? 'button-dis' : 'button'} onClick={handleCreateCompany}>
          Сохранить
        </button>
        <Link onClick={() => dispatch(setCompanyGetOneSuccess({}))} to='/company' className='create__prev'>
          Назад к списку
        </Link>
      </div>
    </>
  );
};

export { CompanyCrtPage };
