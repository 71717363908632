import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalStatus: 'hide',
  modalText: '',
  infoPayload: {},
  modalType: 'bin',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal(state, action) {
      state.modalStatus = 'show';
      state.modalText = action.payload.text;
      state.modalType = action.payload.type ? action.payload.type : 'bin';
      state.infoPayload = { ...action.payload.infoPayload };
    },
    setModalData(state, action) {
      state.modalStatus = action.payload;
    },
    setCleanModalData(state) {
      state.modalStatus = 'hide';
      state.modalText = '';
      state.infoPayload = {};
      state.modalType = 'bin';
    },
  },
});

const {
  reducer: modal,
  actions: { setShowModal, setModalData, setCleanModalData },
} = modalSlice;

export const showModal = (text, infoPayload, type) => {
  return (dispatch) => {
    dispatch(setShowModal({ text, infoPayload, type }));
  };
};

export const modalData = (payload) => {
  return (dispatch) => {
    dispatch(setModalData(payload));
  };
};

export const modalCleanData = (payload) => {
  return (dispatch) => {
    dispatch(setCleanModalData(payload));
  };
};

export { modal, setShowModal, setCleanModalData, setModalData };
