import React from 'react';
import Select from 'react-select';
import styles from './EditGatewayModal.scss';
import { useAppSelector, useAppDispatch } from 'store';
import { showModal, modalCleanData } from 'store/slices/modal';

const isDirtyOption = [
  { label: 'Грязный', value: 1 },
  { label: 'Чистый', value: 2 },
  { label: 'По дефолту', value: '' },
];

const optionStatus = [
  { value: 1, label: 'Активный' },
  { value: 0, label: 'Неактивный' },
  { value: '', label: 'По дефолту' },
];

const optionSber = [
  { value: true, label: 'Включен' },
  { value: false, label: 'Выключен' },
  { value: '', label: 'По дефолту' },
];

const stopRebillOption = [
  { label: 'Ребиллы включены', value: 0 },
  { label: 'Все выключены', value: 1 },
  { label: 'Все по Сберу выключены', value: 2 },
  { label: 'Все по ТКС выключены', value: 10 },
  { label: 'Все по Сберу и ТКС выключены', value: 11 },
  { label: 'Выключены все кроме Сбера', value: 3 },
  { label: 'Первые выключены', value: 4 },
  { label: 'Первые по Сберу выключены', value: 5 },
  { label: 'Первые кроме Сбера выключены', value: 6 },
  { label: 'Повторные выключены', value: 7 },
  { label: 'Повторные по Сберу выключены', value: 8 },
  { label: 'Повторные кроме Сбера выключены', value: 9 },
  { label: 'По дефолту', value: '' },
];

const EditGatewayModal = ({
  handleCliсkCloseTable,
  sberbill,
  setSberbill,
  sberroute,
  setSberroute,
  handleClickMassUpdate,
  status,
  setStatus,
  isDirty,
  setIsDirty,
  stopRecurrentByGateway,
  setStopRecurrentByGateway,
  stopRecurrentByCompany,
  setStopRecurrentByCompany,
  scheme,
  setScheme,
}) => {
  const dispatch = useAppDispatch();
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const schemeOption = useAppSelector((state) => state.gateway.rates);

  React.useEffect(() => {
    if (modalStatus === 'changeStatus') {
      dispatch(modalCleanData());
    }
    if (modalStatus === 'No') {
      setStatus('');
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  const disabledReg =
    status === '' &&
    isDirty === '' &&
    sberroute === '' &&
    sberbill === '' &&
    stopRecurrentByGateway === '' &&
    stopRecurrentByCompany === '' &&
    scheme === '';

  return (
    <div className='editGatewayModal'>
      <div className='editGatewayModal__container'>
        <div className='editGatewayModal__header'>
          <div>Групповое редактирование</div>
          <div className='editGatewayModal__header__close' onClick={handleCliсkCloseTable}>
            X
          </div>
        </div>
        <div>
          <h3>Внести изменения для шлюзов</h3>
          <div className='editGatewayModal__select-wrapper'>
            <div className='editGatewayModal__select-container'>
              <label className='input__label'>SberBill</label>
              <Select
                options={optionSber}
                value={optionSber[optionSber.findIndex((el) => el.value === sberbill)]}
                onChange={(option) => {
                  setSberbill(option.value);
                }}
                placeholder='SberBill'
              />
            </div>
            <div className='editGatewayModal__select-container'>
              <label className='input__label'>SberRoute</label>
              <Select
                options={optionSber}
                value={optionSber[optionSber.findIndex((el) => el.value === sberroute)]}
                onChange={(option) => {
                  setSberroute(option.value);
                }}
                placeholder='SberRoute'
              />
            </div>
            <div className='editGatewayModal__select-container'>
              <label className='input__label'>Остановка ребиллов по юр.лицу</label>
              <Select
                options={stopRebillOption}
                value={stopRebillOption[stopRebillOption.findIndex((el) => el.value === stopRecurrentByCompany)]}
                onChange={(option) => {
                  setStopRecurrentByCompany(option.value);
                }}
                placeholder='Остановка ребиллов'
              />
            </div>
          </div>
        </div>
        <div className='editGatewayModal__select-wrapper'>
          <div className='editGatewayModal__select-container'>
            <label className='input__label'>Статус</label>
            <Select
              options={optionStatus}
              value={optionStatus[optionStatus.findIndex((el) => el.value === status)]}
              onChange={(option) => {
                setStatus(option.value);
                dispatch(showModal(`Шлюзы изменят статус на: "${option.label}"?`, {}, 'changeStatus'));
              }}
              placeholder='Выберите статус'
            />
          </div>
          <div className='editGatewayModal__select-container'>
            <label className='input__label'>Чистый/Грязный шлюз</label>
            <Select
              options={isDirtyOption}
              value={isDirtyOption[isDirtyOption.findIndex((el) => el.value === isDirty)]}
              onChange={(option) => {
                setIsDirty(option.value);
              }}
              placeholder='Чистый/Грязный'
            />
          </div>
          <div className='editGatewayModal__select-container'>
            <label className='input__label'>Остановка ребиллов по шлюзу</label>
            <Select
              options={stopRebillOption}
              value={stopRebillOption[stopRebillOption.findIndex((el) => el.value === stopRecurrentByGateway)]}
              onChange={(option) => {
                setStopRecurrentByGateway(option.value);
              }}
              placeholder='Остановка ребиллов'
            />
          </div>
        </div>
        <div className='editGatewayModal__select-wrapper'>
          <div className='editGatewayModal__select-container'>
            <label className='input__label'>ТАРИФЫ</label>
            <Select
              options={schemeOption}
              value={schemeOption[schemeOption.findIndex((el) => el.value === scheme)]}
              onChange={(option) => {
                setScheme(option.value);
              }}
              placeholder='Тарифы'
            />
          </div>
        </div>
        <div className='editGatewayModal__buttons'>
          <button type='button' onClick={handleCliсkCloseTable} className='editModalDomain__buttons__cancel'>
            Отмена
          </button>
          <button
            disabled={disabledReg}
            type='button'
            onClick={handleClickMassUpdate}
            className='editModalDomain__buttons__add'
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export { EditGatewayModal };
