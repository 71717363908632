import React, { useEffect } from 'react';
import { companyGetAll, companyDelete, companyGetOptions } from 'store/slices/company';
import { modalCleanData } from 'store/slices/modal';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from '../../assets/components/Loader';

const titleCompany = [
  {
    name: 'id',
    mod: 'sm',
  },
  {
    name: 'тип',
  },
  {
    name: 'название',
    mod: 'big',
  },
  {
    name: 'статус',
  },
  {
    name: 'инн',
    mod: 'big',
  },
  {
    name: 'огрн',
    mod: 'big',
  },
  {
    name: 'телефон',
    mod: 'big',
  },
  {
    name: 'налогообложение',
  },
  {
    name: 'адрес',
    mod: 'big',
  },
  {
    name: 'комментарий',
    mod: 'big',
  },
  {
    name: '...',
    mod: 'm',
  },
];

const CompanyPage = ({}) => {
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.company.list);
  const isLoad = useSelector((state) => state.company.isLoad);
  const availableEdit = useSelector((state) => state.auth.role.permissions.company.includes('edit_companies'));
  const infoPayload = useSelector((state) => state.modal.infoPayload);
  const modalStatus = useSelector((state) => state.modal.modalStatus);
  const optionsCompany = useSelector((state) => state.company.options);

  useEffect(() => {
    dispatch(companyGetAll());
    dispatch(companyGetOptions());
  }, []);

  useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(companyDelete(infoPayload.id));
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header
        title='ЮрЛица'
        text={availableEdit ? 'Добавить ЮрЛицо' : null}
        href={availableEdit ? '/company/add' : null}
      />
      <Table titles={titleCompany} optionsCompany={optionsCompany} content={companyList} keyProp='company' />
    </>
  );
};

export default CompanyPage;
