import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: false,
  data: [],
  error: null,
}
const getAllLanguages = createAsyncThunk('GET_ALL_LANGUAGE', () => {
  return request.get('/admin/products/languages')
});

const languageSlice = createSlice({
  name: 'language',
  initialState,
  extraReducers: builder => {
    // Pending
    builder.addCase(getAllLanguages.pending, state => {
      state.isLoading = true
    })
    // Done
    builder.addCase(getAllLanguages.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data.map((lang, index) => {
        return { value: index, label: lang }
      });
    })
    // Reject
    builder.addCase(getAllLanguages.rejected, (state, action) => {
      state.loading = false
    })
  },
});

const { reducer: language } = languageSlice;

export { language, getAllLanguages };