import React from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import Header from 'assets/components/Header';
import Loader from 'assets/components/Loader';
import { LinkTabs } from 'assets/components/LinkTabs/LinkTabs';
import { useParams } from 'react-router-dom';
import Statistics from './Statistics/Statistics';
import WebMaster from './WebMaster/WebMaster';
import { toast } from 'react-toastify';
import { setError } from 'store/slices/chargebacks';

const list = [
  { name: 'Статистика', link: '/chargebacks/statistic', value: 'statistic' },
  { name: 'ВебМастера', link: '/chargebacks/webmaster', value: 'webmaster' },
];

const ChargeBackPage = () => {
  const dispatch = useAppDispatch();
  const isLoad = useAppSelector((state) => state.chargebacks.isLoading);
  const error = useAppSelector((state) => state.chargebacks.error);
  const { tabName } = useParams();

  React.useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(setError(null));
    }
  }, [error]);

  return (
    <>
      {isLoad && <Loader />}
      <Header title='Чарджбеки' />
      <LinkTabs list={list} />
      {tabName === 'statistic' && <Statistics />}
      {tabName === 'webmaster' && <WebMaster />}
    </>
  );
};

export default ChargeBackPage;
