import React, { useEffect, useState } from 'react';
import Input from 'assets/components/Input';
import { FileInput } from 'assets/components/FileInput';
import ButtonLink from 'assets/components/ButtonLink';
import { useHistory } from 'react-router-dom';
import DropDown from 'assets/components/DropDown';
import Loader from '../../assets/components/Loader';
import { fileGetOne, fileUpdate, fileCleanErrors, fileCleanRedirect } from 'actions/file';
import { productGetAll } from 'store/slices/product';
import { useAppDispatch, useAppSelector } from 'store';
import { useParams } from 'react-router-dom';

const FileEditPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const redirect = useAppSelector((state) => state.file.redirect);
  const errors = useAppSelector((state) => state.file.errors);
  const isLoad = useAppSelector((state) => state.file.isLoad);
  const curFile = useAppSelector((state) => state.file.curFile);
  const product = useAppSelector((state) => state.product.list)?.map(({ name, id }) => ({ name, id, check: false }));

  const [path, setPath] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const [products, setProducts] = useState([]);
  const [fileBackgroundFile, setFileBackgroundFile] = useState('');
  const [previewBackgroundURL, setPreviewBackgroundURL] = useState('');
  const [fileCoverFile, setFileCoverFile] = useState('');
  const [previewCoverURL, setPreviewCoverURL] = useState('');

  const [firstRun, setFirstRun] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      dispatch(fileCleanRedirect());
      history.goBack();
    }
    if (errors.file) {
      dispatch(fileCleanErrors('file'));
      history.goBack();
    }
  }, [redirect]);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      dispatch(fileGetOne(id));
    } else {
      setPath(curFile.path);
      setName(curFile.name);
      setNumber(curFile.number);
      setPreviewBackgroundURL(curFile.background);
      setPreviewCoverURL(curFile.cover);
    }
  }, [curFile.name]);

  useEffect(() => {
    if (!curFile.products?.length && !product.length) {
      dispatch(productGetAll());
    }
  }, [product.length]);

  useEffect(() => {
    if (product.length) {
      setProducts(product);
    }
  }, [product.length]);

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Изменение файла продукта</h2>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            if (errors.name) dispatch(fileCleanErrors('name'));
          }}
          error={errors.name || ''}
          placeholder='Название папки'
          type='text'
          name='name'
          inputCls='create__input'
          label='Название папки'
        />
        <Input
          value={path}
          onChange={(e) => {
            setPath(e.target.value);
            if (errors.path) dispatch(fileCleanErrors('path'));
          }}
          error={errors.path || ''}
          placeholder='Путь к папке'
          type='text'
          name='path'
          inputCls='create__input'
          label='Путь к папке'
        />
        <Input
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
            if (errors.number) dispatch(fileCleanErrors('number'));
          }}
          error={errors.number || ''}
          placeholder='Последовательность'
          type='text'
          name='name'
          inputCls='create__input'
          label='Последовательность'
        />

        <FileInput
          label='Загрузки файла фона'
          name='edit-file-background'
          previewURL={previewBackgroundURL ? previewBackgroundURL : ''}
          onChange={(e) => {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
              setFileBackgroundFile(file);
              setPreviewBackgroundURL(reader.result);
            };
            reader.readAsDataURL(file);
          }}
          inputCls='create-file-input'
        />

        <FileInput
          label='Загрузки файла обложки'
          name='edit-file-cover'
          previewURL={previewCoverURL ? previewCoverURL : ''}
          onChange={(e) => {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
              setFileCoverFile(file);
              setPreviewCoverURL(reader.result);
            };
            reader.readAsDataURL(file);
          }}
          inputCls='create-file-input'
        />

        {curFile.products?.length ? (
          <div className='create__info'>
            <p style={{ marginBottom: 16 }}>Этот файл прикреплен к продуктам:</p>
            <ul>
              {curFile.products.map((p) => (
                <li key={p.id}>{`${p.innerName}  (ID: ${p.id})`}</li>
              ))}
            </ul>
          </div>
        ) : (
          <DropDown text='Прикрепить к продукту' arrayOfValues={products} onChange={setProducts} radio={true} />
        )}

        {curFile.products?.length ? (
          <button
            className='button'
            disabled={!name && !path}
            onClick={() =>
              dispatch(
                fileUpdate(id, {
                  path,
                  name,
                  number,
                  productId: curFile.products[0]?.id,
                  background: fileBackgroundFile,
                  cover: fileCoverFile,
                }),
              )
            }
          >
            Сохранить
          </button>
        ) : (
          <button
            className='button'
            disabled={!name && !path}
            onClick={() =>
              dispatch(
                fileUpdate(id, {
                  path,
                  name,
                  number,
                  productId: products.find(({ check }) => check)?.id,
                  background: fileBackgroundFile,
                  cover: fileCoverFile,
                }),
              )
            }
          >
            Сохранить
          </button>
        )}

        <ButtonLink text='Назад к списку' btnClass='create__prev' onClick={() => history.goBack()} />
      </div>
    </>
  );
};

export default FileEditPage;
