import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { setStrikesCleanRedirect, strikesUpdate, strikesGetOne } from 'store/slices/strikes';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Loader from '../../assets/components/Loader';

const StrikesEditPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoad = useSelector((state) => state.strikes.isLoad);
  const redirect = useSelector((state) => state.strikes.redirect);
  const curStrikes = useSelector((state => state.strikes.curStrikes));

  const [nameStrike, setNameStrike] = useState('');
  const [strikeDate, setStrikeDate] = useState(null);

  useEffect(() => {
    curStrikes.description && setNameStrike(curStrikes.description);
    curStrikes?.strokeAt && setStrikeDate(new Date(curStrikes?.strokeAt.slice(0, 10)));
  }, [curStrikes])

  useEffect(() => {
    dispatch(strikesGetOne(id))
  }, [])

  useEffect(() => {
    if (redirect) {
      dispatch(setStrikesCleanRedirect());
      history.goBack();
    }
  }, [redirect]);

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Редактирование страйка</h2>
        <div className='strikes-date'>
          <label className='create__label'>Укажите Дату</label>
          <DatePicker
            selected={strikeDate}
            width={300}
            onChange={(e) => setStrikeDate(e)}
            isClearable={true}
            dateFormat="dd.MM.yyyy"
            className="strikes-dataPicker"
            placeholderText="дд.мм.гггг"
          />
        </div>
        <div className="textarea-strikes">
          <label className='create__label'>Описание</label>
          <textarea
            value={nameStrike}
            onChange={(e) => { setNameStrike(e.target.value) }}
            placeholder='Описание'
            type='text'
            name='nameStrike'
          />
        </div>
        {curStrikes.id && (
          <>
            <p className='create__info'>
              {/* Этот пиксель прикреплен к продукту {curPixel.productName} (ID: {curPixel.productId}) */}
            </p>
            <button
              className='button'
              onClick={() =>
                dispatch(strikesUpdate(curStrikes.id,
                  {
                    description: nameStrike,
                    id: id,
                    strokeAt: `${format(strikeDate, 'dd-MM-yyyy')} 00:00:00`
                  }))}>
              Сохранить
            </button>
          </>
        )}
        <button
          onClick={() => history.goBack()}
          className='btn btn-back'
        >
          <span className='btn__text'>Вернутся назад</span>
        </button>
      </div>
    </>
  );
};

export default StrikesEditPage;
