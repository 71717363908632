import React from 'react';
import Header from 'assets/components/Header/Header';
import Loader from 'assets/components/Loader';
import Table from 'assets/components/Table';
import Filters from 'assets/components/Filters/Filters';
import { getStatisticsGateways, setGatewayFilter } from 'store/slices/gateway';
import { companyGetAll } from 'store/slices/company';
import { useAppDispatch, useAppSelector } from 'store';

const titleStatistics = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'Статус',
  },
  {
    name: 'Дата',
  },
  {
    name: 'Логи',
    mod: 'big',
  },
  {
    name: '% Успешных Ребиллов',
    mod: 'big',
  },
  {
    name: 'Время жизни Сбербилл',
    mod: 'big',
  },
  {
    name: '% отписок',
  },
  {
    name: 'Кол-во Билл',
    mod: 'm',
  },
  {
    name: 'Кол-во Ребилл',
    mod: 'm',
  },
  {
    name: 'Сбероут',
  },
  {
    name: 'Сбербилл',
  },
];

const StatisticsTabPage = () => {
  const dispatch = useAppDispatch();
  const gatewayStatistics = useAppSelector((state) => state.gateway.gatewayStatistics);
  const appliedFilters = useAppSelector((state) => state.gateway.filters);
  const isLoad = useAppSelector((state) => state.gateway.isLoading);
  const companiesOption = useAppSelector((state) =>
    state.company.list?.map((item) => ({ value: item.id, label: item.name })),
  );

  React.useEffect(() => {
    let params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key];
      }
    }
    if (Object.values(params).length) {
      dispatch(getStatisticsGateways(params));
    }
    
  }, [getStatisticsGateways, appliedFilters]);

  React.useEffect(() => {
    dispatch(companyGetAll());
  }, []);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='Статистика' />
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => dispatch(setGatewayFilter(obj))}
        gatewayStatistics={true}
        companiesOption={companiesOption}
      />
      <Table titles={titleStatistics} content={gatewayStatistics} keyProp='gateway-statistics' />
    </>
  );
};

export default StatisticsTabPage;
