import Modal from './Modal';
import { connect } from 'react-redux';
import { modalData } from 'store/slices/modal';

const mapStateToProps = (state) => {
  return {
    modalStatus: state.modal.modalStatus,
    modalText: state.modal.modalText,
    modalType: state.modal.modalType,
  };
};
const mapDispatchToProps = {
  modalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
