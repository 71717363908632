import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: false,
  data: [],
  error: null,
}

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrencyData(state, action) {
      state.data = action.payload.map((currency, index) => {
        return { value: index, label: currency}
      });
    },
    setError(state, action) {
      state.error = action.payload;
    },
  }
});

const {
  reducer: currency,
  actions: { setIsLoading, setCurrencyData, setError }
} = currencySlice;

const getAllCurrencies = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/currencies')
    .then((res) => dispatch(setCurrencyData(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке валюты')))
    .finally(() => dispatch(setIsLoading(false)));
}

export { currency, getAllCurrencies };