import React, { useEffect, useState } from 'react'
import Header from 'assets/components/Header'
import Table from 'assets/components/Table'
import Loader from 'assets/components/Loader'
import Filters from 'assets/components/Filters'
import Pagination from 'rc-pagination'
import { companyGetAll } from 'store/slices/company'
import { useAppSelector, useAppDispatch } from 'store'
import EditModalDomain from './EditModalDomain'
import { toast } from 'react-toastify'
import { getRegions } from 'store/slices/domain'
import {
	domainGetAll,
	domainDelete,
	setDomainFilter,
	setDomainPage,
	domainFolderGetAll,
	domainMassUpdate,
	setDomainMassUpdateStatus,
	setErrorMessage
} from 'store/slices/domain'
import { getAllGateways } from 'store/slices/gateway'
import { productGetAll } from 'store/slices/product'
import { modalCleanData } from 'store/slices/modal'

const titles = [
	{
		name: 'ID',
		mod: 'sm'
	},
	// {
	//   name: 'название',
	//   mod: 'big',
	// },
	{
		name: 'домен',
		mod: 'big'
	},
	{
		name: 'продукт',
		mod: 'big'
	},
	{
		name: 'landFooter',
		mod: 'big'
	},
	{
		name: 'билл',
		onClick: true,
		title: 'domainBill'
	},
	// {
	//   name: 'домен',
	//   mod: 'big',
	// },
	{
		name: 'статус'
	},
	{
		name: 'шлюзы',
		mod: 'big'
	},
	{
		name: 'folder'
	},
	{
		name: 'создан'
	},
	{
		name: '...',
		mod: 'm'
	}
]

const DomainPage = () => {
	const domains = useAppSelector(state => state.domain.list)?.map(el => ({
		...el,
		createdAt: el.createdAt.replace('T', ' ')
	}))
	const regionIdOptions = useAppSelector(state => state.domain.regionIds).map(
		el => ({ ...el, label: el.name, value: el.id })
	)
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const errors = useAppSelector(state => state.domain.errors)
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const appliedFilters = useAppSelector(state => state.domain.filters)
	const isLoad = useAppSelector(state => state.domain.isLoad)
	const availableEdit = useAppSelector(
		state => state.auth.role.permissions.domain
	)?.includes('edit_domains')
	const productsList = useAppSelector(state => state.product.list)
	const limit = useAppSelector(state => state.domain.pagination.limit)
	const page = useAppSelector(state => state.domain.pagination.page)
	const total = useAppSelector(state => state.domain.pagination.total)
	const folderList = useAppSelector(state => state.domain.folderList)?.map(
		item => ({ value: item, label: item })
	)
	const errorMessage = useAppSelector(state => state.domain.errorMessage)
	const dispatch = useAppDispatch()
	const [valueSelect, setValueSelect] = useState([])
	const [valueSelectString, setValueSelectString] = useState('')
	const [paymentSystem, setPaymentSystem] = useState([])

	const [noRef, setNoRef] = useState(false)
	const [res, setRes] = useState(false)
	const [sberbill, setSberbill] = useState(false)
	const [paymentSystemToAdd, setPaymentSystemToAdd] = useState(null)
	const [paymentSystemTitleToAdd, setPaymentSystemTitleToAdd] = useState(null)
	const [paymentGatewayToAdd, setPaymentGatewayToAdd] = useState('')
	const [paymentChanceToAdd, setPaymentChanceToAdd] = useState('')

	const [auth, setAuth] = useState('')
	const [refund, setRefund] = useState('')
	const [unsubscribe, setUnsubscribe] = useState('')
	const [footer, setFooter] = useState('')
	const [landFooter, setLandFooter] = useState(null)

	const companies = useAppSelector(state => state.company.list)
	const gateways = useAppSelector(state => state.gateway.gateways)
	const massUpdateMessage = useAppSelector(
		state => state.domain.massUpdateMessage
	)

	const [isCheck, setIsCheck] = useState([])
	const [isCheckAll, setIsCheckAll] = useState(false)
	const [activeStatus, setActiveStatus] = useState('')

	const [paymentSettingsAdd, setPaymentSettingsAdd] = useState([])

	const [active, setActive] = useState(false)
	const [reloadPage, setReloadPage] = useState(true)

	const multiSelectOptions = productsList
		.filter(item => item.innerName !== '')
		.reverse()

	const handleOnChangeMultiSelect = value => {
		setValueSelect(value)
		setValueSelectString(value.map(item => item.id).toString())
	}

	useEffect(() => {
		if (errorMessage) {
			toast.error(errorMessage)
			dispatch(setErrorMessage(''))
		}
	}, [errorMessage])

	useEffect(() => {
		if (massUpdateMessage === 'success') {
			handleCliсkCloseTable()
			toast.success('Редактирование прошло успешно')
			dispatch(setDomainMassUpdateStatus(''))
		}
		if (massUpdateMessage === 'error') {
			toast.error(errors)
			dispatch(setDomainMassUpdateStatus(''))
		}
	}, [massUpdateMessage])

	useEffect(() => {
		dispatch(companyGetAll())
		dispatch(domainFolderGetAll())
		dispatch(getRegions())
	}, [])

	useEffect(() => {
		dispatch(getAllGateways())
		dispatch(productGetAll())
	}, [])

	useEffect(() => {
		const handleGetDomain = async () => {
			let params = {}

			for (let key in appliedFilters) {
				if (appliedFilters[key] && key !== 'landFooter') {
					params[`filter[${key}]`] = await appliedFilters[key]
				} else if (key === 'landFooter') {
					params[`filter[options][${key}]`] = await appliedFilters[key]
				}
			}

			params.limit = limit
			params.page = page

			dispatch(domainGetAll(params))
		}

		if (reloadPage) {
			handleGetDomain()
			setReloadPage(false)
		}
	}, [reloadPage])

	useEffect(() => {
		if (massUpdateMessage === 'success') {
			let params = {}

			for (let key in appliedFilters) {
				if (appliedFilters[key] && key !== 'landFooter') {
					params[`filter[${key}]`] = appliedFilters[key]
				} else if (key === 'landFooter') {
					params[`filter[options][${key}]`] = appliedFilters[key]
				}
			}

			params.limit = limit
			params.page = page

			dispatch(domainGetAll(params))
			dispatch(productGetAll())
		}
	}, [massUpdateMessage])

	const handlePageChange = current => {
		dispatch(setDomainPage(current))
		setReloadPage(true)
	}

	useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(domainDelete(infoPayload.id))
			dispatch(modalCleanData())
		}
	}, [modalStatus])

	useEffect(() => {
		gateways?.length > 1 &&
			setPaymentSystem(
				gateways
					.map(el => ({
						label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
						value: el.id,
						paymentSystem: el.paymentSystem,
						name: el.name,
						id: el.id,
						companyName: el.companyName,
						paymentSystemTitle: el.paymentGatewayTitle
					}))
					.sort((a, b) => (a.label > b.label ? 1 : -1))
			)
	}, [gateways])

	const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
		<div>
			<div>
				<strong>ID: </strong>
				{id}
			</div>
			<div>
				<strong>Платёжная система: </strong>
				{paymentSystem}
			</div>
			<div>
				<strong>Платёжный шлюз: </strong>
				{name}
			</div>
			<div>
				<strong>ЮрЛицо: </strong>
				{companyName ? companyName : '—'}
			</div>
		</div>
	)

	const companiesOption = companies.map(item => ({
		value: item.id,
		label: item.name
	}))

	const handleClickCheck = e => {
		const { id, checked } = e.target
		setIsCheck([...isCheck, id])
		if (!checked) {
			setIsCheck(isCheck.filter(item => item !== id))
		}
	}

	const handleClickCheckSelectAll = e => {
		setIsCheckAll(!isCheckAll)
		setIsCheck(domains.map(li => li.id))
		if (isCheckAll) {
			setIsCheck([])
		}
	}

	const handleClickStartEdit = () => {
		setActive(true)
	}

	const handleCliсkCloseTable = () => {
		setActive(false)
		setIsCheck([])
		setIsCheckAll(false)
		setPaymentSettingsAdd([])
		setPaymentSystemToAdd(null)
		setPaymentSystemTitleToAdd(null)
		setPaymentGatewayToAdd('')
		setPaymentChanceToAdd('')
		setNoRef(false)
		setRes(false)
		setSberbill(false)
		setActiveStatus('')
		setAuth('')
		setRefund('')
		setUnsubscribe('')
		setFooter('')
		setLandFooter(null)
	}

	const handlePaymentChanceToAddChange = e => {
		if (+e.target.value < 0) {
			return setPaymentChanceToAdd(0)
		}
		if (+e.target.value > 100) {
			return setPaymentChanceToAdd(100)
		}
		setPaymentChanceToAdd(+e.target.value)
	}

	const handlePaymentSettingsAdd = () => {
		setPaymentSettingsAdd(prev => [
			...prev,
			{
				paymentSystem: paymentSystemToAdd ?? '',
				paymentGateway: paymentGatewayToAdd,
				paymentGatewayTitle: paymentSystemTitleToAdd,
				chance: paymentChanceToAdd,
				noRef: noRef,
				res: res,
				sberbill: sberbill
			}
		])
		setPaymentSystemToAdd(null)
		setPaymentGatewayToAdd('')
		setPaymentChanceToAdd('')
		setPaymentSystemTitleToAdd(null)
		setNoRef(false)
		setRes(false)
		setSberbill(false)
	}

	const handleClickMassUpdate = () => {
		const obj = {
			ids: isCheck,
			status: activeStatus,
			paymentSettings: paymentSettingsAdd,
			auth: auth,
			unsubscribe: unsubscribe,
			refund: refund,
			footer: footer,
			landFooter: landFooter
		}

		for (let key in obj) {
			if (
				(obj[key] === '' && key !== 'landFooter') ||
				(Array.isArray(obj[key]) && obj[key].length === 0) ||
				obj[key] === null
			) {
				delete obj[key]
			}
		}

		dispatch(domainMassUpdate(obj))
	}

	return (
		<>
			{isLoad ? <Loader /> : ''}
			<Header
				title='Домены'
				text={availableEdit ? 'Добавить домен' : null}
				href={availableEdit ? '/domain/add' : null}
				secondText={availableEdit ? 'СберБилл' : null}
				secondHref={availableEdit ? '/domain/sberbill' : null}
			/>
			<Filters
				appliedFilters={{ ...appliedFilters }}
				onFilterChange={obj => {
					dispatch(setDomainFilter(obj))
					setReloadPage(true)
				}}
				domainPage={true}
				multiSelectOptions={multiSelectOptions}
				handleOnChangeMultiSelect={handleOnChangeMultiSelect}
				valueSelect={valueSelect}
				valueSelectString={valueSelectString}
				companiesOption={companiesOption}
				statusLabels={[
					{ label: 'Активный', value: '1' },
					{ label: 'Неактивный', value: '2' }
				]}
				regionIdOptions={regionIdOptions}
				formatOptionLabel={formatOptionLabel}
				gatewaysOption={paymentSystem}
				folderListOption={folderList}
			/>
			<Table
				titles={titles}
				content={domains}
				isCheck={isCheck}
				isCheckAll={isCheckAll}
				handleClickCheck={handleClickCheck}
				handleClickCheckSelectAll={handleClickCheckSelectAll}
				handleClickStartEdit={handleClickStartEdit}
				keyProp='domain'
			/>
			<Pagination
				className='order__pagination'
				current={page}
				total={total}
				pageSize={limit}
				onChange={handlePageChange}
				showTitle={false}
			/>
			{active && (
				<EditModalDomain
					paymentSystem={paymentSystem}
					formatOptionLabel={formatOptionLabel}
					setPaymentSystemToAdd={setPaymentSystemToAdd}
					setPaymentSystemTitleToAdd={setPaymentSystemTitleToAdd}
					setPaymentGatewayToAdd={setPaymentGatewayToAdd}
					paymentChanceToAdd={paymentChanceToAdd}
					handlePaymentChanceToAddChange={handlePaymentChanceToAddChange}
					handlePaymentSettingsAdd={handlePaymentSettingsAdd}
					paymentSettingsAdd={paymentSettingsAdd}
					setPaymentSettingsAdd={setPaymentSettingsAdd}
					paymentSystemTitleToAdd={paymentSystemTitleToAdd}
					handleCliсkCloseTable={handleCliсkCloseTable}
					paymentGatewayToAdd={paymentGatewayToAdd}
					handleClickMassUpdate={handleClickMassUpdate}
					noRef={noRef}
					setNoRef={setNoRef}
					res={res}
					setRes={setRes}
					sberbill={sberbill}
					setSberbill={setSberbill}
					activeStatus={activeStatus}
					setActiveStatus={setActiveStatus}
					auth={auth}
					setAuth={setAuth}
					refund={refund}
					setRefund={setRefund}
					unsubscribe={unsubscribe}
					setUnsubscribe={setUnsubscribe}
					footer={footer}
					setFooter={setFooter}
					landFooter={landFooter}
					setLandFooter={setLandFooter}
				/>
			)}
		</>
	)
}

export default DomainPage
