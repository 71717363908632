import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: false,
  banksList: [],
  error: null,
  allBanksList: [],
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  updateIssuerStatus: '',
};

const bankSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setBanksList(state, action) {
      state.banksList = action.payload.items;
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setBanksFilter(state, action) {
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setBanksPage(state, action) {
      state.pagination.page = action.payload;
    },
    setUpdateIssuerStatus(state, action) {
      state.updateIssuerStatus = action.payload;
    },
    setAllBanksList(state, action) {
      state.allBanksList = action.payload;
    }
  },
});

const {
  reducer: banks,
  actions: { setIsLoading, setAllBanksList, setBanksList, setError, setBanksFilter, setBanksPage, setUpdateIssuerStatus },
} = bankSlice;

const getBanksList = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/bank/issuer', { params })
    .then((res) => dispatch(setBanksList(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке банков')))
    .finally(() => dispatch(setIsLoading(false)));
};

const updateBankIssuer = (payload) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .post('/admin/bank/issuer', payload)
    .then(() => {
      dispatch(getBanksList())
      dispatch(setUpdateIssuerStatus('success'));
    })
    .catch((err) => {
      dispatch(setError(err.response?.data?.error?.message ?? 'Произошла ошибка'))
      dispatch(setUpdateIssuerStatus('error'));
    })
    .finally(() => dispatch(setIsLoading(false)));
};

export const getAllBanksList = () => (dispatch) => {
  return request.get('/admin/banks/from/orders').then((res) => dispatch(setAllBanksList(res.data)));
};

export { getBanksList, banks, setBanksFilter, setBanksPage, updateBankIssuer, setUpdateIssuerStatus, setError };
