import Loader from 'assets/components/Loader';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startStatisticsGetAll } from 'store/slices/statistics';
import { DynamicCharts } from '../DynamicCharts';

const Statistics = () => {
  const dispatch = useDispatch();
  const [showStatistics, setShowStatistics] = useState(false);
  const { data, isLoading } = useSelector((state) => state.statistics);

  const handleShowStatisctics = () => {
    setShowStatistics(true);
    dispatch(startStatisticsGetAll());
  }

  return (<>{showStatistics === false ? <button onClick={handleShowStatisctics} className="statistics-btn">Показать статистику</button> : null}
    {showStatistics && isLoading ? <Loader /> : <DynamicCharts data={data} />}
  </>);
};

export { Statistics };