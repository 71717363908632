import React, { useCallback, useEffect } from 'react';
import Select from 'react-select';
import Table from 'assets/components/Table';
import Header from 'assets/components/Header/Header';
import Loader from 'assets/components/Loader';
import Filters from 'assets/components/Filters';
import { getSberBill, setSberBillFilter, setSberBillStatus, sberBillPost, sberBillDelete } from 'store/slices/sberbill';
import { getAllGateways } from 'store/slices/gateway';
import { modalCleanData } from 'store/slices/modal';
import { companyGetAll } from 'store/slices/company';
import { useAppDispatch, useAppSelector } from 'store';

const titleSberBill = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'НАЗВАНИЕ',
    mod: 'big',
  },
  {
    name: 'комментарий',
    mod: 'big',
  },
  {
    name: 'ЮрЛицо',
    mod: 'big',
  },
  {
    name: 'ПЛАТЕЖКА',
    mod: 'big',
  },
  {
    name: '...',
    mod: 'm',
  },
];

const SberBillPage = () => {
  const dispatch = useAppDispatch();
  const isLoad = useAppSelector((state) => state.sberBill.isLoad);
  const sberBill = useAppSelector((state) => state.sberBill.sberBill);
  const appliedFilters = useAppSelector((state) => state.sberBill.filters);
  const companiesOption = useAppSelector((state) => state.company.list).map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const gateways = useAppSelector((state) => state.gateway.gateways);
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);
  const sberBillStatus = useAppSelector((state) => state.sberBill.sberBillStatus);
  const whitePaymentGatewaysCount = useAppSelector((state) => state.sberBill.whitePaymentGatewaysCount);
  const dirtyPaymentGatewaysCount = useAppSelector((state) => state.sberBill.dirtyPaymentGatewaysCount);
  const totalPaymentGatewaysCount = useAppSelector((state) => state.sberBill.totalPaymentGatewaysCount);

  const [paymentSystem, setPaymentSystem] = React.useState([]);
  const [selectGatewayId, setSelectGatewayId] = React.useState('');
  const [firstRequest, setFirstRequest] = React.useState(false);

  const handlePaymentGatewayIdAdd = useCallback(() => {
    dispatch(sberBillPost({ gatewayId: selectGatewayId }));
  }, [selectGatewayId]);

  useEffect(() => {
    if (sberBillStatus === 'success') {
      let params = {};

      for (let key in appliedFilters) {
        if (appliedFilters[key]) {
          params[`filter[${key}]`] = appliedFilters[key];
        }
      }
      if (Object.values(params).length) {
        dispatch(getSberBill(params));
        dispatch(setSberBillStatus(''));
      }

      dispatch(companyGetAll());
    }
  }, [sberBillStatus]);

  useEffect(() => {
    let params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key];
      }
    }
    if (Object.values(params).length || firstRequest) {
      dispatch(getSberBill(params));
    } else {
      setFirstRequest(true);
    }

    dispatch(companyGetAll());
  }, [appliedFilters]);

  useEffect(() => {
    dispatch(getAllGateways());
  }, []);

  useEffect(() => {
    gateways?.length > 1 &&
      setPaymentSystem(
        gateways
          .map((el) => ({
            label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
            value: el.id,
            paymentSystem: el.paymentSystem,
            name: el.name,
            id: el.id,
            companyName: el.companyName,
            paymentSystemTitle: el.paymentGatewayTitle,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      );
  }, [gateways]);

  const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {id}
      </div>
      <div>
        <strong>Платёжная система: </strong>
        {paymentSystem}
      </div>
      <div>
        <strong>Платёжный шлюз: </strong>
        {name}
      </div>
      <div>
        <strong>ЮрЛицо: </strong>
        {companyName ? companyName : '—'}
      </div>
    </div>
  );

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      display: 'inline-block',
      width: '400px',
      height: '80px',
      minHeight: '1px',
      textAlign: 'left',
      border: 'none',
    }),
    control: (provided) => ({
      ...provided,
      height: '80px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '80px',
    }),
  };

  useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(sberBillDelete(infoPayload.id));
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='СберБилл' />
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => dispatch(setSberBillFilter(obj))}
        sberBill={true}
        companiesOption={companiesOption}
      />
      {whitePaymentGatewaysCount !== '' ? (
        <div style={{ marginTop: '20px' }}>Чистые: {whitePaymentGatewaysCount}</div>
      ) : null}
      {dirtyPaymentGatewaysCount !== '' ? (
        <div style={{ marginTop: '20px' }}>Грязные: {dirtyPaymentGatewaysCount}</div>
      ) : null}
      {totalPaymentGatewaysCount !== '' ? (
        <div style={{ marginTop: '20px' }}>Всего: {totalPaymentGatewaysCount}</div>
      ) : null}
      <Table titles={titleSberBill} content={sberBill} keyProp='sberBill' />
      <div className='add-sberBill'>
        <div className='add-sberBill__paymentSystem'>
          <label className='input__label'>Селектор</label>
          <Select
            options={paymentSystem}
            styles={selectStyles}
            value={paymentSystem.filter((option) => option.id === selectGatewayId)}
            formatOptionLabel={formatOptionLabel}
            onChange={(data) => {
              setSelectGatewayId(data.id);
            }}
          />
        </div>
        <div className='add-sberBill__btn'>
          <button onClick={handlePaymentGatewayIdAdd} className='button'>
            Добавить
          </button>
        </div>
      </div>
    </>
  );
};

export default SberBillPage;
