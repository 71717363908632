import {
  FILE_GET_ALL_REQUEST,
  FILE_GET_ALL_SUCCESS,
  FILE_GET_ALL_FAILURE,
  FILE_CREATE_FAILURE,
  FILE_CREATE_REQUEST,
  FILE_CREATE_SUCCESS,
  FILE_CLEAN_ERROR_LOCALE,
  FILE_CLEAN_REDIRECT_LOCALE,
  FILE_DELETE_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_GET_ONE_FAILURE,
  FILE_GET_ONE_REQUEST,
  FILE_GET_ONE_SUCCESS,
  FILE_UPDATE_FAILURE,
  FILE_UPDATE_REQUEST,
  FILE_UPDATE_SUCCESS,
  FILE_IMAGE_REQUEST,
  FILE_IMAGE_SUCCESS,
  FILE_IMAGE_FAILURE
} from 'actions/file';

const initialState = {
  list: [],
  errors: {},
  curFile: {},
  redirect: false,
  isLoad: false,
};

export default function file(state = initialState, action) {
  switch (action.type) {
    case FILE_GET_ALL_FAILURE:
      return { ...state, isLoad: false };
    case FILE_GET_ALL_REQUEST:
      return { ...state, isLoad: true };
    case FILE_GET_ALL_SUCCESS:
      return { ...state, list: action.payload, isLoad: false };

    case FILE_CREATE_REQUEST:
      return { ...state, isLoad: true };
    case FILE_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        isLoad: false,
      };
    case FILE_CREATE_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isLoad: false,
      };

    case FILE_GET_ONE_REQUEST:
      return { ...state, curFile: {}, isLoad: true };
    case FILE_GET_ONE_SUCCESS:
      return {
        ...state,
        curFile: { ...action.payload },
        errors: {},
        isLoad: false,
      };
    case FILE_GET_ONE_FAILURE:
      return { ...state, curFile: {}, errors: { file: 'не найден' }, isLoad: false };

    case FILE_UPDATE_REQUEST:
      return { ...state, isLoad: true };
    case FILE_UPDATE_SUCCESS:
      return { ...state, redirect: true, isLoad: false };
    case FILE_UPDATE_FAILURE:
      return { ...state, errors: action.payload, isLoad: false };

    case FILE_DELETE_REQUEST:
      return { ...state, isLoad: true };
    case FILE_DELETE_SUCCESS:
      return { ...state, isLoad: false };
    case FILE_DELETE_FAILURE:
      return { ...state, isLoad: false };

    case FILE_CLEAN_ERROR_LOCALE:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload]: '',
        },
      };

    case FILE_CLEAN_REDIRECT_LOCALE:
      return {
        ...state,
        redirect: false,
      };
    case FILE_IMAGE_REQUEST:
      return { ...state, isLoad: true };
    case FILE_IMAGE_SUCCESS:
      return { ...state, isLoad: false };
    case FILE_IMAGE_FAILURE:
      return { ...state, isLoad: false };
    default:
      return state;
  }
}
