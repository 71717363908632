import React from 'react';
import cn from 'classnames';

const ProductDescr = ({ value, onChange = () => {}, error = '', placeholder }) => (
  <div className='description'>
    <textarea
      className='description__area'
      cols='50'
      rows='15'
      placeholder={placeholder || ''}
      name='description'
      value={value}
      onChange={onChange}
    />
    <div className={cn('error', 'description__error')}>{error}</div>
  </div>
);

export default ProductDescr;
