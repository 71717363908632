import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoad: false,
  list: [],
};

const sberalertSlice = createSlice({
  name: 'sberalert',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setSberAlertData(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
  },
});

const {
  reducer: sberalert,
  actions: { setIsLoad, setSberAlertData },
} = sberalertSlice;

export const sberAlertGetAll = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/sberalert')
    .then((res) => {
      dispatch(setSberAlertData(res.data));
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export const sberAlertFreezeUpdateStatus = (gatewayId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/sberalert/freeze`, { gatewayId })
    .then(() => {
      dispatch(sberAlertGetAll());
      dispatch(setIsLoad(false));
    })
    .catch((error) => {
      dispatch(setIsLoad(false));
      //   dispatch(sberAlertFreezeUpdateFailure(error.response.data.error));
    });
};

export const sberAlertUnfreezeUpdateStatus = (gatewayId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/sberalert/unfreeze`, { gatewayId })
    .then(() => {
      dispatch(sberAlertGetAll());
      dispatch(setIsLoad(false));
    })
    .catch((error) => {
      dispatch(setIsLoad(false));
    //   dispatch(sberAlertUnfreezeUpdateFailure(error.response.data.error));
    });
};

export { sberalert };
