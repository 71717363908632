import React, { useEffect, useState } from 'react';
import { sberAlertGetAll, sberAlertUnfreezeUpdateStatus, sberAlertFreezeUpdateStatus } from 'store/slices/sberalert';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from '../../assets/components/Loader';
import PopupRefund from 'assets/components/PopupRefund';

const titleSberAlert = [
  {
    name: 'статус',
  },
  {
    name: 'шлюз',
    mod: 'stats',
  },
  {
    name: 'заказы',
  },
  {
    name: 'активные',
    mod: 'stats',
  },
  {
    name: 'замороженные',
    mod: 'stats',
  },
  {
    name: 'управление статусом',
  },
];

const SberAlertPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [editItem, setEditItem] = useState({});
  const dispatch = useDispatch();
  const sberAlertList = useSelector((state) => state.sberalert.list);
  const isLoad = useSelector((state) => state.sberalert.isLoad);

  const handleUpdateSberAlertButton = (item) => {
    setEditItem(item);
    setShowPopup(true);
  };

  const handleFreezeUpdateStatus = () => {
    dispatch(sberAlertFreezeUpdateStatus(Number(editItem.gateway_id)));
    setShowPopup(false);
  };

  const handleUnfreezeUpdateStatus = () => {
    dispatch(sberAlertUnfreezeUpdateStatus(Number(editItem.gateway_id)));
    setShowPopup(false);
  };

  useEffect(() => {
    dispatch(sberAlertGetAll());
  }, [dispatch]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='СберАлерт' />
      <Table
        handleUpdateSberAlertButton={handleUpdateSberAlertButton}
        titles={titleSberAlert}
        content={sberAlertList}
        keyProp='sberalert'
      />
      {showPopup && (
        <PopupRefund
          message={`Вы уверены что хотите изменить статус домена ${editItem.gateway_name}`}
          onConfirm={editItem.status === 'Включен' ? handleFreezeUpdateStatus : handleUnfreezeUpdateStatus}
          onCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default SberAlertPage;
