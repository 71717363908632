import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAppSelector, useAppDispatch } from 'store';
import Input from 'assets/components/Input';
import { useHistory } from 'react-router-dom';
import { modalCleanData } from 'store/slices/modal';
import Table from 'assets/components/Table';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Loader from '../../assets/components/Loader';

import { getGatewaysMoveInfo, moveGateways, getAdminLogs, setMoveResult, cancelGatewayLogs, setMoveResultSuccessMessage } from 'store/slices/gateway';

const GatewayTransferPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const gateways = useAppSelector((state) => state.gateway.gatewaysMove);
  const isLoad = useAppSelector((state) => state.gateway.isLoad);
  const adminLogs = useAppSelector((state) => state.gateway.adminLogs);
  const moveResult = useAppSelector((state) => state.gateway.moveResult);
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);
  const lastTransactionChecked = useAppSelector((state) => state.gateway.lastTransactionChecked);
  const lastTransactionList = useAppSelector((state) => state.gateway.lastTransactionList);
  const errorMove = useAppSelector((state) => state.gateway.error);
  const moveResultSuccessMessage = useAppSelector((state) => state.gateway.moveResultSuccessMessage);
  const [gatewayOption, setGatewayOption] = useState([]);
  const [amount, setAmount] = useState(null);
  const [fromId, setFromId] = useState('');
  const [toId, setToId] = useState('');
  const [issuer, setIssuer] = useState('');
  const [paymentSystemName, setPaymentSystemName] = useState('');
  const [lastTransactionCheckedDefault, setLastTransactionCheckedDefault] = useState([]);
  const [lastTransactionErrorOption, setLastTransactionErrorOption] = useState([]);
  const [lastTransactionErrorValue, setLastTransactionErrorValue] = useState([]);

  useEffect(() => {
    if (moveResult === 'success' && (moveResultSuccessMessage || moveResultSuccessMessage === 0)) {
      toast.success(`Перенесено заказов: ${moveResultSuccessMessage}`);
      dispatch(setMoveResult(''));
      dispatch(setMoveResultSuccessMessage(null));
    }
    if (moveResult === 'error') {
      toast.error(errorMove);
      dispatch(setMoveResult(''));
    }
  }, [moveResult, moveResultSuccessMessage])

  useEffect(() => {
    dispatch(getGatewaysMoveInfo());
    dispatch(getAdminLogs());
  }, []);

  useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(cancelGatewayLogs({ adminLogId: infoPayload.id }));
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  useEffect(() => {
    gateways?.length && setGatewayOption(gateways.map((el) => ({
      label: `${el.paymentSystem}: ${el.name} (${el.company})`,
      value: el.id,
      paymentSystem: el.paymentSystem,
      name: el.name,
      id: el.id,
      companyName: el.company,
      orders: el.orders,
    })).sort((a, b) => a.label > b.label ? 1 : -1))
  }, [gateways]);

  useEffect(() => {
    setLastTransactionCheckedDefault(lastTransactionChecked);
  }, [lastTransactionChecked]);

  useEffect(() => {
    lastTransactionList?.[paymentSystemName]?.length > 0 && setLastTransactionErrorOption(lastTransactionList?.[paymentSystemName]);
    setLastTransactionErrorValue(lastTransactionCheckedDefault?.filter(lastTransactionCheckedDefaultItem => lastTransactionErrorOption.includes(lastTransactionCheckedDefaultItem)))
  }, [paymentSystemName, lastTransactionErrorOption]);

  const formatOptionLabel = ({ paymentSystem, name, id, companyName, orders }) => (
    <div>
      <div><strong>ID: </strong>{id}</div>
      <div><strong>Платёжная система: </strong>{paymentSystem}</div>
      <div><strong>Платёжный шлюз: </strong>{name}</div>
      <div><strong>ЮрЛицо: </strong>{companyName ? companyName : "—"}</div>
      <div><strong>Количество заказов: </strong>{orders}</div>
    </div>
  );

  const styles = {
    container: (provided) => ({
      ...provided,
      display: 'inline-block',
      width: '100%',
      height: "90px",
      minHeight: '1px',
      textAlign: 'left',
      border: 'none',
    }),
    control: (provided) => ({
      ...provided,
      height: "90px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "90px"
    }),
  }

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Перенос шлюзов</h2>
        <div className='domain-crt-select'>
          <label className='create__label-transfer'>С шлюза</label>
          <Select
            options={gatewayOption}
            formatOptionLabel={formatOptionLabel}
            value={gatewayOption[gatewayOption.findIndex((el) => el.value === fromId)] || fromId}
            onChange={(option) => {
              setFromId(option.value);
              setPaymentSystemName(option.paymentSystem);
            }}
            placeholder='Перенос с шлюза'
            styles={styles}
          />
        </div>
        <div className='domain-crt-select--second'>
          <label className='create__label-transfer'>На шлюз</label>
          <Select
            options={gatewayOption}
            formatOptionLabel={formatOptionLabel}
            value={gatewayOption[gatewayOption.findIndex((el) => el.value === toId)] || toId}
            onChange={(option) => {
              setToId(option.value)
            }}
            placeholder='Перенос на шлюз'
            styles={styles}
          />
        </div>
        {lastTransactionList?.[paymentSystemName] && <div className='domain-crt-select--second'>
          <label className='create__label-transfer'>Ошибки</label>
          <Select
            isMulti={true}
            options={lastTransactionErrorOption}
            value={lastTransactionErrorValue}
            onChange={(option) => {
              setLastTransactionErrorValue(option)
            }}
            getOptionLabel={option => option}
            getOptionValue={option => option}
            placeholder='Ошибки'
          />
        </div>}
        <div className='domain-crt-select--second'>
          <label className='create__label-transfer'>Количество заказов</label>
          <Input
            value={amount}
            onChange={(e) => { setAmount(e.target.value) }}
            placeholder='Количество заказов'
            type='text'
            name='amount'
            inputCls='create__input'
          />
        </div>
        <div className='domain-crt-select'>
          <label className='create__label-transfer'>Банк</label>
          <Input
            value={issuer}
            onChange={(e) => { setIssuer(e.target.value) }}
            placeholder='Банк'
            type='text'
            name='issuer'
            inputCls='create__input'
          />
        </div>
        <button
          className='button'
          disabled={!toId || !fromId}
          onClick={() => {
            dispatch(moveGateways(
              {
                amount: amount ? amount : null,
                toId,
                fromId,
                issuer, 	
                lastTransactionErrors: lastTransactionErrorValue.map(item => item === "Без ошибок" ?  "" : item), 
              }))
            setAmount('');
            setFromId('');
            setToId('');
            setIssuer('');
          }}
        >
          Запустить
        </button>
        <button
          onClick={() => history.goBack()}
          className={cn('btn', 'btn-back')}
        >
          <span className='btn__text'>Вернутся назад</span>
        </button>
        <div className='order-info-table'>
          <label className='order-info-label'>Логи операций</label>
          <Table
            titles={[
              { name: 'Создан', mod: 'big' },
              { name: 'Админ', mod: 'big' },
              { name: 'Исходный шлюз' },
              { name: 'Конечный шлюз' },
              { name: 'Банк', mod: 'big' },
              { name: 'Количество заказов' },
              { name: 'Ошибки', mod: 'big'},
              { name: '' },
            ]}
            content={adminLogs}
            keyProp='gatewayTransfer'
          />
        </div>
      </div>
    </>
  );
};

export default GatewayTransferPage;
