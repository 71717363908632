import React from 'react';
import Header from 'assets/components/Header';
import Statistics from './components/Statistics';

const DashboardPage = () => (
  <>
    <Header title='Dashboard' />
    <Statistics />
  </>
);

export default DashboardPage;
