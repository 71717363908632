import SideBar from './SideBar';
import { connect } from 'react-redux';
import { authLogout } from 'actions/auth';

const mapStateToProps = (state) => ({
  permissions: state.auth.role.permissions
})

const mapDispatchToProps = {
  authLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
