import React from 'react';
import cn from 'classnames';

const FileInput = ({
  inputCls, label, name, onChange = () => {}, previewURL
}) => (
  <div className={cn('file-input', inputCls)}>
    <label htmlFor={name}>{label}</label>
    <input type="file" id={name} onChange={onChange}/>
    {previewURL && <img src={previewURL} className='file-input__preview' alt="img"/> }
  </div>
)

export default FileInput;