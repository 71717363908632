import React from 'react';
import {
  startWebMasterStatisticsGetAll,
  setWebMasterPage,
  setChargebacksFilterWebMaster,
  setWebMasterStatus,
  deleteWebmasterCheck
} from 'store/slices/chargebacks';
import Table from 'assets/components/Table';
import Filters from 'assets/components/Filters';
import Pagination from 'rc-pagination';
import { useAppDispatch, useAppSelector } from 'store';
import { productGetAll } from 'store/slices/product';
import { getPaymentOptions } from 'store/slices/gateway';
import { modalCleanData } from 'store/slices/modal';
import { toast } from 'react-toastify';

const titles = [
  {
    name: 'web id',
  },
  {
    name: 'email',
    mod: 'stats',
  },
  {
    name: 'тип',
  },
  {
    name: 'создан',
  },
  {
    name: 'продукт id',
    mod: 'stats',
  },
  {
    name: 'сумма',
  },
  {
    name: 'лендинг',
    mod: 'stats',
  },
  {
    name: 'экваер',
    mod: 'stats',
  },
  {
    name: 'удаление транзакции',
  },
];

const WebMaster = () => {
  const dispatch = useAppDispatch();
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const webMastersList = useAppSelector((state) => state.chargebacks.webMastersList);
  const appliedFilters = useAppSelector((state) => state.chargebacks.filtersWebMaster);
  const limit = useAppSelector((state) => state.chargebacks.paginationWebMaster.limit);
  const page = useAppSelector((state) => state.chargebacks.paginationWebMaster.page);
  const total = useAppSelector((state) => state.chargebacks.paginationWebMaster.total);
  const productsListOption = useAppSelector((state) => state.product.list)
    .filter((item) => item.innerName !== '')
    .reverse()
    .map((option) => ({ label: option.innerName, value: option.id }));
  const payments = useAppSelector((state) => state.gateway.payments);
  const [paymentsOptions, setPaymentsOptions] = React.useState([]);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);
  const webmasterStatus = useAppSelector((state) => state.chargebacks.webmasterStatus);

  const handlePageChange = (current) => {
    dispatch(setWebMasterPage(current));
  };

  React.useEffect(() => {
    payments && setPaymentsOptions(Object.keys(payments).map((item) => ({ label: item, value: item })));
  }, [payments]);

  React.useEffect(() => {
    dispatch(productGetAll());
    dispatch(getPaymentOptions());
  }, []);

  React.useEffect(() => {
    if (webmasterStatus === "success") {
      let params = {};

      for (let key in appliedFilters) {
        if (appliedFilters[key]) {
          params[`filter[${key}]`] = appliedFilters[key];
        }
      }
      if (Object.values(params).length) {
        dispatch(startWebMasterStatisticsGetAll(params));
        dispatch(setWebMasterStatus(''));
      }
    }
  }, [webmasterStatus]);

  React.useEffect(() => {
    const params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key];
      }
    }

    params.limit = limit;
    params.page = page;

    dispatch(startWebMasterStatisticsGetAll(params));
  }, [startWebMasterStatisticsGetAll, appliedFilters, limit, page]);

  React.useEffect(() => {
    if (webmasterStatus === "success") {
      toast.success('Удаление прошло успешно');
      dispatch(setWebMasterStatus(''));
    }
    if (webmasterStatus === "error") {
      toast.error('Ошибка при удалении');
      dispatch(setWebMasterStatus(''));
    }
  }, [webmasterStatus]);

  React.useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(deleteWebmasterCheck(Number(infoPayload.id), 'delete'));
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  const formatOptionLabel = ({ value, label }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {value}
      </div>
      <div>
        <strong>Название продукта: </strong>
        {label}
      </div>
    </div>
  );

  return (
    <>
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => dispatch(setChargebacksFilterWebMaster(obj))}
        webmasterChargeback={true}
        limitPage={limit}
        multiSelectOptions={productsListOption}
        formatOptionLabel={formatOptionLabel}
        paymentsOptions={paymentsOptions}
        typeLabels={[
          { label: 'Обычный', value: '0' },
          { label: 'Арбитражный', value: '1' },
        ]}
      />
      <Table content={webMastersList} titles={titles} keyProp='chargeBacks-webmaster' />
      <Pagination
        className='order__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
    </>
  );
};

export default WebMaster;
