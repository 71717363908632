import React from 'react';
import Table from 'assets/components/Table';
import { useAppDispatch, useAppSelector } from 'store';
import { startSetAdmins } from 'store/slices/admin';

const titlesAdminTab = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'роль',
  },
  {
    name: 'имя',
    mod: 'big',
  },
  {
    name: '...',
    mod: 'm',
  },
];

const Admins = () => {
  const dispatch = useAppDispatch();
  const admins = useAppSelector((state) => state.admin.list);
  React.useEffect(() => {
    dispatch(startSetAdmins());
  }, [startSetAdmins]);

  return <Table titles={titlesAdminTab} content={admins} keyProp='admin' />;
};

export default Admins;
