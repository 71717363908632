import React from 'react';
import Header from 'assets/components/Header';

const MailPage = () => (
  <>
    <Header title='Email рассылки' text='Email' />
  </>
);

export default MailPage;
