import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoad: false,
  list: [],
  stats: [],
  error: null,
  filters: {
    dateFrom: null,
    dateTo: null,
  },
};

const shakerSlice = createSlice({
  name: 'shaker',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setShakerData(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
    setShakerFilter(state, action) {
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
    },
    setShakerDataStats(state, action) {
      state.isLoad = false;
      state.stats = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoad = false;
    },
  },
});

const {
  reducer: shaker,
  actions: { setIsLoad, setShakerData, setShakerFilter, setShakerDataStats, setError },
} = shakerSlice;

export const shakerGetAll = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('/admin/shaker')
    .then((res) => dispatch(setShakerData(res.data)))
    .catch(() => dispatch(setIsLoad(false)));
};

export const shakerStatsGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('admin/shaker/stats', { params })
    .then((res) => dispatch(setShakerDataStats(res.data)))
    .catch(() => dispatch(setIsLoad(false)));
};

export const shakerErrorsGetAll = (gatewayId, dateFrom, dateTo) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .post('admin/shaker/errors', { gatewayId, dateFrom, dateTo })
    .then((res) => {
      const file = new Blob([res.data], { type: 'text/csv;charset=utf-8' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'statistics.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      dispatch(setIsLoad(false));
      URL.revokeObjectURL(fileURL);
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export const shakerFreezeUpdateStatus = (gatewayId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/shaker/freeze`, { gatewayId })
    .then(() => {
      dispatch(shakerGetAll());
      dispatch(setIsLoad(false));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.error));
    });
};

export const shakerUnfreezeUpdateStatus = (gatewayId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/shaker/unfreeze`, { gatewayId })
    .then(() => {
      dispatch(shakerGetAll());
      dispatch(setIsLoad(false));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.error));
    });
};

export { shaker, setShakerFilter };
