import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { getGatewayById } from 'store/slices/gateway';

const initialState = {
  list: [],
  errors: {},
  curStrikes: {},
  redirect: false,
  isLoad: false,
};

const strikesSlice = createSlice({
  name: 'strikes',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setStrikesCreateSuccess(state) {
      state.isLoad = false;
      state.redirect = true;
    },
    setError(state, action) {
      state.errors = action.payload;
      state.isLoad = false;
    },
    setStrikesGetOneSuccess(state, action) {
      state.curStrikes = action.payload;
      state.isLoad = false;
    },
    setStrikesUpdateSuccess(state) {
      state.isLoad = false;
      state.redirect = true;
    },
    setStrikesCleanRedirect(state) {
      state.redirect = false;
    },
  },
});

const {
  reducer: strikes,
  actions: {
    setIsLoad,
    setError,
    setStrikesUpdateSuccess,
    setStrikesCleanRedirect,
    setStrikesCreateSuccess,
    setStrikesGetOneSuccess,
  },
} = strikesSlice;

export const strikesCreate = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post('/admin/strikes', payload)
    .then(() => {
      dispatch(setStrikesCreateSuccess());
    })
    .catch((err) => {
      dispatch(setError(err.response.data.error.messages));
    });
};

export const strikesDelete = (id, gatewayId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .delete(`/admin/strikes/${id}`)
    .then(() => {
      dispatch(getGatewayById(+gatewayId));
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const strikesGetOne = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get(`/admin/strikes/${id}`)
    .then((res) => {
      dispatch(setStrikesGetOneSuccess(res.data));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
      dispatch(setError({ strikes: 'не найден' }));
    });
};

export const strikesUpdate = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .put(`/admin/strikes/${id}`, payload)
    .then(() => {
      dispatch(setStrikesUpdateSuccess());
    })
    .catch((err) => {
      dispatch(setIsLoad(false));
      dispatch(setError(err));
    });
};

export { strikes, setStrikesCleanRedirect };
