import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: true,
  data: [],
  error: null,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const {
  reducer: statistics,
  actions: { setIsLoading, setData, setError },
} = statisticsSlice;

const startStatisticsGetAll = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/statistic?version=2')
    .then((resp) => dispatch(setData(resp.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке статистики')))
    .finally(() => dispatch(setIsLoading(false)));
};

export { statistics, startStatisticsGetAll };
