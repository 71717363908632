import React from 'react';
import { useAppSelector } from 'store';
import { LinkTabs } from 'assets/components/LinkTabs/LinkTabs';
import Header from 'assets/components/Header';
import Loader from 'assets/components/Loader';
import ShakerSettings from './ShakerSettings/ShakerSettings';
import ShakerStats from './ShakerStats/ShakerStats';
import { useParams } from 'react-router-dom';

const list = [
  { name: 'Настройки', link: '/shaker/settings', value: 'settings' },
  { name: 'Статистика', link: '/shaker/statistics', value: 'statistics' },
];

const ShakerPage = () => {
  const isLoad = useAppSelector((state) => state.shaker.isLoad);
  const { tabName } = useParams();
  return (
    <>
      {isLoad ? <Loader /> : null}
      <Header title='Шейкер' />
      <LinkTabs list={list} />
      {tabName === 'settings' && <ShakerSettings />}
      {tabName === 'statistics' && <ShakerStats />}
    </>
  );
};

export default ShakerPage;
