import React, { useEffect } from 'react';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from 'assets/components/Loader';

const titles = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'Обложка',
  },
  {
    name: 'Название файла',
  },
  {
    name: 'Очередность',
  },
  {
    name: 'Путь',
    mod: 'big',
  },
  {
    name: 'Продукты',
  },
  {
    name: '...',
    mod: 'm',
  },
];

const FilePage = ({ fileGetAll, isLoad, files, modalStatus, modalCleanData, fileDelete, infoPayload, role, availableEdit }) => {
  useEffect(() => {
    if (!files.length) fileGetAll();
  }, [files.length]);

  useEffect(() => {
    if (modalStatus === 'yes') {
      fileDelete(infoPayload.id);
      modalCleanData();
    }
  }, [modalStatus]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header
        title='Файлы продуктов'
        text={availableEdit ? 'Добавить файл' : null}
        href={availableEdit ? '/file/all/add' : null}
      />
      <Table titles={titles} content={files} keyProp={'fileExactProduct'} />
    </>
  );
};

export default FilePage;
