import React, { useEffect, useState } from 'react';
import { shakerStatsGetAll, setShakerFilter, shakerErrorsGetAll } from 'store/slices/shaker';
import Table from 'assets/components/Table';
import Filters from 'assets/components/Filters';
import { useAppSelector, useAppDispatch } from 'store';

const titleStatistic = [
  {
    name: 'домен',
    mod: 'stats',
  },
  {
    name: 'успешных платежей',
    mod: 'stats',
  },
  {
    name: 'неуспешных платежей',
    mod: 'stats',
  },
  {
    name: 'выгрузка ошибок',
  },
];

const ShakerStats = () => {
  const dispatch = useAppDispatch();
  const stats = useAppSelector((state) => state.shaker.stats);
  const appliedFiltersStats = useAppSelector((state) => state.shaker.filters);
  const dateFrom = useAppSelector((state) => state.shaker.filters.dateFrom);
  const dateTo = useAppSelector((state) => state.shaker.filters.dateTo);
  const [editItemStatistic, setEditItemStatistic] = useState(null);
  const [reloadPage, setReloadPage] = useState(true);

  const downloadStatistics = (item) => {
    setEditItemStatistic(item);
  };

  useEffect(() => {
    const gatewayId = editItemStatistic?.id;
    if (editItemStatistic != null) {
      dispatch(shakerErrorsGetAll(gatewayId, dateFrom, dateTo));
    }
  }, [editItemStatistic?.id]);

  const handleGetShakerStats = async () => {
    const params = {};

    for (let key in appliedFiltersStats) {
      if (appliedFiltersStats[key]) {
        params[`filter[${key}]`] = await appliedFiltersStats[key];
      }
    }

    dispatch(shakerStatsGetAll(params));
  };

  useEffect(() => {
    if (reloadPage) {
      handleGetShakerStats();
      setReloadPage(false);
    }
  }, [shakerStatsGetAll, appliedFiltersStats, reloadPage]);

  return (
    <>
      <Filters
        shakerStats={true}
        appliedFilters={{ ...appliedFiltersStats }}
        onFilterChange={(obj) => {
          dispatch(setShakerFilter(obj));
          setReloadPage(true);
        }}
      />
      <Table
        titles={titleStatistic}
        downloadStatistics={downloadStatistics}
        dateFrom={dateFrom}
        dateTo={dateTo}
        content={stats}
        keyProp='shaker-statistics'
      />
    </>
  );
};

export default ShakerStats;
