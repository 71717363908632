import React, { useEffect } from 'react';
import Table from 'assets/components/Table';
import Header from 'assets/components/Header/Header';
import Loader from 'assets/components/Loader';
import Filters from 'assets/components/Filters';
import { sberOutRatingGetAll, setSberOutFilter } from 'store/slices/sberout';
import { companyGetAll } from 'store/slices/company';
import { useAppDispatch, useAppSelector } from 'store';


const titleSberOut = [
  {
    name: ' ',
    mod: 'sm',
  },
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'Рейтинг',
  },
  {
    name: 'ПЛАТЕЖКА',
  },
  {
    name: 'НАЗВАНИЕ',
  },
  {
    name: 'ЮрЛицо',
  },
  {
    name: 'ДАННЫЕ',
    mod: 'big'
  },
]

const typeLabels = [
  {
    label: 'Стандартный',
    value: '1'
  },
  {
    label: 'Сплит',
    value: '2',
  }
]

const SberOutPage = () => {
  const dispatch = useAppDispatch();
  const isLoad = useAppSelector((state) => state.sberout.isLoad);
  const list = useAppSelector((state) => state.sberout.list);
  const appliedFilters = useAppSelector((state) => state.sberout.filters);
  const companiesOption = useAppSelector((state) => state.company.list).map((item) => ({ value: item.id, label: item.name }));

  useEffect(() => {
    let params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key];
      }
    }
    if (Object.values(params).length) {
      dispatch(sberOutRatingGetAll(params));
    }
    dispatch(companyGetAll());
  }, [appliedFilters, dispatch]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header
        title='СберРоут'
      />
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => dispatch(setSberOutFilter(obj))}
        sberOut={true}
        companiesOption={companiesOption}
        typeLabels={typeLabels}
      />
      <Table
        titles={titleSberOut}
        content={list}
        keyProp='sberOut'
      />
    </>
  );
};

export default SberOutPage;
