import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { setRedirect } from './redirect';

const initialState = {
  list: [],
  errors: null,
  redirect: false,
  isLoad: false,
  selectedCompany: {},
  options: {},
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setCompanyData(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
    setCompanyCreateSuccess(state) {
      state.redirect = true;
      state.isLoad = false;
    },
    setErrors(state, action) {
      state.errors = action.payload;
      state.isLoad = false;
    },
    setCompanyUpdateSuccess(state) {
      state.isLoad = false;
      state.redirect = true;
      state.selectedCompany = {};
    },
    setCompanyGetOneSuccess(state, action) {
      state.selectedCompany = action.payload;
      state.isLoad = false;
    },
    setCompanyOptions(state, action) {
      state.options = action.payload;
      state.isLoad = false;
    },
    setClearSelectedCompany(state) {
      state.selectedCompany = {};
    }
  },
});

const {
  reducer: company,
  actions: {
    setIsLoad,
    setCompanyData,
    setCompanyGetOneSuccess,
    setCompanyUpdateSuccess,
    setCompanyCreateSuccess,
    setErrors,
    setCompanyOptions,
    setClearSelectedCompany,
  },
} = companySlice;

export const companyGetAll = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/companies')
    .then((res) => {
      dispatch(setCompanyData(res.data));
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export const companyCreate = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post('/admin/companies', payload)
    .then(() => {
      dispatch(setCompanyCreateSuccess());
      dispatch(setRedirect('/company'));
    })
    .catch((err) => {
      dispatch(setErrors(err.response?.data?.error?.messages || null));
    });
};

export const companyDelete = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .delete(`/admin/companies/${id}`)
    .then(() => {
      dispatch(companyGetAll());
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const companyUpdate = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .put(`/admin/companies/${id}`, payload)
    .then(() => {
      dispatch(setCompanyUpdateSuccess());
      dispatch(setRedirect('/company'));
    })
    .catch((err) => {
      dispatch(setErrors(err.response?.data?.error?.messages));
    });
};

export const companyGetOne = (id) => (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get(`/admin/companies/${id}`)
    .then((resp) => {
      dispatch(setCompanyGetOneSuccess(resp.data));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
      dispatch(setRedirect('/company'));
    });
};

export const companyGetOptions = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/companies/options')
    .then((res) => {
      dispatch(setCompanyOptions(res.data));
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export { company, setCompanyGetOneSuccess, setClearSelectedCompany };
