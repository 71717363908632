import React from 'react';
import { startAdminSetRoles } from 'store/slices/admin';
import Table from 'assets/components/Table';
import { useAppSelector, useAppDispatch } from 'store';

const titlesRoleTab = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'роль',
  },
  {
    name: 'доступ',
    mod: 'big',
  },
  {
    name: '...',
    mod: 'm',
  },
];

const Roles = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.admin.roles);

  React.useEffect(() => {
    dispatch(startAdminSetRoles());
  }, [dispatch]);

  return <Table titles={titlesRoleTab} content={roles} keyProp='admin-role' />;
};

export default Roles;
