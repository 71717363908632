import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoad: false,
  list: [],
  filters: {
    companyId: '',
    type: '',
  },
};

const sberoutSlice = createSlice({
  name: 'sberout',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setSberOutData(state, action) {
      state.list = action.payload.items;
      state.isLoad = false;
    },
    setSberOutFilter(state, action) {
      state.filters.companyId = action.payload.companyId;
      state.filters.type = action.payload.type;
    },
  },
});

const {
  reducer: sberout,
  actions: { setIsLoad, setSberOutData, setSberOutFilter },
} = sberoutSlice;

export const sberOutRatingGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('admin/gateways/rating', { params })
    .then((res) => dispatch(setSberOutData(res.data)))
    .catch(() => dispatch(setIsLoad(false)));
};

export { sberout, setSberOutFilter };
