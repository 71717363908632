import React from 'react';
import { startChargebacksStatisticsGetAll, setChargebacksFilterStatistic } from 'store/slices/chargebacks';
import Table from 'assets/components/Table';
import Filters from 'assets/components/Filters';
import { useAppDispatch, useAppSelector } from 'store';
import { productGetAll } from 'store/slices/product';
import { getPaymentOptions } from 'store/slices/gateway';

const titles = [
  {
    name: 'web id',
    mod: 'stats',
  },
  {
    name: 'Всего чарджбэков/% к кол-ву транзакций успешных',
    mod: 'charge',
  },
  {
    name: 'Арбитражные чарджбеки/ %',
    mod: 'charge2',
  },
  {
    name: 'Количество успешных транзакций',
  },
];

const Statistics = () => {
  const dispatch = useAppDispatch();
  const appliedFilters = useAppSelector((state) => state.chargebacks.filtersStatistic);
  const statisticsList = useAppSelector((state) => state.chargebacks.statisticsList);
  const productsListOption = useAppSelector((state) => state.product.list)
    .filter((item) => item.innerName !== '')
    .reverse()
    .map((option) => ({ label: option.innerName, value: option.id }));
  const payments = useAppSelector((state) => state.gateway.payments);
  const [paymentsOptions, setPaymentsOptions] = React.useState([]);

  React.useEffect(() => {
    payments && setPaymentsOptions(Object.keys(payments).map((item) => ({ label: item, value: item })));
  }, [payments]);

  React.useEffect(() => {
    dispatch(productGetAll());
    dispatch(getPaymentOptions());
  }, []);

  React.useEffect(() => {
    let params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key];
      }
    }

    dispatch(startChargebacksStatisticsGetAll(params));
  }, [startChargebacksStatisticsGetAll, appliedFilters]);

  const formatOptionLabel = ({ value, label }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {value}
      </div>
      <div>
        <strong>Название продукта: </strong>
        {label}
      </div>
    </div>
  );

  return (
    <>
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => dispatch(setChargebacksFilterStatistic(obj))}
        statisticChargeback={true}
        multiSelectOptions={productsListOption}
        formatOptionLabel={formatOptionLabel}
        paymentsOptions={paymentsOptions}
      />
      <Table content={statisticsList} titles={titles} keyProp='chargeBacks-statistics' />
    </>
  );
};

export default Statistics;
