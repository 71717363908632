import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './LinkTabs.module.scss';

export const LinkTabs = ({ list }) => {
  const { tabName } = useParams();
  return (
    <div className={styles.container}>
      {list.map((item, idx) => (
        <div className={item.value === tabName ? styles.linkActive : styles.link} key={idx}>
          <Link to={item.link}>{item.name}</Link>
        </div>
      ))}
    </div>
  );
};
