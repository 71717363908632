import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import Filters from 'assets/components/Filters';
import Table from 'assets/components/Table';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import PopupRefund from 'assets/components/PopupRefund';
import {
  orderRefundUpdateStatus,
  getRefundStatusLabels,
  setFilterOrderRefund,
  setPageOrderRefund,
  orderRefundGetAll,
} from 'store/slices/refund';
import { getAllGateways } from 'store/slices/gateway';

const titlesRefund = [
  {
    name: 'ID',
    mod: 'mm',
  },
  {
    name: 'заказы',
    mod: 'medium',
  },
  {
    name: 'email',
    mod: 'big',
  },
  {
    name: 'фио',
    mod: 'big',
  },
  {
    name: 'статус',
    mod: 'status',
  },
  {
    name: 'дата транзакции',
    mod: 'date',
  },
  {
    name: 'причина возврата',
    mod: 'reason',
  },
  {
    name: 'дата создания',
    mod: 'date',
  },
  {
    name: 'действия',
    mod: 'm',
  },
];

const OrderRefund = () => {
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [paymentSystem, setPaymentSystem] = useState([]);
  const [reloadPage, setReloadPage] = useState(true);
  const gateways = useAppSelector((state) => state.gateway.gateways);
  const ordersRefund = useAppSelector((state) => state.refund.list);
  const statusLabelsRefund = useAppSelector((state) => state.refund.statusLabels);
  const appliedFiltersRefund = useAppSelector((state) => state.refund.filters);
  const limitRefund = useAppSelector((state) => state.refund.pagination.limit);
  const pageRefund = useAppSelector((state) => state.refund.pagination.page);
  const totalRefund = useAppSelector((state) => state.refund.pagination.total);

  const handleEditButton = (item) => {
    setEditItem(item);
    setShowPopup(true);
  };

  const handleSaveChange = () => {
    dispatch(orderRefundUpdateStatus(Number(editItem.id)));
    setShowPopup(false);
  };

  useEffect(() => {
    dispatch(getAllGateways());
  }, []);

  useEffect(() => {
    gateways?.length > 1 &&
      setPaymentSystem(
        gateways
          .map((el) => ({
            label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
            value: el.id,
            paymentSystem: el.paymentSystem,
            name: el.name,
            id: el.id,
            companyName: el.companyName,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      );
  }, [gateways]);

  const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {id}
      </div>
      <div>
        <strong>Платёжная система: </strong>
        {paymentSystem}
      </div>
      <div>
        <strong>Платёжный шлюз: </strong>
        {name}
      </div>
      <div>
        <strong>ЮрЛицо: </strong>
        {companyName ? companyName : '—'}
      </div>
    </div>
  );

  useEffect(() => dispatch(getRefundStatusLabels()), [getRefundStatusLabels]);

  const handleOrederRefundGetAll = async () => {
    const params = {};
    for (let key in appliedFiltersRefund) {
      if (appliedFiltersRefund[key]) {
        params[`filter[${key}]`] = await appliedFiltersRefund[key];
      }
    }
    params.limit = limitRefund;
    params.page = pageRefund;
    dispatch(orderRefundGetAll(params));
  };

  useEffect(() => {
    if (reloadPage) {
      handleOrederRefundGetAll();
      setReloadPage(false);
    }
  }, [reloadPage]);

  const handleRefundPageChange = (current) => {
    dispatch(setPageOrderRefund(current));
    setReloadPage(true);
  };

  return (
    <>
      <Filters
        refund={true}
        appliedFilters={{ ...appliedFiltersRefund, limitRefund }}
        onFilterChange={(obj) => {
          dispatch(setFilterOrderRefund(obj));
          setReloadPage(true);
        }}
        statusLabels={statusLabelsRefund.map((label, index) => ({ label: label, value: index.toString() }))}
        orderLabels={[
          { label: 'Билл', value: '0' },
          { label: 'Ребилл', value: '2' },
        ]}
        formatOptionLabel={formatOptionLabel}
        gatewaysOption={paymentSystem}
      />
      <Table titles={titlesRefund} content={ordersRefund} keyProp='order-refund' handleEditButton={handleEditButton} />
      <Pagination
        className='order__pagination'
        current={pageRefund}
        total={totalRefund}
        pageSize={limitRefund}
        onChange={handleRefundPageChange}
        showTitle={false}
      />
      {showPopup && (
        <PopupRefund
          message='Вы уверены что хотите закрыть заявку ?'
          onConfirm={handleSaveChange}
          onCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default OrderRefund;
