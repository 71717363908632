import React from 'react';
import Select from 'react-select';
import Input from 'assets/components/Input';
import styles from './EditBanksIssuerModal.scss';

const selectOptions = [
  { label: 'Для всех заказов с этим БИНом', value: true },
  { label: 'Где не указан Issuer', value: false },
];

const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '41.08px',
      height: '41.08px',
      boxShadow: state.isFocused ? null : null,
    }),
  
    valueContainer: (provided, state) => ({
      ...provided,
      height: '41.08px',
    }),
  };

const EditBanksIssuerModal = ({
  handleClickUpdate,
  handleClickCloseModal,
  selectCardfirstsix,
  setSelectCardfirstsix,
}) => {
  return (
    <div className='editBanksIssuerModal'>
      <div className='editBanksIssuerModal__container'>
        <div className='editBanksIssuerModal__header'>
          <div>Редактирование</div>
          <div className='editBanksIssuerModal__header__close' onClick={handleClickCloseModal}>
            X
          </div>
        </div>
        <div className='editBanksIssuerModal__select-wrapper'>
          <Input
            value={selectCardfirstsix.issuer}
            onChange={(e) => {
              setSelectCardfirstsix({ ...selectCardfirstsix, issuer: e.target.value });
            }}
            placeholder='Название банка'
            type='text'
            name='issuer'
            inputCls='create__input'
            label='Название банка'
          />
          <div className='editBanksIssuerModal__select-container'>
            <label className='input__label'>Установить значение в заказах</label>
            <Select
              options={selectOptions}
              value={selectOptions[selectOptions.findIndex((el) => el.value === selectCardfirstsix.fillEmptyValues)]}
              onChange={(option) => {
                setSelectCardfirstsix({ ...selectCardfirstsix, fillEmptyValues: option.value });
              }}
              placeholder='Применить'
              styles={selectStyles}
            />
          </div>
        </div>
        <div className='editBanksIssuerModal__buttons'>
          <button type='button' onClick={handleClickCloseModal} className='editBanksIssuerModal__buttons__cancel'>
            Отмена
          </button>
          <button disabled={!selectCardfirstsix.issuer} type='button' onClick={handleClickUpdate} className='editBanksIssuerModal__buttons__add'>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export { EditBanksIssuerModal };
