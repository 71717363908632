import AuthPage from './AuthPage';
import { authLogin, authCleanErrors, authAutintification } from 'actions/auth';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  error: state.auth.errors[0]?.error || '',
  isLoad: state.auth.isLoad,
});

const mapDispatchToProps = {
  authLogin,
  authCleanErrors,
  authAutintification
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
