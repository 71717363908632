import React from 'react';
import cn from 'classnames';
import styles from './PopupRefund.scss';

const PopupRefund = ({
    message,
    onConfirm,
    onCancel,
    oneButton
}) => {
    return (
        <div className={cn('popup')}>
            <div className={cn('container-modal')}>
                <div className={cn('text')}>
                    <h2>{message}</h2>
                </div>
                {!oneButton ? <div className={cn('buttons')}>
                    <button className={cn('buttons__item')} onClick={onCancel}>Нет</button>
                    <button className={cn('buttons__item')} onClick={onConfirm}>Да</button>
                </div> : <button className={cn('buttons__one')} onClick={onConfirm}>Понятно</button>}
            </div>
        </div>
    )
};

export { PopupRefund };