import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { format } from 'date-fns';

const initialState = {
  isLoading: false,
  statisticsList: [],
  webMastersList: [],
  error: null,
  webmasterStatus: '',
  filtersStatistic: {
    dateFrom: format(new Date(), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
    webmaster: '',
    equirer: '',
    productId: '',
  },
  filtersWebMaster: {
    dateFrom: format(new Date(), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
    utm_id: '',
    webmaster: '',
    email: '',
    type: '',
    landing: '',
    equirer: '',
    productId: '',
  },
  paginationWebMaster: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
};

const statisticsSlice = createSlice({
  name: 'chargebacks',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setStatistics(state, action) {
      state.statisticsList = action.payload;
    },
    setWebMasters(state, action) {
      state.webMastersList = action.payload.items;
      state.paginationWebMaster.page = action.payload.pagination?.page;
      state.paginationWebMaster.pages = action.payload.pagination?.pages;
      state.paginationWebMaster.total = action.payload.pagination?.total;
    },
    setChargebacksFilterStatistic(state, action) {
      state.filtersStatistic.dateFrom = action.payload.dateFrom;
      state.filtersStatistic.dateTo = action.payload.dateTo;
      state.filtersStatistic.webmaster = action.payload.webmaster;
      state.filtersStatistic.equirer = action.payload.equirer;
      state.filtersStatistic.productId = action.payload.productId;
    },
    setChargebacksFilterWebMaster(state, action) {
      state.filtersWebMaster.dateFrom = action.payload.dateFrom;
      state.filtersWebMaster.dateTo = action.payload.dateTo;
      state.filtersWebMaster.webmaster = action.payload.webmaster;
      state.filtersWebMaster.utm_id = action.payload.utm_id;
      state.filtersWebMaster.email = action.payload.email;
      state.filtersWebMaster.type = action.payload.type;
      state.filtersWebMaster.landing = action.payload.landing;
      state.filtersWebMaster.equirer = action.payload.equirer;
      state.filtersWebMaster.productId = action.payload.productId;
      state.paginationWebMaster.limit = action.payload.limit;
      state.paginationWebMaster.page = 1;
    },
    setWebMasterPage(state, action) {
      state.paginationStatistic.page = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setWebMasterStatus(state, action) {
      state.webmasterStatus = action.payload;
    }
  },
});

const {
  reducer: chargebacks,
  actions: {
    setWebMasterPage,
    setIsLoading,
    setChargebacksFilterWebMaster,
    setChargebacksFilterStatistic,
    setStatistics,
    setWebMasters,
    setError,
    setWebMasterStatus,
  },
} = statisticsSlice;

const startChargebacksStatisticsGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/payments/chargebacks/statistic', { params })
    .then((resp) => dispatch(setStatistics(resp.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке статистики')))
    .finally(() => dispatch(setIsLoading(false)));
};

const startWebMasterStatisticsGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/payments/chargebacks/webmaster', { params })
    .then((resp) => dispatch(setWebMasters(resp.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке статистики')))
    .finally(() => dispatch(setIsLoading(false)));
};

export const deleteWebmasterCheck = (id, type) => async (dispatch) => {
  dispatch(setIsLoading(true));
  request
    .post(`/admin/payments/chargeback`, { id, type })
    .then(() => {
      dispatch(setWebMasterStatus('success'));
    })
    .catch((err) => {
      // dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка'));
      dispatch(setWebMasterStatus('error'));
    })
    .finally(() => dispatch(setIsLoading(false)));
};

export {
  chargebacks,
  setWebMasterPage,
  setChargebacksFilterStatistic,
  setChargebacksFilterWebMaster,
  startChargebacksStatisticsGetAll,
  startWebMasterStatisticsGetAll,
  setError,
  setWebMasterStatus,
};
