import React, { useState } from 'react';
import { ReactComponent as Drop } from 'assets/img/drop.svg';
import cn from 'classnames';

const DropDown = ({
  className,
  text = 'Cписок',
  arrayOfValues = [],
  onChange = () => {},
  radio = false,
  error = '',
  cleanError = () => {},
}) => {
  // debugger;

  const [stateArrow, setStateArrow] = useState();

  const changeFunc = (mas, ind) => {
    const newMas = [...mas];
    newMas[ind].check = !newMas[ind].check;
    onChange(newMas);
  };

  const changeFuncRadio = (mas, ind) => {
    const newMas = [...mas].map((el) => ({ ...el, check: false }));
    newMas[ind].check = true;
    onChange(newMas);
    if (error) cleanError();
  };

  return (
    <div className={cn('dropDown', className)}>
      <div
        className={cn('dropDown__overlay', stateArrow ? 'down' : '')}
        onClick={() => {
          setStateArrow(!stateArrow);
        }}
      />

      <div
        className={cn('dropDown__item', stateArrow ? 'down' : '')}
        onClick={() => {
          setStateArrow(!stateArrow);
        }}
      >
        {/* {radio
          ? arrayOfValues.find(({ check }) => check)
            ? arrayOfValues.find(({ check }) => check).innerName
            : text
          : text} */}
          {radio ? arrayOfValues.find(({ check }) => check) && arrayOfValues.find(({ check }) => check).innerName ? arrayOfValues.find(({ check }) => check).innerName : arrayOfValues.find(({ check }) => check) && arrayOfValues.find(({ check }) => check).name ? arrayOfValues.find(({ check }) => check).name  : text : text}
        <Drop />
      </div>

      {arrayOfValues.length > 0 && (
        <div className={cn('dropDown__list', stateArrow ? 'down' : '')}>
          {radio
            ? arrayOfValues.map(({ id, innerName, name, price, check }, ind) => (
                <div key={ind} className='dropDown__list-item'>
                  <label className='dropDown__label'>
                    <input
                      onChange={() => {
                        changeFuncRadio(arrayOfValues, ind);
                        setStateArrow(!stateArrow);
                      }}
                      type='radio'
                      checked={check}
                      className='dropDown__checkbox'
                    />
                    {id ? `${id} | ` : null} {innerName ? innerName : name} {price ? ` | ${price} руб.` : null}
                  </label>
                </div>
              ))
            : arrayOfValues.map(({ id, innerName, name, price, check }, ind) => (
                <div key={ind} className='dropDown__list-item'>
                  <label className='dropDown__label'>
                    <input
                      onChange={() => {
                        changeFunc(arrayOfValues, ind);
                        cleanError();
                      }}
                      type='checkbox'
                      checked={check}
                      className='dropDown__checkbox'
                    />
                    {id ? `${id} | ` : null} {innerName ? innerName : name} {price ? ` | ${price} руб.` : null}
                  </label>
                </div>
              ))}
        </div>
      )}
      <div className={cn('error', 'dropDown__error')} title='Error'>
        {error}
      </div>
    </div>
  );
};

export default DropDown;
