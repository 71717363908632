import React, { useEffect, useState } from 'react';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from 'assets/components/Loader';
import Filters from 'assets/components/Filters';
import { companyGetAll } from 'store/slices/company';
import { useAppSelector, useAppDispatch } from 'store';
import { analyticsMidsGetAll, setAnalyticsMidsFilter } from 'store/slices/analyticsmids';
import { domainGetPaymentSystems } from 'store/slices/domain';

const titlesMids = [
  {
    name: 'Processing',
  },
  {
    name: 'Gateway',
    mod: 'hm',
  },
  {
    name: 'bank',
  },
  {
    name: 'Date',
    mod: 'hm',
  },
  {
    name: 'Currency',
    mod: 'm',
  },
  {
    name: 'Product',
    mod: 'bigw',
  },
  {
    name: 'CardType',
    mod: 'm',
  },
  {
    name: 'ALL',
    mod: 'rigthmr',
  },
  {
    name: 'Success %',
    mod: 'rigthmm',
  },
  {
    name: 'Fail %',
    mod: 'rigthm',
  },
  {
    name: 'Shake',
    mod: 'rigthm',
  },
  {
    name: 'Refunds %',
    mod: 'rigthm',
  },
  {
    name: 'Chargeback %',
    mod: 'chargeback',
  },
  {
    name: 'MAILS',
    mod: 'rigthm',
  },
  {
    name: 'STRIKES',
    mod: 'rigthm',
  },
];

const AnalyticsMidsPage = () => {
  const dispatch = useAppDispatch();
  const companies = useAppSelector((state) => state.company.list);
  const isLoad = useAppSelector((state) => state.analyticsmids.isLoad);
  const analyticsMids = useAppSelector((state) => state.analyticsmids.list);
  const appliedFiltersMids = useAppSelector((state) => state.analyticsmids.filters);
  const paymentSystem = useAppSelector((state) => state.domain.paymentSystems).map((paymentSystem, idx) => {
    return { value: paymentSystem, label: paymentSystem };
  });
  const [analyticsMidsNew, setAnalyticsMidsNew] = useState([]);
  const [reloadPage, setReloadPage] = useState(true);

  useEffect(() => {
    if (analyticsMids.length > 0) {
      setAnalyticsMidsNew(
        analyticsMids.map((item) => ({
          ...item,
          totalAll: Object.values(item.stats)
            .map((item) => item.total_purchases)
            .reduce((sum, current) => sum + current),
          totalSuccess: Object.values(item.stats)
            .map((item) => item.success_purchases)
            .reduce((sum, current) => sum + current),
          totalRefund: Object.values(item.stats)
            .map((item) => item.refunds)
            .reduce((sum, current) => sum + current),
          totalChargeBacks: Object.values(item.stats)
            .map((item) => item.chargebacks)
            .reduce((sum, current) => sum + current),
          totalShakes: Object.values(item.stats)
            .map((item) => item.shake)
            .reduce((sum, current) => sum + current),
          totalFail: Object.values(item.stats)
            .map((item) => item.fail_purchases)
            .reduce((sum, current) => sum + current),
        })),
      );
    } else {
      setAnalyticsMidsNew([]);
    }
  }, [analyticsMids]);

  useEffect(() => {
    dispatch(companyGetAll());
    dispatch(domainGetPaymentSystems());
  }, [dispatch]);

  const handleGetAnalyticsMids = async () => {
    const params = {};

    for (let key in appliedFiltersMids) {
      if (appliedFiltersMids[key]) {
        params[`filter[${key}]`] = await appliedFiltersMids[key];
      }
    }

    dispatch(analyticsMidsGetAll(params));
  };

  useEffect(() => {
    if (reloadPage) {
      handleGetAnalyticsMids();
      setReloadPage(false);
    }
  }, [analyticsMidsGetAll, appliedFiltersMids, reloadPage]);

  const currency = [
    { value: 'RUB', label: 'RUB' },
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
  ];

  const companiesOption = companies.map((item) => ({ value: item.id, label: item.name }));

  window.onscroll = function () {
    windowScroll();
  };

  let header = document.getElementById('myHeader');
  let headerPlaceholder = document.getElementsByClassName('table__inner')[0];
  let sticky = header?.offsetTop;

  function windowScroll() {
    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
      headerPlaceholder.style.marginTop = '50px';
      if (window.pageXOffset > 0) {
        header.style.left = -(parseInt(window.pageXOffset) - 245) + 'px';
      } else {
        header.style.left = '245px';
      }
    } else {
      header.classList.remove('sticky');
      headerPlaceholder.style.marginTop = '0px';
    }
  }

  return (
    <>
      {isLoad && <Loader />}
      <Header title="Аналитика по MID'ам" />
      <Filters
        analyticsMids={true}
        appliedFilters={{ ...appliedFiltersMids }}
        onFilterChange={(obj) => {
          dispatch(setAnalyticsMidsFilter(obj));
          setReloadPage(true);
        }}
        paymentSystemLabels={paymentSystem}
        currencyLabels={currency}
        companiesOption={companiesOption}
      />
      <Table titles={titlesMids} content={analyticsMids} keyProp='analytics-mids' />
      {analyticsMidsNew.length > 0 && (
        <Table titles={titlesMids} content={analyticsMidsNew} keyProp='analytics-mids-total' />
      )}
    </>
  );
};

export default AnalyticsMidsPage;
