import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: false,
  sberBill: [],
  error: null,
  sberBillStatus: '',
  dirtyPaymentGatewaysCount: '',
  totalPaymentGatewaysCount: '',
  whitePaymentGatewaysCount: '',
  filters: {
    companyId: '',
  },
};

const sberBillSlice = createSlice({
  name: 'sberBill',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSberBill(state, action) {
      state.sberBill = action.payload.items;
      state.whitePaymentGatewaysCount = action.payload.whitePaymentGatewaysCount;
      state.dirtyPaymentGatewaysCount = action.payload.dirtyPaymentGatewaysCount;
      state.totalPaymentGatewaysCount = action.payload.totalPaymentGatewaysCount;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSberBillFilter(state, action) {
      state.filters.companyId = action.payload.companyId;
    },
    setSberBillStatus(state, action) {
      state.sberBillStatus = action.payload;
    },
  },
});

const {
  reducer: sberBill,
  actions: { setIsLoading, setSberBillStatus, setSberBill, setError, setSberBillFilter },
} = sberBillSlice;

const getSberBill = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/sberbill', { params })
    .then((res) => dispatch(setSberBill(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке sberBill')))
    .finally(() => dispatch(setIsLoading(false)));
};

const sberBillPost = (payload) => async (dispatch) => {
  dispatch(setIsLoading(true));
  request
    .post(`/admin/sberbill`, payload)
    .then(() => {
      dispatch(setSberBillStatus('success'));
    })
    // .then(() => {
    //     dispatch(getSberBill());
    // })
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при добавлении')))
    .finally(() => dispatch(setIsLoading(false)));
};

const sberBillDelete = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  request
    .delete(`/admin/sberbill`, { data: { gatewayId: id } })
    .then(() => {
      dispatch(setSberBillStatus('success'));
    })
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при удалении sberBill')))
    .finally(() => dispatch(setIsLoading(false)));
};

export { sberBill, setSberBillStatus, getSberBill, sberBillPost, sberBillDelete, setSberBillFilter };
