import { createSlice } from '@reduxjs/toolkit';
import { modalCleanData } from './modal';
import { setRedirect } from 'store/slices/redirect';
import { request } from 'api';

const initialState = {
  list: [],
  errors: {},
  selectedAdmin: null,
  availableRoles: [],
  roles: [],
  selectedRole: null,
  isLoad: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setAdmins(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
    setError(state, action) {
      state.isLoad = false;
      state.errors = action.payload;
    },
    setSelectedAdmin(state, action) {
      state.selectedAdmin = action.payload;
    },
    setAvailableRoles(state, action) {
      state.availableRoles = action.payload;
    },
    setAdminRoles(state, action) {
      state.roles = action.payload;
      state.isLoad = false;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    },
    setClearSelectedRole(state) {
      state.selectedRole = null;
    },
    setClearSelectedAdmin(state) {
      state.selectedAdmin = null;
    },
    setClearError(state) {
      state.errors = {};
    }
  },
});

const {
  reducer: admin,
  actions: {
    setIsLoad,
    setAdmins,
    setClearSelectedAdmin,
    setSelectedRole,
    setError,
    setAdminRoles,
    setSelectedAdmin,
    setAvailableRoles,
    setClearError,
    setClearSelectedRole,
  },
} = adminSlice;

export const startSetAdmins = () => (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('/admin/admins')
    .then((res) => dispatch(setAdmins(res.data)))
    .catch((err) => dispatch(setError(err)));
};

export const startAdminSetSelected = (id) => (dispatch) => {
  return request
    .get(`/admin/admins/${id}`)
    .then((resp) => dispatch(setSelectedAdmin(resp.data)))
    .catch((_) => dispatch(setRedirect('/admin')));
};

export const startCreateAdmin = (params) => (dispatch) => {
  return request
    .post('/admin/admins', params)
    .then((_) => {
      dispatch(startSetAdmins());
      return dispatch(setRedirect('/admin'));
    })
    .catch((err) => {
      return dispatch(setError(err));
    });
};

export const startEditAdmin =
  ({ id, ...params }) =>
  (dispatch) => {
    return request
      .put(`/admin/admins/${id}`, params)
      .then(() => {
        dispatch(startSetAdmins());
        return dispatch(setRedirect('/admin'));
      })
      .catch((err) => dispatch(setError(err)));
  };

export const startDeleteAdmin = (payload) => (dispatch) => {
  return request
    .delete(`/admin/admins/${payload.id}`)
    .then(() => dispatch(startSetAdmins()))
    .catch((err) => dispatch(setError(err)))
    .finally(() => dispatch(modalCleanData()));
};

export const startAdminSetAvailableRoles = () => (dispatch) => {
  return request
    .get('/admin/roles/attributes')
    .then((resp) => dispatch(setAvailableRoles(resp.data)))
    .catch((err) => console.trace(err.response));
};

export const startAdminSetRoles = () => (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('/admin/roles')
    .then((resp) => dispatch(setAdminRoles(resp.data)))
    .catch((err) => {
      dispatch(setError(err));
      console.trace(err.response);
    });
};

export const startDeleteRole = (payload) => (dispatch) => {
  return request
    .delete(`/admin/roles/${payload.id}`)
    .then(() => dispatch(startAdminSetRoles()))
    .catch((err) => dispatch(setError(err)))
    .finally(() => dispatch(modalCleanData()));
};

export const startCreateRole = (params) => (dispatch) => {
  return request
    .post('/admin/roles', params)
    .then((_) => {
      dispatch(startAdminSetRoles());
      return dispatch(setRedirect('/admin'));
    })
    .catch((err) => {
      return dispatch(setError(err));
    });
};

export const startRoleSetSelected = (id) => (dispatch) => {
  return request
    .get(`/admin/roles/${id}`)
    .then((resp) => {
      dispatch(setSelectedRole(resp.data));
    })
    .catch((_) => dispatch(setRedirect('/admin')));
};

export const startEditRole = (id, body) => (dispatch) => {
  return request
    .put(`/admin/roles/${id}`, body)
    .then(() => {
      dispatch(startAdminSetRoles());
      return dispatch(setRedirect('/admin'));
    })
    .catch((err) => dispatch(setError(err)));
};

export { admin, setError, setClearSelectedAdmin, setClearError, setClearSelectedRole };
