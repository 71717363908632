import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as Plus } from 'assets/img/plus.svg';

const ButtonLink = ({ plus, btnClass, text = 'Текст кнопки', href = '/', onClick = () => {} }) => (
  <Link
    onClick={() => {
      onClick();
    }}
    to={href}
    className={cn('btn', btnClass)}
  >
    {!plus && <Plus />}
    <span className='btn__text'>{text}</span>
  </Link>
);

export default ButtonLink;
