import React, { useEffect, useState } from 'react';
import Table from 'assets/components/Table';
import PopupRefund from 'assets/components/PopupRefund';
import { useAppSelector, useAppDispatch } from 'store';
import { shakerGetAll, shakerFreezeUpdateStatus, shakerUnfreezeUpdateStatus } from 'store/slices/shaker';

const titleSettings = [
  {
    name: 'статус',
  },
  {
    name: 'шлюз',
    mod: 'stats',
  },
  {
    name: 'заказы',
  },
  {
    name: 'активные',
    mod: 'stats',
  },
  {
    name: 'замороженные',
    mod: 'stats',
  },
  {
    name: 'управление статусом',
  },
];

const ShakerSettings = () => {
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [editItem, setEditItem] = useState({});
  const shakers = useAppSelector((state) => state.shaker.list);

  const handleUpdateShakerButton = (item) => {
    setEditItem(item);
    setShowPopup(true);
  };

  const handleFreezeUpdateStatus = () => {
    dispatch(shakerFreezeUpdateStatus(Number(editItem.gateway_id)));
    setShowPopup(false);
  };

  const handleUnfreezeUpdateStatus = () => {
    dispatch(shakerUnfreezeUpdateStatus(Number(editItem.gateway_id)));
    setShowPopup(false);
  };

  useEffect(() => {
    dispatch(shakerGetAll());
  }, [shakerGetAll]);

  return (
    <>
      <Table
        handleUpdateShakerButton={handleUpdateShakerButton}
        titles={titleSettings}
        content={shakers}
        keyProp='shaker-settings'
      />
      {showPopup && (
        <PopupRefund
          message={`Вы уверены что хотите изменить статус домена ${editItem.gateway_name}`}
          onConfirm={editItem.status === 'Включен' ? handleFreezeUpdateStatus : handleUnfreezeUpdateStatus}
          onCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default ShakerSettings;
