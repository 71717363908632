import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userGetOne, setClearSelectedUser, userSaveChanges, setClearErrorsUser } from 'store/slices/user';

import Header from 'assets/components/Header';
import Input from 'assets/components/Input';

const UserEditPage = () => {
  /**
   * Hooks
   */
  const { id } = useParams();
  const dispatch = useDispatch();
  // state
  const { selectedUser, userEditErrors, isLoad } = useSelector((state) => state.user);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [fullAccessValue, setFullAccessValue] = useState(true);

  useEffect(() => {
    dispatch(userGetOne(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedUser?.email) {
      setEmailInputValue(selectedUser?.email);
    }
    setFullAccessValue(selectedUser?.fullAccess);
  }, [selectedUser?.email, selectedUser?.fullAccess]);

  // cleanup
  useEffect(
    () => () => {
      dispatch(setClearSelectedUser());
      dispatch(setClearErrorsUser());
    },
    [dispatch],
  );

  /**
   * Handlers
   */
  const handleSave = (e) => {
    e.preventDefault();
    dispatch(userSaveChanges(id, { email: emailInputValue, fullAccess: fullAccessValue }));
  };

  /**
   * View
   */
  return (
    <>
      <Header title='Редактирование профиля клиента' />
      <div className='user-edit-page'>
        <div className='user-edit-page__title-wrapper'>
          <p className='user-edit-page__entry'>ID клиента: {id}</p>
          <p className='user-edit-page__entry'>Имя: {selectedUser?.name}</p>
        </div>
        <form onSubmit={handleSave}>
          <Input
            type='email'
            label='E-mail'
            value={emailInputValue}
            onChange={(e) => setEmailInputValue(e.target.value)}
            error={userEditErrors.email}
          />
          <div className='user-edit-page__checkbox'>
            <label>Полный доступ к купленным продуктам</label>
            <input type='checkbox' checked={fullAccessValue} onChange={() => setFullAccessValue(!fullAccessValue)} />
          </div>
          <button className='button' disabled={isLoad}>
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
};

export { UserEditPage };
