import { request } from 'api';

export const FILE_GET_ALL_REQUEST = 'FILE_GET_ALL_REQUEST';
export const FILE_GET_ALL_SUCCESS = 'FILE_GET_ALL_SUCCESS';
export const FILE_GET_ALL_FAILURE = 'FILE_GET_ALL_FAILURE';

const fileGetAllRequest = () => ({ type: FILE_GET_ALL_REQUEST });
const fileGetAllSuccess = (payload) => ({ type: FILE_GET_ALL_SUCCESS, payload });
const fileGetAllFailure = () => ({ type: FILE_GET_ALL_FAILURE });

export const fileGetAll = () => async (dispatch) => {
  dispatch(fileGetAllRequest());
  request
    .get('/admin/files')
    .then((res) => {
      dispatch(fileGetAllSuccess(res.data));
    })
    .catch(() => dispatch(fileGetAllFailure()));
};

export const FILE_CREATE_REQUEST = 'FILE_CREATE_REQUEST';
export const FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS';
export const FILE_CREATE_FAILURE = 'FILE_CREATE_FAILURE';

const fileCreateRequest = () => ({ type: FILE_CREATE_REQUEST });
const fileCreateSuccess = () => ({ type: FILE_CREATE_SUCCESS });
const fileCreateFailure = (payload) => ({ type: FILE_CREATE_FAILURE, payload });

export const fileCreate = (payload) => async (dispatch) => {
  const { background, cover, ...fixPayload } = payload;
  fixPayload.number = isNaN(Number(fixPayload.number)) || !fixPayload.number ? 0 : +fixPayload.number;
  if (fixPayload.productId === undefined) delete fixPayload.productId;

  dispatch(fileCreateRequest());
  request
    .post('/admin/files', fixPayload)
    .then((res) => {
      if(background && cover){
        dispatch(fileImage(res.data.id, background, "background"));
        dispatch(fileImage(res.data.id, cover, "cover"));
        dispatch(fileGetAll());
        dispatch(fileCreateSuccess());
      } else if(background){
        dispatch(fileImage(res.data.id, background, "background"));
        dispatch(fileGetAll());
        dispatch(fileCreateSuccess());
      } else if(cover){
        dispatch(fileImage(res.data.id, cover, "cover"));
        dispatch(fileGetAll());
        dispatch(fileCreateSuccess());
      } else {
        dispatch(fileGetAll());
        dispatch(fileCreateSuccess());
      }
    })
    .catch((err) => {
      dispatch(fileCreateFailure(err.response.data.error.messages));
    });
};

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

const fileDeleteRequest = () => ({ type: FILE_DELETE_REQUEST });
const fileDeleteSuccess = () => ({ type: FILE_DELETE_SUCCESS });
const fileDeleteFailure = () => ({ type: FILE_DELETE_FAILURE });

export const fileDelete = (id) => async (dispatch) => {
  dispatch(fileDeleteRequest());
  request
    .delete(`/admin/files/${id}`)
    .then(() => {
      dispatch(fileGetAll());
      // else {
      //   dispatch(productGetOne(productId));
      // }
      dispatch(fileDeleteSuccess());
    })
    .catch(() => {
      dispatch(fileDeleteFailure());
    });
};

export const FILE_GET_ONE_REQUEST = 'FILE_GET_ONE_REQUEST';
export const FILE_GET_ONE_SUCCESS = 'FILE_GET_ONE_SUCCESS';
export const FILE_GET_ONE_FAILURE = 'FILE_GET_ONE_FAILURE';

const fileGetOneRequest = () => ({ type: FILE_GET_ONE_REQUEST });
const fileGetOneSuccess = (payload) => ({ type: FILE_GET_ONE_SUCCESS, payload });
const fileGetOneFailure = () => ({ type: FILE_GET_ONE_FAILURE });

export const fileGetOne =
  (id = 0) =>
  async (dispatch) => {
    const fixId = isNaN(id) ? 0 : id;
    dispatch(fileGetOneRequest());
    request
      .get(`/admin/files/${fixId}`)
      .then((res) => {
        dispatch(fileGetOneSuccess(res.data));
      })
      .catch(() => {
        dispatch(fileGetOneFailure());
      });
  };

export const FILE_UPDATE_REQUEST = 'FILE_UPDATE_REQUEST';
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS';
export const FILE_UPDATE_FAILURE = 'FILE_UPDATE_FAILURE';

const fileUpdateRequest = () => ({ type: FILE_UPDATE_REQUEST });
const fileUpdateSuccess = () => ({ type: FILE_UPDATE_SUCCESS });
const fileUpdateFailure = (payload) => ({ type: FILE_UPDATE_FAILURE, payload });

export const fileUpdate = (id, payload) => async (dispatch) => {
  const { background, cover, ...fixPayload } = payload
  dispatch(fileUpdateRequest());
  request
    .put(`/admin/files/${id}`, fixPayload)
    .then(() => {
      if(background && cover){
        dispatch(fileImage(id, background, "background"));
        dispatch(fileImage(id, cover, "cover"));
        dispatch(fileGetAll());
        dispatch(fileUpdateSuccess());
      } else if(background){
        dispatch(fileImage(id, background, "background"));
        dispatch(fileGetAll());
        dispatch(fileUpdateSuccess());
      } else if(cover){
        dispatch(fileImage(id, cover, "cover"));
        dispatch(fileGetAll());
        dispatch(fileUpdateSuccess());
      } else {
        dispatch(fileGetAll());
        dispatch(fileUpdateSuccess());
      }
    })
    .catch((err) => {
      dispatch(fileUpdateFailure(err.response.data.error.messages));
    });
};

export const FILE_CLEAN_ERROR_LOCALE = 'FILE_CLEAN_ERROR_LOCALE';

const fileCleanErrorsLocale = (payload) => ({ type: FILE_CLEAN_ERROR_LOCALE, payload });

export const fileCleanErrors = (payload) => (dispatch) => {
  dispatch(fileCleanErrorsLocale(payload));
};

export const FILE_CLEAN_REDIRECT_LOCALE = 'FILE_CLEAN_REDIRECT_LOCALE';

const fileCleanRedirectLocale = () => ({ type: FILE_CLEAN_REDIRECT_LOCALE });

export const fileCleanRedirect = () => (dispatch) => {
  dispatch(fileCleanRedirectLocale());
};

export const FILE_IMAGE_REQUEST = 'FILE_IMAGE_REQUEST';
export const FILE_IMAGE_SUCCESS = 'FILE_IMAGE_SUCCESS';
export const FILE_IMAGE_FAILURE = 'FILE_IMAGE_FAILURE';

const fileImageRequest = () => ({ type: FILE_IMAGE_REQUEST });
const fileImageSuccess = () => ({ type: FILE_IMAGE_SUCCESS });
const fileImageFailure = () => ({ type: FILE_IMAGE_FAILURE });
/**
 *
 * @api  /admin/files/{type}/{id}
 * @type product или file
 * @content-type multipart/form-data
 * @formKey cover or background
 * @id product or file id
 */
export const fileImage = (id, file, type) => async (dispatch) => {
  const formData = new FormData();
  formData.append(type, file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  dispatch(fileImageRequest());
  request
    .post(`/admin/files/file/${id}`, formData, config)
    .then(() => {
      dispatch(fileImageSuccess());
    })
    .catch((err) => {
      dispatch(fileImageFailure(err.response?.data?.error?.messages));
    });
};