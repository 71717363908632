import Table from './Table';
import { connect } from 'react-redux';
import { showModal } from 'store/slices/modal';

const mapStateToProps = (state) => {
  return {
    modalStatus: state.modal.modalStatus,
  };
};
const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
