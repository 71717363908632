import React from 'react';
import { ReactComponent as Close } from 'assets/img/close.svg';
import cn from 'classnames';

const Modal = ({ modalStatus, modalData, modalText, modalType }) => {
  // debugger;
  return (
    <>
      <div
        className={cn('modal__overlay', modalStatus)}
        onClick={() => {
          modalData('No');
        }}
      />
      <div className={cn('modal', modalStatus)}>
        <Close
          onClick={() => {
            modalData('No');
          }}
        />
        <div className='modal__text'>{modalText}</div>
        <div className='modal__btns'>
          <div className='modal__btn' onClick={modalType === "copy" ? () => modalData('copy') : modalType === "deleteFile" ? () => modalData('deleteFile') : modalType === "changeStatus" ? () => modalData('changeStatus') : () => modalData('yes')}>
            Да
          </div>
          <div className='modal__btn' onClick={() => modalData('No')}>
            Нет
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
