import React, { useEffect, useState, useCallback } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import { format } from 'date-fns'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { useAppSelector } from 'store'
import { landFooterOptions } from 'constants/dashboardOptions/dashboardOptions'
import FilterInput from '../FilterInput'
import SelectWrapper from '../SelectWrapper'
import Button from '../Button'

const parseAmount = (val1, val2) => {
	const result =
		val1 && val2 && val1 > val2 && val2 !== ''
			? `${val2}:${val1}`
			: val1 && val2
			? `${val1}:${val2}`
			: val1 === '' && val2
			? `0:${val2}`
			: val1 && val2 === ''
			? `${val1}`
			: ''
	return result
}

const selectStyles = {
	control: provided => ({
		...provided,
		minHeight: '40px'
	}),
	container: provided => ({
		...provided,
		minWidth: '200px',
		minHeight: '1px',
		textAlign: 'left',
		border: 'none'
	})
}

const selectStylesLong = {
	control: provided => ({
		...provided,
		minHeight: '40px'
	}),
	container: provided => ({
		...provided,
		minWidth: '350px',
		minHeight: '1px',
		textAlign: 'left',
		border: 'none'
	})
}
// const selectStyles = {
// 	container: provided => ({
// 		...provided,
// 		display: 'inline-block',
// 		width: '250px',
// 		minHeight: '1px',
// 		textAlign: 'left',
// 		border: 'none'
// 	})
// }

const gatewaysDomainOptions = [
	{ label: 'Есть нореф шлюз', value: 'isNoRefExist' },
	{ label: 'Есть реф шлюз', value: 'isRefExist' },
	{ label: 'Нет нореф шлюза', value: 'isNoRefNotExist' },
	{ label: 'Нет реф шлюза', value: 'isRefNotExist' }
]

const stopRebillOption = [
	{ label: 'Ребиллы включены', value: 0 },
	{ label: 'Все выключены', value: 1 },
	{ label: 'Все по Сберу выключены', value: 2 },
	{ label: 'Все по ТКС выключены', value: 10 },
	{ label: 'Все по Сберу и ТКС выключены', value: 11 },
	{ label: 'Выключены все кроме Сбера', value: 3 },
	{ label: 'Первые выключены', value: 4 },
	{ label: 'Первые по Сберу выключены', value: 5 },
	{ label: 'Первые кроме Сбера выключены', value: 6 },
	{ label: 'Повторные выключены', value: 7 },
	{ label: 'Повторные по Сберу выключены', value: 8 },
	{ label: 'Повторные кроме Сбера выключены', value: 9 }
]

const selectStyles2 = {
	control: (provided, state) => ({
		...provided,
		background: '#fff',
		borderColor: '#9e9e9e',
		minHeight: '30px',
		height: '30px',
		boxShadow: state.isFocused ? null : null
	}),

	valueContainer: (provided, state) => ({
		...provided,
		height: '30px',
		padding: '0 6px'
	}),

	input: (provided, state) => ({
		...provided,
		margin: '0px'
	}),
	indicatorSeparator: state => ({
		display: 'none'
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: '30px'
	})
}

const sberbillOrSberrouteOption = [
	{ value: '0', label: 'Выключен' },
	{ value: '1', label: 'Включен' }
]

const transactionStatusOption = [
	{ value: '0', label: 'Неуспешный' },
	{ value: '1', label: 'Успешный' }
]

const stepOption = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
	{ value: '6', label: '6' },
	{ value: '7', label: '7' },
	{ value: '8', label: '8' },
	{ value: '9', label: '9' },
	{ value: '10', label: '10' }
]

const dirtyOption = [
	{ value: '1', label: 'Грязный' },
	{ value: '0', label: 'Чистый' }
]

const Filters = ({
	sberBill,
	sberOut,
	limitPage,
	formatOptionLabel,
	gatewaysOption,
	companiesOption,
	countriesOption,
	payCountriesOption,
	appliedFilters,
	orderLabels,
	paymentLabels,
	onFilterChange,
	statusLabels,
	order,
	refund,
	domainPage,
	gatewayPage,
	productPage,
	analytics,
	paymentSystemLabels,
	currencyLabels,
	multiSelectOptions,
	handleOnChangeMultiSelect,
	valueSelect,
	valueSelectString,
	valueSelectErrors,
	valueSelectStringErrors,
	analyticsMids,
	shakerStats,
	bankListOption,
	folderListOption,
	paymentErrorListOption,
	typeLabels,
	paymentsOptions,
	statisticChargeback,
	webmasterChargeback,
	gatewayStatistics,
	topErrors,
	chainErrors,
	banks,
	statusLabelsOrder,
	regionIdOptions
}) => {
	const schemeOption = useAppSelector(state => state.gateway.rates)
	const [status, setStatus] = useState(appliedFilters?.status ?? null)
	const [type, setType] = useState(appliedFilters?.type ?? null)
	const [typeOrder, setTypeOrder] = useState(appliedFilters?.typeOrder ?? null)
	const [country, setCountry] = useState(
		appliedFilters?.countriesOption ?? null
	)
	const [payCountry, setPayCountry] = useState(
		appliedFilters?.payCountriesOption ?? null
	)
	const [typePayment, setTypePayment] = useState(
		appliedFilters?.typePayment ?? null
	)
	const [email, setEmail] = useState(appliedFilters?.email ?? '')
	const [companies, setCompanies] = useState(appliedFilters?.companies ?? '')
	const [fromDate, setFromDate] = useState(
		appliedFilters?.dateFrom ? new Date(appliedFilters.dateFrom) : null
	)
	const [toDate, setToDate] = useState(
		appliedFilters?.dateTo ? new Date(appliedFilters.dateTo) : null
	)
	const [fromDatePayment, setFromDatePayment] = useState(
		appliedFilters?.datePaymentFrom
			? new Date(appliedFilters.datePaymentFrom)
			: null
	)
	const [toDatePayment, setToDatePayment] = useState(
		appliedFilters?.datePaymentTo
			? new Date(appliedFilters.datePaymentTo)
			: null
	)
	const [domain, setDomain] = useState(appliedFilters?.domain ?? '')
	const [link, setLink] = useState(appliedFilters?.link ?? '')
	const [limit, setLimit] = useState(limitPage ?? '50')
	const [id, setId] = useState(appliedFilters?.id ?? '')
	const [orderIds, setOrderIds] = useState(appliedFilters?.orderIds ?? '')
	const [orderNumber, setOrderNumber] = useState(appliedFilters?.number ?? '')
	const [firstSix, setFirstSix] = useState(appliedFilters?.firstSix ?? '')
	const [lastFour, setLastFour] = useState(appliedFilters?.lastFour ?? '')
	const [webmaster, setWebmaster] = useState(appliedFilters?.webmaster ?? '')
	const [utmCampaign, setUtmCampaign] = useState(
		appliedFilters?.utmCampaign ?? ''
	)
	const [paymentSystem, setPaymentSystem] = useState(
		appliedFilters?.paymentSystem ?? ''
	)
	const [currency, setCurrency] = useState(appliedFilters?.paymentSystem ?? '')
	const [gateway, setGateway] = useState(appliedFilters?.gateway ?? '')
	const [products, setProducts] = useState(appliedFilters?.products ?? '')
	const [name, setName] = useState(appliedFilters?.name ?? '')
	const [reason, setReason] = useState(appliedFilters?.reason ?? '')
	const [externalId, setExternalId] = useState(appliedFilters?.externalId ?? '')
	const [companyId, setCompanyId] = useState(appliedFilters?.companyId ?? '')
	const [companyIds, setCompanyIds] = useState(appliedFilters?.companyIds ?? '')
	const [productId, setProductId] = useState(appliedFilters?.productId ?? '')
	const [paymentGatewayId, setPaymentGatewayId] = useState(
		appliedFilters?.paymentGatewayId ?? ''
	)
	const [equirer, setEquirer] = useState(appliedFilters?.equirer ?? '')
	const [landing, setLanding] = useState(appliedFilters?.landing ?? '')
	const [issuer, setIssuer] = useState(appliedFilters?.issuer ?? '')
	const [folder, setFolder] = useState(appliedFilters?.folder ?? '')
	const [sberbill, setSberbill] = useState(appliedFilters?.sberbill ?? '')
	const [sberroute, setSberroute] = useState(appliedFilters?.sberroute ?? '')
	const [transactionStatus, setTransactionStatus] = useState(
		appliedFilters?.transactionStatus ?? ''
	)
	const [userId, setUserId] = useState(appliedFilters?.userId ?? '')
	const [errors, setErrors] = useState(appliedFilters?.errors ?? '')
	const [step, setStep] = useState(appliedFilters?.step ?? '')
	const [stepNone, setStepNone] = useState(appliedFilters?.stepNone ?? '')
	const [dirty, setDirty] = useState(appliedFilters?.dirty ?? '')
	const [issuers, setIssuers] = useState(appliedFilters?.issuers ?? '')
	const [statuses, setStatuses] = useState(appliedFilters?.statuses ?? '')
	const [fromAmount, setFromAmount] = useState(
		appliedFilters?.amount?.split(':')[0] ?? ''
	)
	const [toAmount, setToAmount] = useState(
		appliedFilters?.amount?.split(':')[1] ?? ''
	)
	const [recurrentStoppedIds, setRecurrentStoppedIds] = useState(
		appliedFilters?.recurrentStoppedIds ?? ''
	)
	const [scheme, setScheme] = useState(appliedFilters?.scheme ?? '')
	const [clientId, setClientId] = useState(appliedFilters?.clientId ?? '')
	const [paymentGatewayIds, setPaymentGatewayIds] = useState(
		appliedFilters?.paymentGatewayIds ?? ''
	)
	const [landFooter, setLandFooter] = useState(
		appliedFilters?.landFooter ?? null
	)
	const [gateways, setGateways] = useState(appliedFilters?.gateways ?? '')
	const [statusesOrder, setStatusesOrder] = useState(
		appliedFilters?.statusesOrder ?? ''
	)
	const [regionId, setRegionId] = useState(appliedFilters?.regionId ?? '')
	const filterFrom = date =>
		toDate
			? date.getTime() <= Date.now() && date.getTime() <= toDate.getTime()
			: filterTo(date)
	const filterTo = date => date.getTime() <= Date.now()

	const filterFromPayment = date =>
		toDatePayment
			? date.getTime() <= Date.now() &&
			  date.getTime() <= toDatePayment.getTime()
			: filterToPayment(date)
	const filterToPayment = date => date.getTime() <= Date.now()

	const handleApplyFilters = () => {
		let formattedFromDate = fromDate ? format(fromDate, 'yyyy-MM-dd') : null
		let formattedToDate = toDate ? format(toDate, 'yyyy-MM-dd') : null
		let formattedFromDateTransaction = fromDatePayment
			? format(fromDatePayment, 'yyyy-MM-dd')
			: null
		let formattedToDateTransaction = toDatePayment
			? format(toDatePayment, 'yyyy-MM-dd')
			: null

		if (formattedFromDate > formattedToDate) {
			// switch from <-> to dates
			let temp = fromDate
			setFromDate(toDate)
			setToDate(temp)
			;[formattedFromDate, formattedToDate] = [
				formattedToDate,
				formattedFromDate
			]
		}

		if (formattedFromDateTransaction > formattedToDateTransaction) {
			// switch from <-> to dates
			let temp = fromDatePayment
			setFromDatePayment(toDatePayment)
			setToDatePayment(temp)
			;[formattedFromDateTransaction, formattedToDateTransaction] = [
				formattedToDateTransaction,
				formattedFromDateTransaction
			]
		}

		if (fromAmount > toAmount && toAmount !== '') {
			let temp = fromAmount
			setFromAmount(toAmount)
			setToAmount(temp)
		}
		onFilterChange({
			status,
			type,
			typeOrder,
			typePayment,
			email,
			dateFrom: formattedFromDate,
			dateTo: formattedToDate,
			datePaymentFrom: formattedFromDateTransaction,
			datePaymentTo: formattedToDateTransaction,
			domain,
			limit,
			id,
			orderIds,
			number: orderNumber,
			firstSix,
			lastFour,
			webmaster,
			utmCampaign,
			country,
			payCountry,
			paymentSystem,
			currency,
			gateway,
			link,
			products,
			name,
			reason,
			externalId,
			companyId,
			paymentGatewayId,
			productId,
			equirer,
			landing,
			issuer,
			folder,
			sberbill,
			sberroute,
			transactionStatus,
			errors,
			companies,
			step,
			stepNone,
			dirty,
			issuers,
			statuses,
			companyIds,
			amount: parseAmount(fromAmount, toAmount),
			recurrentStoppedIds,
			scheme,
			clientId,
			paymentGatewayIds,
			landFooter,
			gateways,
			userId,
			statusesOrder,
			regionId
		})
	}

	const handlePressEnter = event => {
		if (event.key === 'Enter') {
			event.preventDefault()
			handleApplyFilters()
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', handlePressEnter)
		return () => {
			document.removeEventListener('keydown', handlePressEnter)
		}
	})

	// status selector optimization rendering
	const onChangeState = useCallback(e => {
		e?.value ? setStatus(e.value) : setStatus(e)
	}, [])

	// type selector optimization rendering
	const onChangeType = useCallback(e => {
		e?.value ? setType(e.value) : setType(e)
	}, [])

	// typeOrder selector optimization rendering
	const onChangeTypeOrder = useCallback(e => {
		e?.value ? setTypeOrder(e.value) : setTypeOrder(e)
	}, [])

	// countries selector optimization rendering
	const onChangeCountries = useCallback(e => {
		e?.value ? setCountry(e.value) : setCountry(e)
	}, [])

	// PayCountries selector optimization rendering
	const onChangePayCountries = useCallback(e => {
		e?.value ? setPayCountry(e.value) : setPayCountry(e)
	}, [])

	// typePayment selector optimization rendering
	const onChangeTypePayment = useCallback(e => {
		e?.value ? setTypePayment(e.value) : setTypePayment(e)
	}, [])

	// type selector optimization rendering
	const onChangePaymentGatewayId = useCallback(e => {
		e?.value ? setPaymentGatewayId(e.value) : setPaymentGatewayId(null)
	}, [])

	// email input optimization rendering
	const onChangeEmail = useCallback(e => {
		setEmail(e.target.value)
	}, [])

	// fromDate optimization rendering
	const onChangeFromDate = useCallback(e => {
		setFromDate(e)
	}, [])

	// toDate optimization rendering
	const onChangeToDate = useCallback(e => {
		setToDate(e)
	}, [])

	// fromDate optimization rendering
	const onChangeFromDatePayment = useCallback(e => {
		setFromDatePayment(e)
	}, [])

	// toDate optimization rendering
	const onChangeToDatePayment = useCallback(e => {
		setToDatePayment(e)
	}, [])

	// domain optimization rendering
	const onChangeDomain = useCallback(e => {
		setDomain(e.target.value)
	}, [])

	// link optimization rendering
	const onChangeLink = useCallback(e => {
		setLink(e.target.value)
	}, [])

	// limit optimization rendering
	const onChangeLimit = useCallback(e => {
		setLimit(e.target.value)
	}, [])

	// id optimization rendering
	const onChangeId = useCallback(e => {
		setId(e.target.value)
	}, [])

	// order ids optimization rendering
	const onChangeOrderIds = useCallback(e => {
		setOrderIds(e.target.value)
	}, [])

	// orderNumber optimization rendering
	const onChangeOrderNumber = useCallback(e => {
		setOrderNumber(e.target.value)
	}, [])

	const onChangeClientId = useCallback(e => {
		setClientId(e.target.value)
	}, [])

	// orderNumber optimization rendering
	const onChangeFirstSix = useCallback(e => {
		setFirstSix(e.target.value)
	}, [])

	// orderNumber optimization rendering
	const onChangeLastFour = useCallback(e => {
		setLastFour(e.target.value)
	}, [])

	// orderNumber optimization rendering
	const onChangeWebmaster = useCallback(e => {
		setWebmaster(e.target.value)
	}, [])

	const onChangeUtmCampaign = useCallback(e => {
		setUtmCampaign(e.target.value)
	}, [])

	// paymentSystem optimization rendering
	const onChangePaymentSystem = useCallback(e => {
		e?.value ? setPaymentSystem(e.value) : setPaymentSystem(e)
	}, [])

	// currency optimization rendering
	const onChangeCurrency = useCallback(e => {
		setCurrency(e)
	}, [])

	const onChangeGateway = useCallback(e => {
		setGateway(e.target.value)
	}, [])

	const onChangeName = useCallback(e => {
		setName(e.target.value)
	}, [])

	const onChangeReason = useCallback(e => {
		setReason(e.target.value)
	}, [])

	const onChangeUserId = useCallback(e => {
		setUserId(e.target.value)
	}, [])

	const onChangeExternalId = useCallback(e => {
		setExternalId(e.target.value)
	}, [])

	const onChangeCompanyId = useCallback(e => {
		e?.value ? setCompanyId(e.value) : setCompanyId(e)
	}, [])

	const onChangeProductId = useCallback(e => {
		e?.value ? setProductId(e.value) : setProductId(null)
	}, [])

	const onChangeEquirer = useCallback(e => {
		e?.value ? setEquirer(e.value) : setEquirer(null)
	}, [])

	const onChangeLanding = useCallback(e => {
		setLanding(e.target.value)
	}, [])

	const onChangeTypeCharge = useCallback(e => {
		e?.value ? setType(e.value) : setType(null)
	}, [])

	const onChangeIssuer = useCallback(e => {
		setIssuer(e.target.value)
	}, [])

	const onChangeFolder = useCallback(e => {
		e?.value ? setFolder(e.value) : setFolder(e)
	}, [])

	const onChangeSberbill = useCallback(e => {
		e?.value ? setSberbill(e.value) : setSberbill(e)
	}, [])

	const onChangeRegionId = useCallback(e => {
		e?.value ? setRegionId(e.value) : setRegionId(e)
	}, [])

	const onChangeLandFooter = useCallback(e => {
		e?.value || e?.value === '' ? setLandFooter(e.value) : setLandFooter(e)
	}, [])

	const onChangeSberroute = useCallback(e => {
		e?.value ? setSberroute(e.value) : setSberroute(e)
	}, [])

	const onChangeDirty = useCallback(e => {
		e?.value ? setDirty(e.value) : setDirty(e)
	}, [])

	const onChangeTransactionStatus = useCallback(e => {
		e?.value ? setTransactionStatus(e.value) : setTransactionStatus(e)
	})

	const onChangeStep = useCallback(e => {
		e?.value ? setStep(e.value) : setStep(e)
	}, [])

	const onChangeStepNone = useCallback(e => {
		e?.value ? setStepNone(e.value) : setStepNone(e)
	}, [])

	const onChangeScheme = useCallback(e => {
		e?.value ? setScheme(e.value) : setScheme(e)
	}, [])

	useEffect(() => {
		setProducts(valueSelectString)
	}, [valueSelectString])

	useEffect(() => {
		setErrors(valueSelectStringErrors)
	}, [valueSelectStringErrors])

	return order ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							isMulti={true}
							onChange={o => setStatuses(o.map(item => item.value).toString())}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder='Email'
						type='text'
						label='Email'
						value={email}
						onChange={onChangeEmail}
					/>
					<FilterInput
						placeholder='Домен'
						type='text'
						label='Домен'
						value={domain}
						onChange={onChangeDomain}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
				</div>
				{/* Row 2 */}
				<div className='rowFlex'>
					<SelectWrapper label='Тип: Билл/Реневал'>
						<Select
							onChange={onChangeTypeOrder}
							options={orderLabels}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === typeOrder)}
							placeholder='Тип: Билл/Реневал'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата заказа от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата заказа до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Тип: Ребилла'>
						<Select
							onChange={onChangeTypePayment}
							options={paymentLabels}
							styles={selectStyles}
							value={paymentLabels.find(o => o.value === typePayment)}
							placeholder='Тип: Билл/Реневал'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата платежа от'>
						<DatePicker
							selected={fromDatePayment}
							onChange={onChangeFromDatePayment}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFromPayment}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата платежа до'>
						<DatePicker
							selected={toDatePayment}
							onChange={onChangeToDatePayment}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterToPayment}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Страна по БК'>
						<Select
							onChange={onChangeCountries}
							options={countriesOption}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === country)}
							placeholder='Страна по БК'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Страна по IP'>
						<Select
							onChange={onChangePayCountries}
							options={payCountriesOption}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === payCountry)}
							placeholder='Страна по IP'
							isClearable
						/>
					</SelectWrapper>
				</div>
				{/* Row 2 */}
				<div className='rowFlex'>
					<FilterInput
						placeholder='ID'
						type='text'
						label='ID'
						value={id}
						onChange={onChangeId}
					/>
					<FilterInput
						placeholder='0'
						type='text'
						label='Номер'
						value={orderNumber}
						onChange={onChangeOrderNumber}
					/>
					<FilterInput
						placeholder='000 000'
						type='text'
						label='Первые 6 цифр карты'
						value={firstSix}
						onChange={onChangeFirstSix}
					/>
					<FilterInput
						placeholder='0000'
						type='text'
						label='Последние 4 цифр карты'
						value={lastFour}
						onChange={onChangeLastFour}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='ID Вебмастера'
						value={webmaster}
						onChange={onChangeWebmaster}
					/>
					<FilterInput
						placeholder='1234567'
						type='text'
						label='utm_campaign'
						value={utmCampaign}
						onChange={onChangeUtmCampaign}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Внешний ID платежа'
						value={externalId}
						onChange={onChangeExternalId}
					/>
					<SelectWrapper label='Шлюзы'>
						<Select
							isMulti={true}
							onChange={o =>
								setPaymentGatewayIds(o.map(item => item.value).toString())
							}
							options={gatewaysOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Шлюзы'
							formatOptionLabel={formatOptionLabel}
							isClearable
						/>
					</SelectWrapper>
				</div>
				{/* Row 3 */}
				<div className='rowFlex'>
					<SelectWrapper label='Продукты'>
						<Select
							value={multiSelectOptions?.find(o => o.value === productId)}
							onChange={onChangeProductId}
							options={multiSelectOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Продукты'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Ошибки'>
						<Select
							isMulti={true}
							value={valueSelectErrors}
							onChange={handleOnChangeMultiSelect}
							options={paymentErrorListOption}
							styles={selectStyles}
							placeholder='Ошибки'
						/>
					</SelectWrapper>
					<SelectWrapper label='Банки'>
						<Select
							isMulti={true}
							onChange={o => setIssuers(o.map(item => item.value).toString())}
							options={bankListOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Банки...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='ЮрЛицо'>
						<Select
							isMulti={true}
							onChange={o =>
								setCompanyIds(o.map(item => item.value).toString())
							}
							options={companiesOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='ЮрЛицо'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='Банк'
						value={issuer}
						onChange={onChangeIssuer}
					/>
					<SelectWrapper label='Cтатус Транзакции'>
						<Select
							value={transactionStatusOption?.find(
								o => o.value === transactionStatus
							)}
							onChange={onChangeTransactionStatus}
							options={transactionStatusOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Cтатус'
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Платежная система'>
						<Select
							onChange={onChangePaymentSystem}
							options={paymentSystemLabels}
							styles={selectStyles}
							value={paymentSystemLabels.find(o => o.value === paymentSystem)}
							placeholder='Платежная система'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='number'
						name='fromAmount'
						label='Сумма от'
						value={fromAmount}
						onChange={e => setFromAmount(parseInt(e.target.value))}
					/>
					<FilterInput
						placeholder=''
						type='number'
						name='toAmount'
						label='Cумма до'
						value={toAmount}
						onChange={e => setToAmount(parseInt(e.target.value))}
					/>
					<FilterInput
						placeholder=''
						type='text'
						name='userId'
						label='userId'
						value={userId}
						onChange={onChangeUserId}
					/>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : banks ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				<div className='rowFlex'>
					<FilterInput
						placeholder=''
						type='number'
						name='count'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : chainErrors ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<FilterInput
						placeholder=''
						type='text'
						name='webmaster'
						label='ID Вебмастера'
						value={webmaster}
						onChange={onChangeWebmaster}
					/>
					<FilterInput
						placeholder=''
						type='text'
						name='id'
						label='ID заказа'
						value={orderIds}
						onChange={onChangeOrderIds}
					/>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Банк'
						value={issuer}
						onChange={onChangeIssuer}
					/>
					<SelectWrapper label='Тип: Билл/Ребилл'>
						<Select
							onChange={onChangeType}
							options={orderLabels}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === type)}
							placeholder='Тип: Билл/Ребилл'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Эквайринг'>
						<Select
							onChange={onChangePaymentSystem}
							options={paymentSystemLabels}
							styles={selectStyles}
							value={paymentSystemLabels.find(o => o.value === paymentSystem)}
							placeholder='Эквайринг'
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Юр Лицо'>
						<Select
							isMulti={true}
							onChange={o => setCompanies(o.map(item => item.value).toString())}
							options={companiesOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Юр Лицо'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Есть шаг'>
						<Select
							value={stepOption?.find(o => o.value === step)}
							onChange={onChangeStep}
							options={stepOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='шаг'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Нет шага'>
						<Select
							value={stepOption?.find(o => o.value === stepNone)}
							onChange={onChangeStepNone}
							options={stepOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='шаг'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Шлюзы'>
						<Select
							isMulti={true}
							onChange={o =>
								setPaymentGatewayIds(o.map(item => item.value).toString())
							}
							formatOptionLabel={formatOptionLabel}
							options={gatewaysOption}
							placeholder='Шлюзы'
							isSearchable={true}
							styles={selectStyles}
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Продукты'>
						<Select
							isMulti={true}
							onChange={o => setProducts(o.map(item => item.value).toString())}
							options={multiSelectOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Продукты...'
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Банки'>
						<Select
							isMulti={true}
							onChange={o => setIssuers(o.map(item => item.value).toString())}
							options={bankListOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Банки...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Статус Транзакции'>
						<Select
							value={transactionStatusOption?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Статус'>
						<Select
							isMulti={true}
							onChange={o =>
								setStatusesOrder(o.map(item => item.value).toString())
							}
							options={statusLabelsOrder}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Страна по БК'>
						<Select
							onChange={onChangeCountries}
							options={countriesOption}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === country)}
							placeholder='Страна по БК'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Страна по IP'>
						<Select
							onChange={onChangePayCountries}
							options={payCountriesOption}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === payCountry)}
							placeholder='Страна по IP'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : topErrors ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='ID Вебмастера'
						value={webmaster}
						onChange={onChangeWebmaster}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Банк'
						value={issuer}
						onChange={onChangeIssuer}
					/>
					<FilterInput
						placeholder='Домен'
						type='text'
						label='Домен'
						value={domain}
						onChange={onChangeDomain}
					/>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Продукты'>
						<Select
							isMulti={true}
							onChange={o => setProducts(o.map(item => item.value).toString())}
							options={multiSelectOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Продукты...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Юр Лицо'>
						<Select
							isMulti={true}
							onChange={o => setCompanies(o.map(item => item.value).toString())}
							options={companiesOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Юр Лицо'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Ошибки'>
						<Select
							isMulti={true}
							isSearchable={true}
							onChange={o => setErrors(o.map(item => item.value).toString())}
							options={paymentErrorListOption}
							styles={selectStyles}
							placeholder='Ошибки'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Тип: Билл/Ребилл'>
						<Select
							onChange={onChangeType}
							options={orderLabels}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === type)}
							placeholder='Тип: Билл/Ребилл'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Шлюзы'>
						<Select
							onChange={onChangePaymentGatewayId}
							options={gatewaysOption}
							value={gatewaysOption?.find(o => o.value === paymentGatewayId)}
							placeholder='Шлюзы'
							isSearchable={true}
							styles={selectStylesLong}
							formatOptionLabel={formatOptionLabel}
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : statisticChargeback ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='ID Вебмастера'
						value={webmaster}
						onChange={onChangeWebmaster}
					/>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Продукты'>
						<Select
							value={multiSelectOptions?.find(o => o.value === productId)}
							onChange={onChangeProductId}
							formatOptionLabel={formatOptionLabel}
							options={multiSelectOptions}
							styles={selectStylesLong}
							isSearchable={true}
							placeholder='Продукты...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Эквайринг'>
						<Select
							value={paymentsOptions?.find(o => o.value === equirer)}
							onChange={onChangeEquirer}
							options={paymentsOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Эквайринг...'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : webmasterChargeback ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='ID Вебмастера'
						value={webmaster}
						onChange={onChangeWebmaster}
					/>
					<FilterInput
						placeholder='Email'
						type='text'
						label='Email'
						value={email}
						onChange={onChangeEmail}
					/>
					<FilterInput
						placeholder='Лендинг'
						type='text'
						label='Лендинг'
						value={landing}
						onChange={onChangeLanding}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Продукты'>
						<Select
							value={multiSelectOptions?.find(o => o.value === productId)}
							onChange={onChangeProductId}
							formatOptionLabel={formatOptionLabel}
							options={multiSelectOptions}
							styles={selectStylesLong}
							isSearchable={true}
							placeholder='Продукты...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Эквайринг'>
						<Select
							value={paymentsOptions?.find(o => o.value === equirer)}
							onChange={onChangeEquirer}
							options={paymentsOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Эквайринг...'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Тип: Обычный/Арбитражный'>
						<Select
							value={typeLabels?.find(o => o.value === type)}
							onChange={onChangeTypeCharge}
							options={typeLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Тип'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : gatewayStatistics ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='ЮрЛица'>
						<Select
							onChange={onChangeCompanyId}
							options={companiesOption}
							value={companiesOption?.find(o => o.value === companyId)}
							placeholder='ЮрЛица'
							isSearchable={true}
							styles={selectStyles}
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : refund ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							value={statusLabels?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Тип: Билл/Ребилл'>
						<Select
							onChange={onChangeType}
							options={orderLabels}
							styles={selectStyles}
							value={orderLabels.find(o => o.value === type)}
							placeholder='Тип: Билл/Ребилл'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder='Email'
						type='text'
						label='Email'
						value={email}
						onChange={onChangeEmail}
					/>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
				</div>
				{/* Row 2 */}
				<div className='rowFlex'>
					<FilterInput
						placeholder=''
						type='text'
						label='ФИО'
						value={name}
						onChange={onChangeName}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Причина возврата'
						value={reason}
						onChange={onChangeReason}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Номер'
						value={clientId}
						onChange={onChangeClientId}
					/>
					<SelectWrapper label='Шлюзы'>
						<Select
							onChange={onChangePaymentGatewayId}
							options={gatewaysOption}
							formatOptionLabel={formatOptionLabel}
							value={gatewaysOption?.find(o => o.value === paymentGatewayId)}
							placeholder='Шлюзы'
							isSearchable={true}
							styles={selectStylesLong}
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : gatewayPage ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							value={statusLabels?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='ЮрЛицо'>
						<Select
							isMulti={true}
							onChange={o =>
								setCompanyIds(o.map(item => item.value).toString())
							}
							options={companiesOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='ЮрЛицо'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
					<SelectWrapper label='SberBill'>
						<Select
							value={sberbillOrSberrouteOption?.find(o => o.value === sberbill)}
							onChange={onChangeSberbill}
							options={sberbillOrSberrouteOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='SberBill'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='SberRoute'>
						<Select
							value={sberbillOrSberrouteOption?.find(
								o => o.value === sberroute
							)}
							onChange={onChangeSberroute}
							options={sberbillOrSberrouteOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='SberRoute'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Чистый/Грязный шлюз'>
						<Select
							value={dirtyOption?.find(o => o.value === dirty)}
							onChange={onChangeDirty}
							options={dirtyOption}
							styles={selectStyles}
							placeholder='Шлюз'
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<SelectWrapper label='Тарифы'>
						<Select
							value={schemeOption?.find(o => o.value === scheme)}
							onChange={onChangeScheme}
							options={schemeOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Тарифы'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Остановка ребиллов по шлюзу'>
						<Select
							isMulti={true}
							onChange={o =>
								setRecurrentStoppedIds(o.map(item => item.value).toString())
							}
							options={stopRebillOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Остановка ребиллов'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : domainPage ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							value={statusLabels?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Продукты'>
						<Select
							isMulti={true}
							value={valueSelect}
							onChange={handleOnChangeMultiSelect}
							options={multiSelectOptions}
							getOptionLabel={option => option.innerName}
							getOptionValue={option => option.id}
							styles={selectStyles}
							placeholder='Продукты...'
						/>
					</SelectWrapper>
					<SelectWrapper label='ЮрЛицо'>
						<Select
							isMulti={true}
							onChange={o =>
								setCompanyIds(o.map(item => item.value).toString())
							}
							options={companiesOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='ЮрЛицо'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='landFooter'>
						<Select
							value={landFooterOptions?.find(o => o.value === landFooter)}
							onChange={onChangeLandFooter}
							options={landFooterOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='landFooter'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Шлюзы'>
						<Select
							onChange={onChangePaymentGatewayId}
							formatOptionLabel={formatOptionLabel}
							options={gatewaysOption}
							value={gatewaysOption?.find(o => o.value === paymentGatewayId)}
							placeholder='Шлюзы'
							isSearchable={true}
							styles={selectStylesLong}
							isClearable
						/>
					</SelectWrapper>
				</div>
				<div className='rowFlex'>
					<FilterInput
						placeholder=''
						type='text'
						label='Домен'
						value={link}
						onChange={onChangeLink}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
					<SelectWrapper label='Репозиторий'>
						<Select
							value={folderListOption?.find(o => o.value === folder)}
							onChange={onChangeFolder}
							options={folderListOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Репозиторий'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='SberBill'>
						<Select
							value={sberbillOrSberrouteOption?.find(o => o.value === sberbill)}
							onChange={onChangeSberbill}
							options={sberbillOrSberrouteOption}
							styles={selectStyles}
							isSearchable={true}
							placeholder='SberBill'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Шлюзы (ref/noRef)'>
						<Select
							isMulti={true}
							onChange={o => setGateways(o.map(item => item.value).toString())}
							options={gatewaysDomainOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='шлюзы (ref/noRef)'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Регион'>
						<Select
							value={regionIdOptions?.find(o => o.value === regionId)}
							onChange={onChangeRegionId}
							options={regionIdOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Регион'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : analytics ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Эквайринг'>
						<Select
							onChange={onChangePaymentSystem}
							options={paymentSystemLabels}
							styles={selectStyles}
							value={paymentSystemLabels.find(o => o.value === paymentSystem)}
							placeholder='Платежная система'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Валюта'>
						<Select
							onChange={onChangeCurrency}
							options={currencyLabels}
							styles={selectStyles}
							value={currencyLabels.find(o => o.value === currency)}
							placeholder='Валюта'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='ЮрЛицо'>
						<Select
							onChange={onChangeCompanyId}
							options={companiesOption}
							value={companiesOption?.find(o => o.value === companyId)}
							placeholder='ЮрЛицо'
							isSearchable={true}
							styles={selectStyles}
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : analyticsMids ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Валюта'>
						<Select
							onChange={onChangeCurrency}
							options={currencyLabels}
							styles={selectStyles}
							value={currencyLabels.find(o => o.value === currency)}
							placeholder='Валюта'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Эквайринг'>
						<Select
							onChange={onChangePaymentSystem}
							options={paymentSystemLabels}
							styles={selectStyles}
							value={paymentSystemLabels.find(o => o.value === paymentSystem)}
							placeholder='Платежная система'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='ЮрЛицо'>
						<Select
							onChange={onChangeCompanyId}
							options={companiesOption}
							value={companiesOption?.find(o => o.value === companyId)}
							placeholder='ЮрЛицо'
							isSearchable={true}
							styles={selectStyles}
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder=''
						type='text'
						label='Gateway'
						value={gateway}
						onChange={onChangeGateway}
					/>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : shakerStats ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				<div className='rowFlex'>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : sberOut ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='ЮрЛица'>
						<Select
							onChange={onChangeCompanyId}
							options={companiesOption}
							value={companiesOption?.find(o => o.value === companyId)}
							styles={selectStyles}
							placeholder='ЮрЛица'
							isSearchable={true}
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Тип: Обычный/Арбитражный'>
						<Select
							value={typeLabels?.find(o => o.value === type)}
							onChange={onChangeTypeCharge}
							options={typeLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Тип'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : sberBill ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='ЮрЛица'>
						<Select
							onChange={onChangeCompanyId}
							options={companiesOption}
							value={companiesOption?.find(o => o.value === companyId)}
							placeholder='ЮрЛица'
							isSearchable={true}
							styles={selectStyles}
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : productPage ? (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							value={statusLabels?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<SelectWrapper label='Регион'>
						<Select
							value={regionIdOptions?.find(o => o.value === regionId)}
							onChange={onChangeRegionId}
							options={regionIdOptions}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Регион'
							isClearable
						/>
					</SelectWrapper>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className='filters'>
			<h2 className='filters__title'>Фильтры</h2>
			<div className='filters__inner'>
				{/* Row 1 */}
				<div className='rowFlex'>
					<SelectWrapper label='Статус'>
						<Select
							value={statusLabels?.find(o => o.value === status)}
							onChange={onChangeState}
							options={statusLabels}
							styles={selectStyles}
							isSearchable={true}
							placeholder='Статус'
							isClearable
						/>
					</SelectWrapper>
					<FilterInput
						placeholder='Email'
						type='text'
						label='Email'
						value={email}
						onChange={onChangeEmail}
					/>
					<SelectWrapper label='Дата от'>
						<DatePicker
							selected={fromDate}
							onChange={onChangeFromDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterFrom}
							locale={ru}
						/>
					</SelectWrapper>
					<SelectWrapper label='Дата до'>
						<DatePicker
							selected={toDate}
							onChange={onChangeToDate}
							dateFormat='dd.MM.yyyy'
							isClearable={true}
							className='filter-dataPicker'
							filterDate={filterTo}
							locale={ru}
						/>
					</SelectWrapper>
					<FilterInput
						placeholder='Домен'
						type='text'
						label='Домен'
						value={domain}
						onChange={onChangeDomain}
					/>
					<FilterInput
						placeholder=''
						type='text'
						label='Выводить по'
						value={limit}
						onChange={onChangeLimit}
					/>
				</div>
				{/* Row 2 */}
				<div className='rowFlex'>
					<FilterInput
						placeholder='ID'
						type='text'
						label='ID'
						value={id}
						onChange={onChangeId}
					/>
					<FilterInput
						placeholder='0'
						type='text'
						label='Номер'
						value={orderNumber}
						onChange={onChangeOrderNumber}
					/>
					<FilterInput
						placeholder='000 000'
						type='text'
						label='Первые 6 цифр карты'
						value={firstSix}
						onChange={onChangeFirstSix}
					/>
					<FilterInput
						placeholder='0000'
						type='text'
						label='Последние 4 цифр карты'
						value={lastFour}
						onChange={onChangeLastFour}
					/>
					<div>
						<Button label='Применить' onClick={handleApplyFilters} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Filters
