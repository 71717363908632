import {
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGIN_FAILURE,
  AUTH_CLEAN_ERORRS_LOCALE,
  AUTH_FAIL_LOCALE,
  AUTH_LOGOUT_REQUEST,
  AUTH_2FA_ON_SUCCESS,
  AUTH_2FA_ON_FAILURE,
  AUTH_2FA_QRCODE_SUCCESS,
  AUTH_2FA_TOKEN_SUCCESS,
  AUTH_LOGIN_SECOND_SUCCESS,
  AUTH_2FA_TOKEN_QRCODE_CLEAR,
} from 'actions/auth';

const initialState = {
  auth: 'unknown',
  id: null,
  role: null,
  errors: [],
  isLoad: false,
  auth2FaError: '',
  token: '',
  qrCode: null,
  statusAuth2Fa: '',
  is2faEnabled: null,
  authSecondError: false,
};
// authSecondError: action.payload.is2faEnabled ? "false" : "true",...action.payload 
export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_CHECK_REQUEST:
      return state;
    case AUTH_LOGIN_SUCCESS:
    case AUTH_CHECK_SUCCESS:
      return { ...state, errors: [], isLoad: false, is2faEnabled: action.payload.is2faEnabled === false || action.payload.is2faEnabled === true ? action.payload.is2faEnabled : action.payload === `{"login": "success": "2fa_complete": false}` ? true : null, auth: action.payload.is2faEnabled === false || action.payload.is2faEnabled === true ? "success" : action.payload === `{"login": "success": "2fa_complete": false}` ? "pending" : null, ...action.payload };
    case AUTH_LOGIN_SECOND_SUCCESS:
      return { ...state, errors: action.payload.is2faEnabled ? [] : "Неправильный 2FA код", isLoad: false, auth: action.payload?.is2faEnabled ? "success" : "fail", is2faEnabled: true, token: '', qrCode: null, ...action.payload }
    case AUTH_LOGIN_FAILURE:
      const errorPayload = { ...action.payload, error: 'Неправильный логин или пароль' };
      const newErrors = state.errors.concat(errorPayload);
      return { ...state, errors: newErrors, isLoad: false };
    case AUTH_LOGIN_REQUEST:
      return { ...state, isLoad: true };
    case AUTH_LOGOUT_REQUEST:
    case AUTH_LOGOUT_SUCCESS:
      return { ...state, is2faEnabled: false };
    case AUTH_LOGOUT_FAILURE:
    case AUTH_CHECK_FAILURE:
      return { ...state, auth: 'fail' };
    case AUTH_CLEAN_ERORRS_LOCALE:
      return { ...state, errors: [] };
    case AUTH_FAIL_LOCALE:
      return { ...state, auth: 'fail' };
    case AUTH_2FA_ON_SUCCESS:
      return { ...state, statusAuth2Fa: action.payload }
    case AUTH_2FA_ON_FAILURE:
      return { ...state, auth2FaError: action.payload.data.error, statusAuth2Fa: action.payload.status };
    case AUTH_2FA_QRCODE_SUCCESS:
      return { ...state, qrCode: action.payload };
    case AUTH_2FA_TOKEN_SUCCESS:
      return { ...state, token: action.payload.token };
    case AUTH_2FA_TOKEN_QRCODE_CLEAR:
      return { ...state, token: '', qrCode: null };
    default:
      return state;
  }
}
