import FilePage from './FilePage';
import { modalCleanData } from 'store/slices/modal';
import { fileDelete } from 'actions/file';
import { fileGetAll } from 'actions/file';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    files: state.file.list,
    modalStatus: state.modal.modalStatus,
    infoPayload: state.modal.infoPayload,
    role: state.auth.role,
    availableEdit: state.auth.role.permissions.file.includes('edit_files'),
  };
};
const mapDispatchToProps = {
  fileGetAll,
  modalCleanData,
  fileDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilePage);
