import React, { useEffect } from 'react'
import Header from 'assets/components/Header'
import Table from 'assets/components/Table'
import Loader from '../../assets/components/Loader'
import { Filters } from 'assets/components/Filters'
import EditProductModal from './EditProductModal'
import { toast } from 'react-toastify'
import { modalCleanData } from 'store/slices/modal'
import {
	productMassUpdate,
	setProductMassUpdateStatus,
	setProductCleanErrors,
	productGetAll,
	productDelete,
	setProductFilters,
	productDuplicate
} from 'store/slices/product'
import { getRegions } from 'store/slices/domain'
import { useAppDispatch, useAppSelector } from 'store'

const titles = [
	{
		name: 'ID',
		mod: 'sm'
	},
	{
		name: 'обложка'
	},
	{
		name: 'название',
		mod: 'big'
	},
	{
		name: 'статус'
	},
	{
		name: 'валюта'
	},
	{
		name: 'валюта на ленде'
	},
	{
		name: 'цены'
	},
	{
		name: 'Tariff'
	},
	{
		name: 'домены',
		mod: 'big'
	},
	{
		name: '...',
		mod: 'm'
	}
]

const ProductsPage = () => {
	const dispatch = useAppDispatch()
	const massUpdateStatus = useAppSelector(
		state => state.product.massUpdateStatus
	)
	const regionIdOptions = useAppSelector(state => state.domain.regionIds).map(
		el => ({ ...el, label: el.name, value: el.id })
	)
	const errorUpdate = useAppSelector(state => state.product.errors)
	const products = useAppSelector(state => state.product.list)
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const isLoad = useAppSelector(state => state.product.isLoad)
	const appliedFilters = useAppSelector(state => state.product.filters)
	const availableEdit = useAppSelector(
		state => state.auth.role
	)?.permissions.product.includes('edit_products')

	const [checkProducts, setCheckProducts] = React.useState()
	const [isCheckProduct, setIsCheckProduct] = React.useState([])
	const [isCheckAllProduct, setIsCheckAllProduct] = React.useState(false)
	const [activeEdit, setActiveEdit] = React.useState(false)
	const [categories, setCategories] = React.useState([])
	// debugger;
	useEffect(() => {
		setCheckProducts(products)
	}, [products])

	useEffect(() => {
		let params = {}

		for (let key in appliedFilters) {
			if (appliedFilters[key]) {
				params[`filter[${key}]`] = appliedFilters[key]
			}
		}

		dispatch(productGetAll(params))
	}, [productGetAll, appliedFilters])

	useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(productDelete(infoPayload.id))
			dispatch(modalCleanData())
		}
		if (modalStatus === 'copy') {
			dispatch(productDuplicate(infoPayload.id))
			dispatch(modalCleanData())
		}
	}, [modalStatus])

	useEffect(() => {
		dispatch(getRegions())
	}, [])

	const handleClickCheck = e => {
		const checked = e.target.checked
		const id = Number(e.target.id)
		setIsCheckProduct([...isCheckProduct, id])
		if (!checked) {
			setIsCheckProduct(isCheckProduct.filter(item => item !== id))
		}
	}

	const handleClickCheckSelectAll = e => {
		setIsCheckAllProduct(!isCheckAllProduct)
		setIsCheckProduct(checkProducts.map(li => li.id))
		if (isCheckAllProduct) {
			setIsCheckProduct([])
		}
	}

	const handleClickStartEdit = () => {
		setActiveEdit(true)
	}

	const handleCliсkCloseTable = () => {
		setCategories([])
		setActiveEdit(false)
	}

	const handleClickMassUpdate = async () => {
		const updateData = await isCheckProduct.map(item => ({
			productId: item,
			categoryIds: categories
		}))

		dispatch(productMassUpdate(updateData))
	}

	useEffect(() => {
		if (massUpdateStatus === 'success') {
			handleCliсkCloseTable()
			toast.success(`Редактирование прошло успешно`)
			dispatch(setProductMassUpdateStatus(''))
			dispatch(setProductCleanErrors(''))
			setCategories([])
		}
		if (massUpdateStatus === 'error') {
			handleCliсkCloseTable()
			toast.error(errorUpdate || 'Произошла ошибка ;(')
			dispatch(setProductMassUpdateStatus(''))
			dispatch(setProductCleanErrors(''))
			setCategories([])
		}
	}, [massUpdateStatus])

	return (
		<>
			{isLoad ? <Loader /> : ''}
			<Header
				title='Продукты'
				text={availableEdit ? 'Добавить продукт' : null}
				href={availableEdit ? '/product/add' : null}
			/>
			<Filters
				appliedFilters={{ ...appliedFilters }}
				onFilterChange={obj => dispatch(setProductFilters(obj))}
				productPage={true}
				regionIdOptions={regionIdOptions}
				statusLabels={[
					{ label: 'Активный', value: '1' },
					{ label: 'Неактивный', value: '2' }
				]}
			/>
			<Table
				titles={titles}
				content={products}
				isCheck={isCheckProduct}
				isCheckAll={isCheckAllProduct}
				handleClickCheck={handleClickCheck}
				handleClickCheckSelectAll={handleClickCheckSelectAll}
				handleClickStartEdit={handleClickStartEdit}
				keyProp='product'
			/>
			{activeEdit && (
				<EditProductModal
					categories={categories}
					setCategories={setCategories}
					handleCliсkCloseTable={handleCliсkCloseTable}
					handleClickMassUpdate={handleClickMassUpdate}
				/>
			)}
		</>
	)
}

export default ProductsPage
