import GatewayCrgPage from './GatewayCrgPage';
import { connect } from 'react-redux';
import {
  getPaymentOptions,
  postCreateGateway,
  getGatewayById,
  updateGateway,
  getGatewayStatuses,
  getBanks,
  getAllGateways,
  getRatesList
} from 'store/slices/gateway';

const mapStateToProps = (state) => ({
  gateways: state.gateway.gatewayData,
  payments: state.gateway.payments,
  isLoad: state.gateway.isLoad,
  statusList: state.gateway.statusList,
  banks: state.gateway.banks,
  rates: state.gateway.rates,
});

const mapDispatchToProps = {
  getPaymentOptions,
  postCreateGateway,
  getGatewayById,
  updateGateway,
  getGatewayStatuses,
  getBanks,
  getAllGateways,
  getRatesList
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayCrgPage);
