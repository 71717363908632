import React, { useEffect, useState } from 'react';
import { strikesCreate, setStrikesCleanRedirect } from 'store/slices/strikes';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Loader from '../../assets/components/Loader';

const StrikesCrtPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [nameStrike, setNameStrike] = useState('');
  const [strikeDate, setStrikeDate] = useState(null);
  const isLoad = useSelector((state) => state.strikes.isLoad);
  const errors = useSelector((state) => state.strikes.errors);
  const redirect = useSelector((state) => state.strikes.redirect);
  const { gatewayId } = useParams();

  useEffect(() => {
    if (redirect) {
      dispatch(setStrikesCleanRedirect());
      history.goBack();
    }
  }, [redirect, dispatch]);

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Создание страйка</h2>
        <div className='strikes-date'>
          <label className='create__label'>Укажите Дату</label>
          <DatePicker
            selected={strikeDate}
            onChange={(e) => setStrikeDate(e)}
            isClearable={true}
            dateFormat='dd.MM.yyyy'
            className='strikes-dataPicker'
            placeholderText='дд.мм.гггг'
          />
        </div>
        <div className='textarea-strikes'>
          <label className='create__label'>Описание</label>
          <textarea
            value={nameStrike}
            onChange={(e) => {
              setNameStrike(e.target.value);
            }}
            placeholder='Описание'
            type='text'
            name='nameStrike'
          />
        </div>
        {errors.strokeAt && <div>{errors.strokeAt}</div>}
        <button
          className='button'
          onClick={() =>
            dispatch(
              strikesCreate({
                description: nameStrike,
                gatewayId: +gatewayId,
                strokeAt: `${format(strikeDate, 'dd-MM-yyyy')} 00:00:00`,
              }),
            )
          }
          disabled={!nameStrike || !strikeDate}
        >
          Сохранить
        </button>
        <button onClick={() => history.goBack()} className='btn btn-back'>
          <span className='btn__text'>Вернутся назад</span>
        </button>
      </div>
    </>
  );
};

export default StrikesCrtPage;
