import React from 'react';
import Header from 'assets/components/Header';
import { useAppSelector, useAppDispatch } from 'store';
import { LinkTabs } from 'assets/components/LinkTabs/LinkTabs';
import Admins from './Admins/Admins';
import Roles from './Roles/Roles';
import { startDeleteAdmin, startDeleteRole } from 'store/slices/admin';
import { useParams } from 'react-router-dom';

const list = [
  { name: 'Админы', link: '/admin/admins', value: 'admins' },
  { name: 'Роли', link: '/admin/roles', value: 'roles' },
];

const AdminPage = () => {
  const dispatch = useAppDispatch();
  const modalText = useAppSelector((state) => state.modal.modalText);
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const canEdit = useAppSelector((state) => state.auth.role.permissions.admin.includes('edit_admins'));
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);
  const { tabName } = useParams();
  React.useEffect(() => {
    if (modalStatus === 'yes' && modalText === 'Вы действительно хотите удалить админа?') {
      dispatch(startDeleteAdmin(infoPayload));
    }
    if (modalStatus === 'yes' && modalText === 'Вы действительно хотите удалить роль?') {
      dispatch(startDeleteRole(infoPayload));
    }
  }, [modalStatus, infoPayload, startDeleteAdmin, modalText, startDeleteRole]);

  return (
    <>
      <Header
        title='Админы'
        href={canEdit && tabName === 'admins' ? '/admin/add' : canEdit && tabName === 'roles' ? '/admin/role/add' : null}
        text={canEdit && tabName === 'admins' ? 'Добавить админа' : canEdit && tabName === 'roles' ? 'Добавить роль' : null}
      />
      <LinkTabs list={list} />
      {tabName === 'admins' && <Admins />}
      {tabName === 'roles' && <Roles />}
    </>
  );
};

export default AdminPage;
