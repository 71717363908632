import axios from 'axios';
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query';

const { hostname } = window.location;

const apiPath = `https://${hostname.replace('admin', 'api')}`;

export const request = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_STAGE === 'local' ? process.env.REACT_APP_REQUEST_PATH : apiPath,
});

// /// RTK Query alternative
// export const rtkApi = createApi({
//   reducerPath: 'baseApi',
//   baseQuery: fetchBaseQuery({ baseUrl: origin }),
//   endpoints: (builder) => ({
//     // statistics
//     statisticsGetAll: builder.query({
//       query: () => '/admin/statistic',
//     }),
//   }),
// });
