import React from 'react';
import cn from 'classnames';
import { ReactComponent as User } from 'assets/img/user.svg';
import { ReactComponent as Lock } from 'assets/img/lock.svg';
import InputMask from 'react-input-mask';

const iconPrint = (text) => {
  switch (text) {
    case 'user':
      return <User className='input__icon' />;
    case 'lock':
      return <Lock className='input__icon' />;
    default:
      return null;
  }
};
const Input = ({
  placeholder,
  type,
  name,
  img,
  inputCls,
  label,
  inputItemCls,
  onChange = () => {},
  value = '',
  mask = '',
  onClick = () => {},
  error = '',
  disabled,
  isClearable,
  onClear = () => {},
}) => (
  <div className={cn('input', inputCls)}>
    <label className='input__label'>{label}</label>
    {iconPrint(img)}

    <div className='input__wrapper'>
      <InputMask
        mask={mask}
        className={cn('input__item', inputItemCls)}
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        onClick={onClick}
        autoComplete='off'
        disabled={disabled}
      />
      {isClearable && value ? (
        <svg
          height='20'
          width='20'
          viewBox='0 0 20 20'
          aria-hidden='true'
          focusable='false'
          className='input__clear-icon'
          onClick={onClear}
        >
          <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
        </svg>
      ) : null}
    </div>
    <div className='error' title='Error'>
      {error}
    </div>
  </div>
);

export default Input;
