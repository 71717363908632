import React, { useEffect, useState } from 'react';
import Input from 'assets/components/Input';
import ButtonLink from 'assets/components/ButtonLink';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../assets/components/Loader';
import { setCleanErrors, setCleanRedirect, pixelUpdate, pixelGetOne } from 'store/slices/pixel';
import { useAppDispatch, useAppSelector } from 'store';

const PixelEditPage = () => {
  const dispatch = useAppDispatch();
  const redirect = useAppSelector((state) => state.pixel.redirect);
  const curPixel = useAppSelector((state) => state.pixel.curPixel);
  const errors = useAppSelector((state) => state.pixel.errors);
  const isLoad = useAppSelector((state) => state.pixel.isLoad);
  const [value, setValue] = useState('');
  const [firstRun, setFirstRun] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (redirect) {
      dispatch(setCleanRedirect());
      history.goBack();
    }
    if (errors.pixel) {
      dispatch(setCleanErrors());
      history.goBack();
    }
  }, [redirect]);

  useEffect(() => {
    if (firstRun) {
      window.scrollTo(0, 0);
      setFirstRun(false);
      dispatch(pixelGetOne(id));
    } else {
      setValue(curPixel.value);
    }
  }, [curPixel.value]);

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Изменение пикселя {curPixel.type}</h2>
        <Input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (errors.value) dispatch(setCleanErrors());
          }}
          error={errors.value || ''}
          placeholder='Значение'
          type='text'
          name='value'
          inputCls='create__input'
          label='Значение'
        />
        {curPixel.id && (
          <>
            <p className='create__info'>
              Этот пиксель прикреплен к продукту {curPixel.productName} (ID: {curPixel.productId})
            </p>
            <button className='button' onClick={() => dispatch(pixelUpdate(curPixel.id, { value }))}>
              Сохранить
            </button>
          </>
        )}
        <ButtonLink text='Назад к списку' btnClass='create__prev' onClick={() => history.goBack()} />
      </div>
    </>
  );
};

export default PixelEditPage;
