import React, { useEffect } from 'react';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Filters from 'assets/components/Filters';
import Loader from '../../assets/components/Loader';
import Pagination from 'rc-pagination';
import { userGetAll, setUserFilter, setUserPage } from 'store/slices/user';
import { useAppDispatch, useAppSelector } from 'store';

const titles = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'email',
    mod: 'big',
  },
  {
    name: 'имя',
    mod: 'big',
  },
  // {
  //   name: 'Order id',
  //   mod: 'big',
  // },
  {
    name: 'кол-во заказов',
  },
  {
    name: 'кол-во платежей',
  },
  {
    name: 'Сумма, всего',
  },
  {
    name: 'создан',
    mod: 'big',
  },
  {
    name: 'статус',
  },
  {
    name: '',
    mod: 'm',
  },
];

const UserPage = () => {
  const dispatch = useAppDispatch();
  const appliedFilters = useAppSelector((state) => state.user.filters);
  const isLoad = useAppSelector((state) => state.user.isLoad);
  const limit = useAppSelector((state) => state.user.pagination.limit);
  const page = useAppSelector((state) => state.user.pagination.page);
  const total = useAppSelector((state) => state.user.pagination.total);
  const users = useAppSelector((state) => state.user.list);
  const [reloadPage, setReloadPage] = React.useState(true);

  const handleGetUsers = async () => {
    const params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key === 'status' ? 'isActive' : key}]`] = await appliedFilters[key];
      }
    }

    params.limit = limit;
    params.page = page;

    dispatch(userGetAll(params));
  };

  useEffect(() => {
    // remove empty filters and format for request
    if (reloadPage) {
      handleGetUsers();
      setReloadPage(false);
    }
  }, [reloadPage]);

  const handlePageChange = (page) => {
    dispatch(setUserPage(page));
    setReloadPage(true);
  };

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='Клиенты' />
      <Filters
        appliedFilters={{ ...appliedFilters, limit }}
        onFilterChange={(obj) => {
          dispatch(setUserFilter(obj));
          setReloadPage(true);
        }}
        statusLabels={[
          { label: 'Активный', value: '1' },
          { label: 'Неактивный', value: '0' },
        ]}
      />
      <Table titles={titles} content={users} keyProp='user' />
      <Pagination
        className='user__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
    </>
  );
};

export default UserPage;
