import OrderCrtPage from './OrderCrtPage';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isLoad: state.order.isLoad,
  };
};

export default connect(mapStateToProps, null)(OrderCrtPage);
