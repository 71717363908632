import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { companyGetAll } from 'store/slices/company'
import { modalCleanData } from 'store/slices/modal'
import { strikesDelete } from 'store/slices/strikes'
import { useAppSelector, useAppDispatch } from 'store'
import ButtonLink from 'assets/components/ButtonLink'
import Table from 'assets/components/Table'
import Select from 'react-select'
import Input from 'assets/components/Input'
import Loader from 'assets/components/Loader'

const stopRebillOption = [
	{ label: 'Ребиллы включены', value: 0 },
	{ label: 'Все выключены', value: 1 },
	{ label: 'Все по Сберу выключены', value: 2 },
	{ label: 'Все по ТКС выключены', value: 10 },
	{ label: 'Все по Сберу и ТКС выключены', value: 11 },
	{ label: 'Выключены все кроме Сбера', value: 3 },
	{ label: 'Первые выключены', value: 4 },
	{ label: 'Первые по Сберу выключены', value: 5 },
	{ label: 'Первые кроме Сбера выключены', value: 6 },
	{ label: 'Повторные выключены', value: 7 },
	{ label: 'Повторные по Сберу выключены', value: 8 },
	{ label: 'Повторные кроме Сбера выключены', value: 9 }
]

const schemeOption = [
	{ label: 'Актуальный', value: 0 },
	{ label: '297-297', value: 1 }
]

const strikesTableTitles = [
	{
		name: 'Название Strikes',
		mod: 'big'
	},
	{
		name: 'Дата',
		mod: 'big'
	},
	{
		name: '...',
		mod: 'm'
	}
]

const GatewayCrtPage = ({
	statusList,
	getGatewayStatuses,
	isLoad,
	gateways,
	getPaymentOptions,
	payments,
	postCreateGateway,
	getGatewayById,
	updateGateway,
	banks,
	getBanks,
	getRatesList,
	rates
}) => {
	const { id } = useParams()
	const dispatch = useAppDispatch()
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const companies = useAppSelector(state => state.company.list)
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const [paymentOptions, setPaymentOptions] = useState(null)
	const [selectedPayment, setSelectedPayment] = useState('')
	const [key1, setKey1] = useState('')
	const [key2, setKey2] = useState('')
	const [key3, setKey3] = useState('')
	const [key4, setKey4] = useState('')
	const [name, setName] = useState('')
	const [status, setStatus] = useState('')
	const [comment, setComment] = useState('')
	const [applepay, setApplepay] = useState('')
	const [companyId, setCompanyId] = useState('')
	const [bankId, setBankId] = useState(null)
	const [appeals, setAppeals] = useState(0)
	const [sberbill, setSberbill] = useState(false)
	const [sberroute, setSberroute] = useState(false)
	const [isDirty, setIsDirty] = useState(false)
	const [stopReccurentByCompanyId, setStopReccurentByCompanyId] = useState(0)
	const [stopReccurentByGatewayId, setStopReccurentByGatewayId] = useState(0)
	const [
		makeAdditionalPaymentImmediately,
		setMakeAdditionalPaymentImmediately
	] = useState(false)
	const [url, setUrl] = useState('')
	const [scheme, setScheme] = useState(0)
	const companiesOption = companies.map(item => ({
		value: item.id,
		label: item.name
	}))
	const banksOptions = banks.map(item => ({ value: item.id, label: item.name }))

	const isDirtyOption = [
		{
			label: 'Грязный',
			value: true
		},
		{
			label: 'Чистый',
			value: false
		}
	]
	const sberBillOption = [
		{
			label: 'Включен',
			value: true
		},
		{
			label: 'Выключен',
			value: false
		}
	]
	const sberRouteOption = [
		{
			label: 'Включен',
			value: true
		},
		{
			label: 'Выключен',
			value: false
		}
	]

	useEffect(() => {
		dispatch(companyGetAll())
		getGatewayStatuses()
		getBanks()
		getRatesList()
	}, [])

	useEffect(() => {
		if (!id) {
			getPaymentOptions()
		}
		if (id) {
			getGatewayById(id)
		}
	}, [id])

	useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(strikesDelete(infoPayload.id, gateways.id))
			dispatch(modalCleanData())
		}
	}, [modalStatus])

	useEffect(() => {
		payments &&
			setPaymentOptions(() =>
				Object.keys(payments).map((name, idx) => ({
					label: name,
					value: idx,
					disabled: false
				}))
			)
	}, [payments])

	useEffect(() => {
		if (id) {
			gateways?.paymentSystem && setSelectedPayment(gateways.paymentSystem)
			gateways?.data && setKey1(gateways.data[Object.keys(gateways.data)[0]])
			gateways?.data && setKey2(gateways.data[Object.keys(gateways.data)[1]])
			gateways?.data && setKey3(gateways.data[Object.keys(gateways.data)[2]])
			gateways?.data && setKey4(gateways.data[Object.keys(gateways.data)[3]])
			gateways?.name && setName(gateways.name)
			gateways?.status && setStatus(gateways.status)
			gateways?.comment && setComment(gateways.comment)
			gateways?.applepay && setApplepay(gateways.applepay)
			gateways?.companyId && setCompanyId(gateways.companyId)
			gateways?.bankId && setBankId(gateways.bankId)
			gateways?.appeals && setAppeals(gateways.appeals)
			gateways?.sberbill && setSberbill(gateways.sberbill)
			gateways?.sberroute && setSberroute(gateways.sberroute)
			gateways?.isDirty && setIsDirty(gateways.isDirty)
			gateways?.reccurentStopped?.byCompanyId &&
				setStopReccurentByCompanyId(gateways.reccurentStopped.byCompanyId)
			gateways?.reccurentStopped?.byGatewayId &&
				setStopReccurentByGatewayId(gateways.reccurentStopped.byGatewayId)
			gateways?.url && setUrl(gateways.url)
			gateways?.scheme && setScheme(gateways.scheme)
			gateways?.makeAdditionalPaymentImmediately &&
				setMakeAdditionalPaymentImmediately(
					gateways.makeAdditionalPaymentImmediately
				)
		}
		return () => {
			setSelectedPayment('')
			setKey1('')
			setKey2('')
			setKey3('')
			setKey4('')
			setName('')
			setStatus('')
			setComment('')
			setApplepay('')
			setCompanyId('')
			setBankId(null)
			setAppeals(0)
			setSberbill(false)
			setSberroute(false)
			setIsDirty(false)
			setStopReccurentByCompanyId(0)
			setStopReccurentByGatewayId(0)
			setMakeAdditionalPaymentImmediately(false)
			setUrl('')
			setScheme(0)
		}
	}, [gateways, id])

	const handleSaveClick = () => {
		const action = id ? updateGateway.bind(null, id) : postCreateGateway
		let data = {}
		if (key1 !== '' && key2 !== '' && key3 !== '' && key4 !== '') {
			data = {
				paymentSystem: selectedPayment,
				name,
				status,
				comment,
				data: {
					[payments[selectedPayment][0]]: key1,
					[payments[selectedPayment][1]]: key2,
					[payments[selectedPayment][2]]: key3,
					[payments[selectedPayment][3]]: key4
				},
				applepay,
				companyId,
				bankId,
				sberbill,
				sberroute,
				isDirty,
				stopReccurentByCompanyId,
				stopReccurentByGatewayId,
				url,
				scheme,
				makeAdditionalPaymentImmediately
				// appeals,
			}
		}
		if (key1 !== '' && key2 !== '' && key3 !== '' && key4 === '') {
			data = {
				paymentSystem: selectedPayment,
				name,
				status,
				comment,
				data: {
					[payments[selectedPayment][0]]: key1,
					[payments[selectedPayment][1]]: key2,
					[payments[selectedPayment][2]]: key3
				},
				applepay,
				companyId,
				bankId,
				sberbill,
				sberroute,
				isDirty,
				stopReccurentByCompanyId,
				stopReccurentByGatewayId,
				url,
				scheme,
				makeAdditionalPaymentImmediately
				// appeals,
			}
		}
		if (key1 !== '' && key2 !== '' && key3 === '' && key4 === '') {
			data = {
				paymentSystem: selectedPayment,
				name,
				status,
				comment,
				data: {
					[payments[selectedPayment][0]]: key1,
					[payments[selectedPayment][1]]: key2
				},
				applepay,
				companyId,
				bankId,
				sberbill,
				sberroute,
				isDirty,
				stopReccurentByCompanyId,
				stopReccurentByGatewayId,
				url,
				scheme,
				makeAdditionalPaymentImmediately
				// appeals,
			}
		}
		if (!id) {
			delete data.sberbill
			delete data.isDirty
		}
		action(data)
	}

	return (
		<>
			<div className='create gateway-crt'>
				{isLoad ? <Loader /> : ''}
				<h2 className='create__title'>
					{id ? 'Редактирование шлюза' : 'Создание шлюза'}
				</h2>
				<div className='gateway-crt-label'>Платёжка</div>
				<Select
					options={paymentOptions}
					onChange={data => setSelectedPayment(data.label)}
					value={
						paymentOptions &&
						paymentOptions.filter(el => el.label === selectedPayment)
					}
					isOptionDisabled={option =>
						id ? (option.disabled = true) : option.disabled
					}
				/>
				{selectedPayment && (
					<Input
						value={key1}
						onChange={e => setKey1(e.target.value)}
						placeholder={id ? '' : payments[selectedPayment][0]}
						type='text'
						name={id ? '' : payments[selectedPayment][0]}
						label={
							id ? Object.keys(gateways.data)[0] : payments[selectedPayment][0]
						}
						inputCls='gateway-crt__input'
						disabled={!!id}
					/>
				)}

				{selectedPayment && (
					<Input
						value={key2}
						onChange={e => setKey2(e.target.value)}
						placeholder={id ? '' : payments[selectedPayment][1]}
						type='text'
						name={id ? '' : payments[selectedPayment][1]}
						label={
							id ? Object.keys(gateways.data)[1] : payments[selectedPayment][1]
						}
						inputCls='gateway-crt__input'
						disabled={!!id}
					/>
				)}
				{selectedPayment && payments?.[selectedPayment]?.[2] && (
					<Input
						value={key3}
						onChange={e => setKey3(e.target.value)}
						placeholder={id ? '' : payments[selectedPayment][2]}
						type='text'
						name={id ? '' : payments[selectedPayment][2]}
						label={
							id ? Object.keys(gateways.data)[2] : payments[selectedPayment][2]
						}
						inputCls='gateway-crt__input'
						disabled={!!id}
					/>
				)}
				{selectedPayment && payments?.[selectedPayment]?.[3] && (
					<Input
						value={key4}
						onChange={e => setKey4(e.target.value)}
						placeholder={id ? '' : payments[selectedPayment][3]}
						type='text'
						name={id ? '' : payments[selectedPayment][3]}
						label={
							id ? Object.keys(gateways.data)[3] : payments[selectedPayment][3]
						}
						inputCls='gateway-crt__input'
						disabled={!!id}
					/>
				)}
				<Input
					value={name}
					onChange={e => {
						setName(e.target.value)
						// if (errors.link) domainCleanErrors('link');
					}}
					// error={errors.link || ''}
					placeholder='name'
					type='text'
					name='name'
					inputCls='gateway-crt__input'
					label='Имя'
					disabled={!!id}
				/>

				<Input
					value={comment}
					onChange={e => {
						setComment(e.target.value)
						// if (errors.link) domainCleanErrors('link');
					}}
					// error={errors.link || ''}
					placeholder='comment'
					type='text'
					name='comment'
					inputCls='gateway-crt__input'
					label='Комментарий'
				/>

				{id && (
					<Input
						value={appeals}
						onChange={e => {
							setAppeals(e.target.value)
						}}
						placeholder='Количество писем'
						type='text'
						name='appeals'
						inputCls='gateway-crt__input-appeals'
						label='Количество писем'
						disabled
					/>
				)}

				<Input
					value={url}
					onChange={e => {
						setUrl(e.target.value)
					}}
					placeholder='URL'
					type='text'
					name='url'
					inputCls='gateway-crt__input-appeals'
					label='URL'
				/>

				<div className='domain-crt-select'>
					<label className='input__label'>Статус</label>
					<Select
						options={statusList}
						value={statusList[statusList.findIndex(el => el.value == status)]}
						onChange={option => {
							setStatus(option.value)
						}}
						placeholder='Выберите статус'
					/>
				</div>

				<div className='domain-crt-select--second'>
					<label className='input__label'>ЮрЛицо</label>
					<Select
						options={companiesOption}
						value={
							companiesOption[
								companiesOption.findIndex(el => el.value === companyId)
							]
						}
						onChange={option => {
							setCompanyId(option.value)
						}}
						placeholder='Выберите ЮрЛицо'
					/>
				</div>

				{id && (
					<div className='domain-crt-select--second'>
						<label className='input__label'>
							Остановка ребиллов по юр.лицу
						</label>
						<Select
							options={stopRebillOption}
							value={
								stopRebillOption[
									stopRebillOption.findIndex(
										el => el.value === stopReccurentByCompanyId
									)
								]
							}
							onChange={option => {
								setStopReccurentByCompanyId(option.value)
							}}
							placeholder='Остановка ребиллов'
						/>
					</div>
				)}

				<div className='domain-crt-select--second'>
					<label className='input__label'>Банк</label>
					<Select
						options={banksOptions}
						value={
							banksOptions[banksOptions.findIndex(el => el.value === bankId)]
						}
						onChange={option => {
							setBankId(option.value)
						}}
						placeholder='Выберите Банк'
					/>
				</div>

				{id && selectedPayment === 'CloudPayments' && (
					<div className='domain-crt-select--second'>
						<label className='input__label'>SberBill</label>
						<Select
							options={sberBillOption}
							value={
								sberBillOption[
									sberBillOption.findIndex(el => el.value === sberbill)
								]
							}
							onChange={option => {
								setSberbill(option.value)
							}}
							placeholder='SberBill'
						/>
					</div>
				)}

				<div className='domain-crt-select--second'>
					<label className='input__label'>SberRoute</label>
					<Select
						options={sberRouteOption}
						value={
							sberRouteOption[
								sberRouteOption.findIndex(el => el.value === sberroute)
							]
						}
						onChange={option => {
							setSberroute(option.value)
						}}
						placeholder='SberRoute'
					/>
				</div>

				{id && (
					<>
						<div className='domain-crt-select--second'>
							<label className='input__label'>Чистый/Грязный шлюз</label>
							<Select
								options={isDirtyOption}
								value={
									isDirtyOption[
										isDirtyOption.findIndex(el => el.value === isDirty)
									]
								}
								onChange={option => {
									setIsDirty(option.value)
								}}
								placeholder='isDirty'
							/>
						</div>
						<div className='domain-crt-select--second'>
							<label className='input__label'>
								Остановка ребиллов по шлюзу
							</label>
							<Select
								options={stopRebillOption}
								value={
									stopRebillOption[
										stopRebillOption.findIndex(
											el => el.value === stopReccurentByGatewayId
										)
									]
								}
								onChange={option => {
									setStopReccurentByGatewayId(option.value)
								}}
								placeholder='Остановка ребиллов'
							/>
						</div>
					</>
				)}

				{id && (
					<div className='domain-crt-select--second'>
						<label className='input__label'>ТАРИФЫ</label>
						<Select
							options={rates}
							value={
								rates?.[rates?.findIndex(el => Number(el.value) === scheme)]
							}
							onChange={option => {
								setScheme(option.value)
							}}
							placeholder='Тарифы'
						/>
					</div>
				)}
				<br />

				<div className='recalculate-checkbox input-checkbox'>
					<label>Списывать инициализационную цену после била</label>
					<input
						type='checkbox'
						checked={makeAdditionalPaymentImmediately}
						onChange={() =>
							setMakeAdditionalPaymentImmediately(
								!makeAdditionalPaymentImmediately
							)
						}
					/>
				</div>
				<br />
				<div className='gateway-crt-label'>Apple pay</div>
				<textarea
					className='gateway-crt__applepay'
					value={applepay}
					onChange={e => setApplepay(e.target.value)}
				/>
				{gateways.id && (
					<>
						<span>Strikes:</span>
						<ButtonLink
							btnClass='create__btn'
							text='ДОБАВИТЬ СТРАЙК'
							href={`/strikes/${gateways.id}/add`}
						/>
						<Table
							style={{ marginBottom: 40 }}
							titles={strikesTableTitles}
							keyProp='strikes'
							content={gateways.strikes}
							gatewaysId={gateways.id}
						/>
					</>
				)}
				<button
					className='button gateway-crt-btn'
					onClick={handleSaveClick}
					disabled={
						!selectedPayment ||
						!key1 ||
						!key2 ||
						!name ||
						(payments?.[selectedPayment]?.[2] && !key3) ||
						(payments?.[selectedPayment]?.[3] && !key4)
					}
				>
					Сохранить
				</button>
				<Link to='/gateway' className='create__prev'>
					Назад к списку
				</Link>
			</div>
		</>
	)
}

export default GatewayCrtPage
