import React from 'react';
import Select from 'react-select';
import styles from './EditProductModal.scss';
import { productCategoriesGetAll } from 'store/slices/product';
import { useAppSelector, useAppDispatch } from 'store';

const EditProductModal = ({ handleCliсkCloseTable, handleClickMassUpdate, categories, setCategories }) => {
  const dispatch = useAppDispatch();
  const categoriesOption = useAppSelector((state) => state.product.categories);

  React.useEffect(() => {
    dispatch(productCategoriesGetAll());
  }, []);

  const disabledReg = !categories.length;

  return (
    <div className='editProductModal'>
      <div className='editProductModal__container'>
        <div className='editProductModal__header'>
          <div>Групповое редактирование</div>
          <div className='editProductModal__header__close' onClick={handleCliсkCloseTable}>
            X
          </div>
        </div>
        <div>
          <h3>Внести изменения для продуктов</h3>
          <div className='editProductModal__select-wrapper'>
            <div className='editProductModal__select-container'>
              <label className='input__label'>Категории</label>
              <Select
                isMulti={true}
                options={categoriesOption}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(o) => setCategories(o.map((item) => item.id))}
                placeholder='Выберите категорию'
              />
            </div>
          </div>
        </div>
        <div className='editProductModal__buttons'>
          <button type='button' onClick={handleCliсkCloseTable} className='editProductModal__buttons__cancel'>
            Отмена
          </button>
          <button
            disabled={disabledReg}
            type='button'
            onClick={handleClickMassUpdate}
            className='editProductModal__buttons__add'
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export { EditProductModal };
