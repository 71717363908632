import React from 'react';
import { useAppSelector } from 'store';
import Header from 'assets/components/Header';
import { LinkTabs } from 'assets/components/LinkTabs/LinkTabs';
import Loader from '../../assets/components/Loader';
import ErrorsTop from './ErrorsTop/ErrorsTop';
import ErrorsChains from './ErrorsChains/ErrorsChains';
import { useParams } from 'react-router-dom';

const list = [
  { name: 'ТОП ошибок', link: '/errors/top', value: 'top' },
  { name: 'Цепочки списаний', link: '/errors/chains', value: 'chains' },
];

const ErrorsPage = () => {
  const isLoad = useAppSelector((state) => state.errors.isLoading);
  const { tabName } = useParams();
  return (
    <>
      {isLoad ? <Loader /> : null}
      <Header title='Платежи' />
      <LinkTabs list={list} />
      {tabName === 'top' && <ErrorsTop />}
      {tabName === 'chains' && <ErrorsChains />}
    </>
  );
};

export default ErrorsPage;
