import React from 'react';
import Input from 'assets/components/Input';
import ButtonLink from 'assets/components/ButtonLink';
import Loader from 'assets/components/Loader';

const OrderCrtPage = ({ isLoad }) => (
  <>
    <div className='create'>
      {isLoad ? <Loader /> : ''}
      <h2 className='create__title'>Создание заказа</h2>
      <Input placeholder='Name' type='text' name='name' inputCls='create__input' label='Name' />
      <Input placeholder='Email' type='email' name='email' inputCls='create__input' label='Email' />
      <button className='button'>Сохранить</button>
      <ButtonLink text='Назад к списку' btnClass='create__prev' href='/orders' />
    </div>
  </>
);

export default OrderCrtPage;
