import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  path: '',
};

const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setRedirect(state, action) {
      state.path = action.payload;
    },
    setClearRedirect(state) {
      state.path = '';
    },
  },
});

const {
  reducer: redirect,
  actions: { setClearRedirect, setRedirect },
} = redirectSlice;

export { redirect, setClearRedirect, setRedirect };
