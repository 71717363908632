import { createSlice } from '@reduxjs/toolkit';
import { productGetOne } from 'store/slices/product';
import { request } from 'api';

const initialState = {
  list: [],
  errors: '',
  curPixel: {},
  redirect: false,
  isLoad: false,
  types: {},
};

const pixelSlice = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setPixelCreateSuccess(state) {
      state.isLoad = false;
      state.redirect = true;
    },
    setError(state, action) {
      state.errors = action.payload;
      state.isLoad = false;
    },
    setPixelGetOneSuccess(state, action) {
      state.curPixel = action.payload;
      state.isLoad = false;
      state.errors = {};
    },
    setTypesSuccess(state, action) {
      state.types = action.payload;
      state.isLoad = false;
    },
    setPixelUpdateSuccess(state) {
      state.isLoad = false;
      state.redirect = true;
    },
    setCleanRedirect(state) {
      state.redirect = false;
    },
    setCleanErrors(state) {
      state.errors = '';
    },
  },
});

const {
  reducer: pixel,
  actions: {
    setIsLoad,
    setPixelUpdateSuccess,
    setTypesSuccess,
    setPixelCreateSuccess,
    setPixelGetOneSuccess,
    setError,
    setCleanErrors,
    setCleanRedirect,
  },
} = pixelSlice;

export const pixelCreate = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post('/admin/pixels', payload)
    .then(() => {
      dispatch(setPixelCreateSuccess());
    })
    .catch((err) => {
      dispatch(setError(err.response.data.error?.messages?.value || err.response.data.error?.messages || err.response.data?.error?.message));
    });
};

export const pixelDelete = (id, productId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .delete(`/admin/pixels/${id}`)
    .then(() => {
      dispatch(productGetOne(+productId));
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const pixelGetOne =
  (id = 0) =>
  async (dispatch) => {
    const fixId = isNaN(id) ? 0 : id;
    dispatch(setIsLoad(true));
    request
      .get(`/admin/pixels/${fixId}`)
      .then((res) => {
        dispatch(setPixelGetOneSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setError(err.response.data?.error?.messages || err.response.data?.error?.message));
      });
  };

export const pixelGetType =
  (id = 0) =>
  async (dispatch) => {
    dispatch(setIsLoad(true));
    request
      .get(`/admin/pixels/types`)
      .then((res) => {
        dispatch(setTypesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setError(err.response.data?.error?.messages || err.response.data?.error?.message));
      });
  };

export const pixelUpdate = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .put(`/admin/pixels/${id}`, payload)
    .then(() => {
      dispatch(setPixelUpdateSuccess());
    })
    .catch((err) => {
      dispatch(setError(err.response.data?.error?.messages || err.response.data?.error?.message || err.response.data?.error?.messages?.value));
    });
};

export { pixel, setCleanErrors, setCleanRedirect };
