import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { format } from 'date-fns';

const initialState = {
    isLoad: false,
    list: [],
    filters: {
        dateFrom: format(new Date() - 7 * 24 * 3600 * 1000, 'yyyy-MM-dd'),
        dateTo: format(new Date(), 'yyyy-MM-dd'),
        currency: '',
        paymentSystem: '',
        gateway: '',
        companyId: '',
    },
};

const analyticsmidsSlice = createSlice({
  name: 'analyticsmids',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setAnalyticsMidsData(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
    setAnalyticsMidsFilter(state, action) {
      state.filters.paymentSystem = action.payload.paymentSystem;
      state.filters.currency = action.payload.currency;
      state.filters.companyId = action.payload.companyId;
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
      state.filters.gateway = action.payload.gateway;
    },
  },
});

const {
  reducer: analyticsmids,
  actions: { setIsLoad, setAnalyticsMidsData, setAnalyticsMidsFilter },
} = analyticsmidsSlice;

export const analyticsMidsGetAll = (params) => async (dispatch) => {
    dispatch(setIsLoad(true));
    return request
        .get('/admin/analytics/mid-stats', { params })
        .then((res) => dispatch(setAnalyticsMidsData(res.data)))
        .catch(() => dispatch(setIsLoad(false)));
};

export { analyticsmids, setAnalyticsMidsFilter };
